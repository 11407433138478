/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import logo from "../../../../../assets/img/logo.svg";
import linkedin from "../../../../../assets/img/linkedin.svg";
import twitter from "../../../../../assets/img/twitter.svg";
import medium from "../../../../../assets/img/medium.svg";
import location from "../../../../../assets/img/location.svg";
import mail from "../../../../../assets/img/mail.svg";
import tech from "../../../../../assets/img/tech.svg";
import phone from "../../../../../assets/img/phone-call.svg";
import { Link } from "react-router-dom";
import axios from "../../../../../services/api";

function index() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [subscribe, setSubscribe] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState(false);
  const [emailScc, setEmailScc] = useState(false);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onSubmitSubscribe = () => {
    let checkEmail = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
    if (subscribe === "") {
      setEmailErr(true);
      setEmailErrText("Email is Required");
      return null;
    } else if (!checkEmail.test(subscribe)) {
      setEmailErr(true);
      setEmailErrText("Email is Invaild");
      return null;
    } else {
      axios
        .post("users/v1/newsletter-subscribers", {
          email: subscribe,
        })
        .then(function (response) {
          // handle success
          setEmailErr(false);
          setSubscribe("");
          setEmailScc(true);
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          setEmailErr(true);
          setEmailErrText("Something went Wrong");
          console.log(error);
        });
    }
  };

  return (
    <footer className="bg-black xl:px-16 2xl:px-32 px-8 ">
      <div className="flex footer-main flex-col lg:flex-row justify-between items-center  pt-9 bg-black">
        {/* column one */}
        <div className="flex  md:mr-8 lg:flex-col justify-between   relative lg:top-4 w-full lg:w-auto footer-block-1">
          <div className="footer-logo-">
            <Link
              to="/"
              className={`${dimensions.width < 640 ? "" : "footer_border"} flex items-center cursor-pointer  lg:-mt-12`}
            >
              <img src={logo} loading="lazy" alt="StategyConnectLogo" className="h-6 w-6 cursor-pointer" />
              <p
                className={`nunito text-white text-base ml-1.5 font-bold relative top-0.5 cursor-pointer ${
                  dimensions.width < 640 ? "hidden" : ""
                }`}
              >
                StrategyConnect
              </p>
            </Link>
          </div>

          <p className={`text-base text-white mt-6 hidden lg:flex `}>Social Media</p>

          <div
            className={`flex items-center social-links relative w-2/5 lg:w-auto justify-between mt-4  ${
              dimensions.width < 640 ? "-top-2" : ""
            }`}
          >
            <a href="https://www.linkedin.com/company/strategyconnect/">
              <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAaVBMVEUAd7X///8AcLEEebb7/f4Debb9/v79/v8DeLY8l8Y7lsYAbbCcyuKlz+WQxN+Lwd0KfbiDvdvl8fcAc7O+3OwgiL6x1ehFnMlytNZqsNQZhLzF4O7w9/tSo81gqtGp0ebc7PUsjsJ8udngaOvIAAADg0lEQVRoge2abZeqIBCAGV7EjXItKjc1y/7/j7xD7m5AWHdD+uScs3s8kDzOIMwMDoGrSJ4lEC6H0ckVkUEiyeQPBC+ar+pjcqm+GsQMEAntUSuaQJQ+toZC8F+7U4KJBMKE6ltUwkAqJUgiEapCAJGwpSwVgxBGtyAJh4NKCVEH4AStRZNZC+1F0V5orgXN00FyukBzZekh2XsgbzFXCMIGSQlhTCsjegrMCEQQVXfF8rOrFYm3YxjCdFnw6zbdFkJHr6AgROjdGYAbATjXOlaXEISRcgN88GEZh3NJIuclBBF0BfzXr3EoYve1ACTXPViuOAO+izRYAMLUxVLEqBK7RQcggp48yHJ6SG5cjAWRsI/0A0FN9g6Ew2p6TZhae+a6pDDXwn67UKt++rcL3+Gts072sW4gDOn5L4VjRBa7rwT3LkarDMNvmeEf8EV0tDSyC9O++dZku4uPyEb8CdO6KrbNtvjQOt5rjXnGnGGkrKmibALfP+7jBQ6fs0lCvnHIn4Tlj2KBsTkJX4cGYgLH1yiD8n+A/LcgACevrHe7ujRXQYXCEFHaIsLNZjgMnMrjstm0nLebZnXodSi6Cbvfz3Zzk3Y97I/ozOxmE2Dkql5vnCS0OQbe+WAgoZbOjcUPpLBbeU0I7dprZi4xlZYyM8ENNP3d6h2DcPkj/Ab5vDVjkllrgg/DpfNA+HN59D3DKMTahW3ILbxoa3Ru/D75x59Uni6vQ3hZOL7N8j9+ePMqRMLmACNnGHce6FUItrcgA4ThCdwU9HUIjDLuVImAjB/4+EFnjCZXba6xv/SU8oLOOEhmvQgu5GRPShQER25Pl667nFrXeBmc7ZUSA8FxLyU1mSWtTw7Fm5S4ia/Ud5KsnVtM34dlrwiIxNkV31ZhhG1sXTh01sy/DkG7W55SqC9npcLXJBBvHEzPLFU4rCeBgBeHs7MDKaaA4A5ZWggvdXKzs5chGTSOn2XKTZlX02jiJnnGOU8O8ZO8u146CWTpQdYzZIbMkBkyQ2bIDJkhA+QmDsRqDkCs3tXTqH4FtiyDxx6BkMiW/ZO4K6eH/eom+27oy2nnNB+8DPfo9j5JgswBk/PVnj1sfnzTKMScPVpf7fMnzU97JzqDfCxvhMxfsf8Ake8pX3hLIUbqkhJtSkpSF8ccTXGMKSXqk5f5vKVg6Xpkkbz06j1FZJC6HO4fbRVnSvd35FUAAAAASUVORK5CYII="
                }
                loading="lazy"
                alt="linkedIn"
                className="cursor-pointer mr-f-20"
                style={{
                  width: "30%",
                  borderRadius: "2px",
                }}
              />
            </a>
            {/* <img
              src={linkedin}
              loading="lazy"
              alt="linkedIn"
              className="cursor-pointer"
            />
            <img
              src={twitter}
              loading="lazy"
              alt="twitter"
              className="cursor-pointer"
            />
            <img
              src={medium}
              loading="lazy"
              alt="medium"
              className="cursor-pointer"
            /> */}
          </div>

          {/* <p className="no-underline text-white lg:grey-text text-xl text-left cursor-pointer hover:text-gray-300 mt-4 ">
            <Link style={{ borderBottom: '1px solid white' }} to="/freelancer">
              Freelance
            </Link>
          </p> */}
        </div>
        {/* column one */}

        {/* column two */}
        <div
          className={` text-white footer-block-2   ${
            dimensions.width < 1040 ? "" : "right-divide-line"
          }  lg:pr-10  relative xl:pr-10 2xl:pr-20 lg:top-2 `}
        >
          <p className={`text-base font-semi-bold lg:mb-2  ${dimensions.width < 640 ? "hidden" : ""}`}>Website</p>

          <ul
            className={`flex  flex-row lg:flex-col w-72   lg:w-auto mt-4 lg:mt-0  lg:justify-between mx-auto flex-wrap`}
          >
            <li className="no-underline text-white lg:grey-text  hover:text-gray-300 cursor-pointer text-sm text-left lg:mt-0 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/">Home</Link>
            </li>
            <li className="no-underline text-white lg:grey-text text-sm hover:text-gray-300 cursor-pointer text-left lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/our-approach">Approach</Link>
            </li>
            <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/case-studies">Case Studies</Link>
            </li>
            <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/about-us">About Us</Link>
            </li>
            {/* <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/freelancer">Join as Freelancer</Link>
            </li> */}
            <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/onboarding">Join as Freelancer</Link>
            </li>
            <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="no-underline text-white lg:grey-text text-sm text-left cursor-pointer hover:text-gray-300 lg:mt-3 mt-2 mr-8 lg:mr-0 font-thin">
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </li>
          </ul>
        </div>

        {/* column two */}

        {/* column three */}
        <div
          className={`text-white footer-block-3 ${
            dimensions.width < 1040 ? "" : "right-divide-line"
          } lg:pr-10 2xl:pr-20 xl:pr-10  relative top-2 -left-5 lg:h-56 `}
        >
          <p className="text-base hidden lg:flex font-semi-bold">Contact Us</p>
          <ul className="flex flex-col">
            <li className="no-underline grey-text text-sm  text-left md:mt-4 mt-8 flex font-thin">
              <img src={location} loading="lazy" alt="location" className="self-start" />
              <span>
                <p className="ml-6 w-64 lg:w-48 xl:w-80">
                  8th Floor, #145 The Offices 4, One Central Dubai World Trade Center, Dubai, UAE.
                </p>
                <p className="ml-6 w-80 hidden xl:block footer-top-line">8 The Greens, Dover, Delaware 19901, USA.</p>
              </span>
            </li>
            <li className="no-underline grey-text text-sm flex  md:mt-4 mt-8 text-left font-thin">
              <img src={mail} loading="lazy" alt="location" className="" />
              <p className="ml-6">info@strategyconnect.co</p>
            </li>
            <li className="no-underline grey-text text-sm flex  md:mt-8 mt-8 font-thin">
              <img src={phone} loading="lazy" alt="location" className="" />
              <p className="ml-6">+971 527344538</p>
            </li>
          </ul>
        </div>

        {/* column three */}

        {/* column four */}
        <div className="text-white footer-block-4 hidden relative sm:-left-8 lg:block">
          <p className="text-base font-semi-bold">Get the latest updates in your inbox</p>

          <div className="w-72 flex mt-6 items-center">
            <input
              type="text"
              placeholder="Email ID"
              className="focus:outline-none py-1.5 border-none px-3 bg-white text-black font-thin text-base rounded"
              onChange={(e) => {
                setSubscribe(e.target.value);
                setEmailErrText("");
              }}
              value={subscribe}
            />
            <p
              className="py-2 px-4 ml-2 hover:bg-indigo-900 text-white purple rounded cursor-pointer text-sm"
              onClick={() => {
                onSubmitSubscribe();
              }}
            >
              Subscribe
            </p>
          </div>
          {emailErr && <p className="email-err-text">{emailErrText}</p>}
          {emailScc && <p className="email-suc-text">Subscribe Done</p>}
          <p className="grey-text w-72 mt-3 text-sm text-left  font-thin">Don’t worry, we hate spam too.</p>

          <div className="flex tech-top-line items-center">
            <p className="grey-text text-sm text-left  font-thin">Backed by</p>
            <img src={tech} loading="lazy" alt="techstars" className="ml-4" />
          </div>
        </div>

        {/* column four */}
      </div>
      <div className="py-3 mt-8 bg-black top-divide-line">
        <p className="text-center grey-text text-sm">Copyright @ 2021 StrategyConnect Inc. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default index;
