import React from 'react';

export default function MarketHero() {
  return (
    <div
      className="xl:py-28 py-14"
      style={{ backgroundColor: 'rgba(177, 175, 229,0.1)' }}
    >
      <p className="xl:text-5xl text-3xl font-bold text-center">
        Marketplace Experts
      </p>
      <p
        style={{ color: '#707376' }}
        className="xl:text-xl text-base font-light xl:mt-7 mt-4 text-center xl:w-3/6 w-full px-8 xl:px-0 mx-auto"
      >
        We uncover deep insights that can help your business fast track its
        entry to the market of interest.
      </p>
    </div>
  );
}
