import React from "react";

export default function BusinessHero() {
  return (
    <div className="xl:py-20 py-14" style={{ backgroundColor: "rgba(177, 175, 229,0.1)" }}>
      <p className="xl:text-5xl text-3xl font-bold text-center">Service Packages</p>
      <p
        style={{ color: "#707376" }}
        className="xl:text-xl text-base font-light xl:mt-7 mt-4 text-center xl:w-3/6 w-full px-8 xl:px-0 mx-auto"
      >
        Our best selling services, carefully designed into standardized offerings with well defined process, clear
        deliverables and transparent pricing to help business scale fast and succeed
      </p>
    </div>
  );
}
