import React, { useState, useEffect } from 'react';
import manOne from '../../../assets/img/pickOne.svg';
import manTwo from '../../../assets/img/pickTwo.svg';
import boxes from '../../../assets/img/boxes.svg';

function OurStory() {

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <div className="xl:pt-16 py-7 our-light-bg lg:mt-24 mt-10   lg:px-24 px-4  relative">
      <div>
        <div>
          <p className="text-sm uppercase grey-text">Our Story</p>
          <p className="lg:text-4xl text-xl lg:mt-3 lg:w-full w-2/4  font-bold">
            How we started
          </p>
        </div>

        <img
          src={boxes}
          style={{
            height: dimensions.width < 1200 ? 94 : 'auto',
            width: dimensions.width < 1200 ? 117.61 : 'auto',
          }}
          alt="boxes"
          className="absolute lg:-right-5 right-5 lg:-top-3 -top-7"
        />
      </div>

      <div className="flex flex-row items-center flex-wrap justify-between mt-14">
        <img src={manOne} alt="" loading="lazy" className="" />

        <p
          style={{width:dimensions.width <= 1340 && dimensions.width >= 1027 && '50%' ,}}
          className="text-base grey-text mr-6 leading-7 mt-8 lg:mt-0 w-full meme lg:w-7/12">
          We’ve been mesmerized with how the startup and family-office space has
          been growing in the past few years. Of course, it helped that we were
          part of the startup microcosm ourselves. Observing how new business
          ventures were finding traction either as category creators or for
          bridging a gap in existing products/services, we experienced a
          light-bulb moment!
        </p>
      </div>

      <p className="text-base grey-text mt-10 leading-7">
        We had been staring at a big talent gap (and therefore a golden
        opportunity) right in the face: the unavailability of skilled,
        experienced, short-term specialist consultants. The only thing to do
        then was to find a way to bridge it.
      </p>

      <div className="flex flex-row flex-wrap items-center justify-between mt-7">
        <p className="text-base grey-text leading-7 w-full lg:w-7/12 ">
          During the various stints in our professional careers, we discovered
          that the greatest challenge that ran across all projects was finding
          the right skills, at the right time, at the right cost. For small or
          emerging companies trying to expand their presence and reach, this
          challenge becomes multi-fold, as the lack of project experts holds
          them back from reaching their true potential.
        </p>
        <img src={manTwo} alt="" loading="lazy" className="mt-8 lg:mt-0" />
      </div>

      <p className="text-base grey-text mt-10 leading-7">
        To bridge this gap, Strategy Connect operates in the business advisory &
        transformation space to offer startups, family offices and high-growth
        organizations, a unique combination of on-demand talent as well as
        AI-powered technology to help them achieve scale, profitability, and
        cost effectiveness.
      </p>
      <p className="text-base grey-text mt-7 leading-7">
        When you share your project details with us, which could be a
        go-to-market strategy or a new product launch, our AI-powered platform
        suggests deliverables, skills and timelines for your project. It then
        maps project-relevant skills and capabilities of your internal workforce
        and smartly matches specialized external consultants, experts and
        boutiques from StrategyConnect's vast talent network to your project
        deliverables.
      </p>
      <p className="text-base grey-text mt-7 leading-7">
        When you have an expert by your side, your project doesn’t look
        unsurmountable anymore. Not only do we help you plug in the skill gaps,
        we also give you access to cutting-edge project management solutions to
        view tangible progress through the course of your project and
        successfully complete it without delays.
      </p>
    </div>
  );
}

export default OurStory;
