import React, { useState } from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import classes from "./Carrers.module.css";
import tie from "../../assets/img/tie.svg";
import Menu from "@material-ui/core/Menu";
import ModalResponse from "./ModalResponse";
import { useHistory } from "react-router-dom";
import clock from "../../assets/img/clock.png";
import edit from "../../assets/img/Icon/edit.svg";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import deleteImg from "../../assets/img/Icon/delete.svg";
const user_tyep_id = localStorage.getItem("adminAccessType");
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    color: "red",
  },
  dropdown: {
    position: "absolute",
    top: 28,
    right: "-12px",
    zIndex: 1,
    display: "grid",
    padding: "4px",
    backgroundColor: theme.palette.background.paper,
  },
}));
function CareersList(props) {
  const [selectedRow, setSelectedRow] = useState();
  const [visible, setVisible] = useState(false);
  const [deleteI, setDeleteI] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let history = useHistory();

  return (
    <div className={classes.listParent}>
      <div className={classes.list}>
        {props?.data.length > 0 ? (
          props?.data?.map((res, index) => {
            return (
              <div key={index} className={classes.box}>
                <div className={classes.gg}>
                  <h4 className={classes.boxh4}>
                    {res?.job_title} <span className={classes.boxspan}>{res?.JobID}</span>
                  </h4>
                  {(user_tyep_id == 2 || user_tyep_id == 3) && (
                    <div className={classes.moreIcon}>
                      <IconButton
                        cla
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e);
                          setSelectedRow(res);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        style={{ boxShadow: "none" }}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            // history.push("/careers");
                            // console.log("Editeeeeeeeeeeeeee");
                            history.push({
                              pathname: `/careers/create`,
                              state: res,
                            });
                          }}
                        >
                          <img className={classes.iconDrop} src={edit} alt="edit" />
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            setVisible(true);
                            console.log("Delete");
                          }}
                        >
                          <img className={classes.iconDrop} src={deleteImg} alt="delete" />
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>

                <h6>
                  <img src={tie}></img> {res?.years_of_experience} Yrs
                </h6>
                <div className={classes.minBox}>
                  <p
                    className={`${classes.eclipse} ull`}
                    dangerouslySetInnerHTML={{ __html: res?.job_description }}
                  ></p>
                </div>
                <h6>
                  <img src={clock} alt="Data" />
                  {/* moment().endOf('day').fromNow() */}
                  {/* {moment(res?.createdAt).startOf("day").fromNow()}
                  {moment(res?.createdAt).endOf("day").fromNow()} */}
                  {moment(res?.createdAt).endOf("hour").fromNow()}
                  {/* {moment(res?.createdAt).startOf("hour").fromNow()} */}
                </h6>
                <div className={classes.btn}>
                  <button
                    // style={{ }}
                    style={
                      localStorage.getItem("jobId") === res.JobID
                        ? { width: "100%", margin: "1rem 0", pointerEvents: "none", background: "gray", border: "gray" }
                        : { width: "100%", margin: "1rem 0" }
                    }
                    onClick={() => {
                      history.push({
                        pathname: "/careers/details",
                        state: { detail: res },
                      });
                    }}
                  >
                    {localStorage.getItem("jobId") === res.JobID ? "Applied" : "Details"}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
        {props?.data?.length <= 0 && <div className={classes.noData}>No Data Available</div>}

        {visible && (
          <ModalResponse
            onClose={() => {
              setVisible(false);
              handleClose();
            }}
            delete={deleteI}
            open={visible}
            deleteItem={() => {
              props.deleteJob(selectedRow?.id);
              setVisible(false);
              handleClose();
            }}
            message={"Are you sure you want to Delete?"}
          />
        )}
      </div>
      {props.count > 0 && (
        <ReactPaginate
          previousLabel={"<"}
          previousClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          disabledClassName={"border py-1 px-2 text-gray-300 text-sm rounded border-gray-300"}
          nextClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          nextLabel={">"}
          breakLabel={"..."}
          // breakClassName={"break-me"}
          pageCount={props.count}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            props.getCaseStudies("", e.selected * 10);
          }}
          containerClassName={
            " justify-between pagginaton_react pt-10 items-center w-full pr-12 mobile-pagination lg:flex my-8"
          }
          subContainerClassName={"pages pagination"}
          activeClassName={"text-white text-sm font-bold px-2 py-1 purple rounded focus:outline-none"}
        />
      )}
    </div>
  );
}

export default CareersList;
