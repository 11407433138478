import React, { useEffect, useState } from "react";
import defaultCaseStudy from "../../assets/img/defaultCaseStudy.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css } from "@emotion/core";
import PacmanLoader from "react-spinners/PacmanLoader";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import Interested from "../Clients/Interested/Index";
import { getCaseStudiesByIndustries, getCaseStudyDetail } from "../../state/actions/caseStudyAction";
import { Helmet } from "react-helmet";
const override = css`
  display: block;
  margin: 5px auto;
  position: relative;
  left: -10px;
  top: -4px;
`;

function Clients({ getCaseStudyDetail, getCaseStudiesByIndustries, match, caseStudy }) {
  const { caseStudyDetail, caseStudyLoading } = caseStudy;
  useEffect(() => {
    getCaseStudyDetail(match.params.id);
    document.title = "StrategyConnect | Clients";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (caseStudyDetail !== null) {
      // if (caseStudyDetail.project.industries) {
      //   let industries = caseStudyDetail.project.industries.map((i) => i.categorization_code);
      //   getCaseStudiesByIndustries(industries[0], 3);
      // }
    }
  }, [caseStudyDetail, getCaseStudiesByIndustries]);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  let case_study_loading = false;
  if (caseStudyDetail === null) {
    case_study_loading = true;
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
          justifyContent: "center",
          display: "flex",
          paddingTop: window.screen.height * 0.4,
        }}
      >
        <PacmanLoader color={"#38369a"} loading={case_study_loading} css={override} size={60} />
      </div>
    );
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Read our case studies to see how StrategyConnect helps businesses like yours solve business challenges with an expert-driven approach. Hire experts to drive real business growth. "
        />
        <title>Case Studies | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div className="title">
        <img
          src={caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[1].signed_url ? caseStudyDetail.data[1].signed_url : defaultCaseStudy}
          // src={defaultCaseStudy}
          loading="lazy"
          alt="spi-logo"
          className="w-full imgageR"
          style={{ maxHeight: "300px" }}
        />
        <div>
          <div>
            <h1>{caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].title}</h1>
          </div>
          <div className="case-details-grid">
            <div className="padding-1">
              <div className="textCapital">Client</div>
              <div>{caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].client_name}</div>
            </div>
            <div className="padding-1">
              <div className="textCapital">Year</div>
              <div>
                {caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].date
                  ? new Date(caseStudyDetail.data[0].date).getFullYear()
                  : ""}
              </div>
            </div>
          </div>
          <div className="case-details-grid">
            <div className="padding-1">
              <div className="textCapital">Industries</div>
              <div>
                {caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].industries && JSON.parse(caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].industries).map((val) => {
                  return <span className="cab_proties">{val.name}</span>;
                })}
              </div>
            </div>
            <div className="padding-1">
              <div className="textCapital">Capabilities</div>
              <div>
                {caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].capabilities && JSON.parse(caseStudyDetail.data[0].capabilities).map((val) => {
                  return <span className="cab_proties">{val.name}</span>;
                })}
              </div>
            </div>
          </div>

          <div className="p_b_2  ull">
            {/* <div className="textCapital">Document</div> */}
            <p dangerouslySetInnerHTML={{ __html: caseStudyDetail && caseStudyDetail.data && caseStudyDetail.data[0].description }}></p>
          </div>
        </div>
      </div>
      <div className="title">
        <Interested />
      </div>
      <Footer />
    </div>
  );
}

getCaseStudyDetail.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};
getCaseStudiesByIndustries.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  caseStudy: state.caseStudy,
});

export default connect(mapStateToProps, {
  getCaseStudyDetail,
  getCaseStudiesByIndustries,
})(Clients);
