import React from 'react';
import man from '../../../../assets/img/sai2.webp';
import ikea from '../../../../assets/img/Crocs.svg';
import EngagementForm from './EngagementForm';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';


 function EngagementModel({ consultant }) {
   return (
     <LoadingOverlay active={consultant.loading} spinner text="">
       <div className=" w-full flex items-center">
         <div className="h-screen hidden lg:block relative w-3/5">
           <img
             src={man}
             style={{ objectFit: 'cover' }}
             alt="placeImage"
             className="z-10 h-screen w-full"
           />
           <div
             style={{
               background:
                 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 195, 238, 0.7) 78.65%, rgba(0, 195, 238, 0.95) 100%)',
             }}
             className="w-full  h-full z-20 absolute top-0 left-0 px-20"
           >
             <div className="absolute bottom-0 pb-10 ">
               <h1 className="text-xl font-semibold text-white w-4/5 pb-7 ">
                 As the director of a boutique strategy consultancy group, I
                 found StrategyConnect’s platform instrumental to get more
                 projects and engage my team with their built-in project
                 management tools.
               </h1>
               <div
                 style={{ borderTop: '1.5px solid #FFFFFF' }}
                 className="w-4/5"
               >
                 <p className="text-white text-xl text-left font-semibold mt-7 mb-5">
                   Sandeep, consultant with Strategyconnect
                 </p>

                 <div className="flex items-center">
                   <p className="text-white text-base font-light text-left mr-4">
                     Worked with
                   </p>
                   <img src={ikea} alt="ikea-logo" />
                 </div>
               </div>
             </div>
           </div>
         </div>
         <EngagementForm />
       </div>
     </LoadingOverlay>
   );
 }

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {})(EngagementModel);