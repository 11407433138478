import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import close from '../../../../assets/img/cancel.svg';
import Modal from 'react-awesome-modal';
import downArrow from '../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../assets/img/textUpArrow.svg';
import CustomDropdown from '../../../auth/components/CustomDropdown';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { saveProfileData } from '../../../../state/actions/consultantAction';
import CustomTextInput from '../../../auth/components/CustomTextInput';

 function AddCoordinates(props) {
   const { consultant, saveProfileData } = props;
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [joinAs, setMyJoinAs] = useState('');
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const smallHeight = dimensions.height;


  const initialValues = {
    city:'',
  };

  const validationSchema = yup.object().shape({
    city: yup.string().required('city is required'),
  });

  const handleSubmit = (formValues) => {
    const dataObject = {
      cityData:
        formValues.city.length < 1
          ? []
          : !consultant.cities
          ? [
              {
                country: joinAs,
                city: formValues.city,
              },
            ]
          : [
              ...consultant.cities,
              {
                country: joinAs,
                city: formValues.city,
              },
            ],
    };
        // console.log({ dataObject });
        const authId = localStorage.getItem('authId');
        saveProfileData(dataObject, authId, (res) => {
          if (Object.keys(res).length > 0) {
            alert.error(res.error);
          } else {
            props.setVisible(false);
          }
        });
  };
  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width == 1200
          ? '45%'
          : dimensions.width == 1024
          ? '50%'
          : '40%'
      }
      onClickAway={() => props.setVisible(false)}
    > <LoadingOverlay active={consultant.loading} spinner text=" ">
      <div
        style={{
          height: dimensions.width < 780 ? smallHeight : 'auto',
          width: dimensions.width < 780 ? dimensions.width : 'auto',
        }}
        className="rounded-lg bg-white overflow-y-scroll hideScroll"
      >
        <div
          style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
          className="flex items-center justify-between p-8"
        >
          <p className="text-xl">ADD CO-ORDINATES</p>
          <img
            src={close}
            alt=""
            onClick={() => {
              props.setVisible(false);
            }}
            className="cursor-pointer"
          />
        </div>
        {/* main add content */}
        <div className="lg:px-28 px-10 mt-5 lg:mt-10 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              return (
                <Form id="add-language">
                  <div className="border border-gray-300 rounded p-3">
                    <p
                      className="text-base pb-1.5"
                      style={{
                        borderBottom: '1px solid #80808036',
                        color: 'rgba(128, 128, 128, 0.63)',
                      }}
                    >
                      Country
                    </p>
                    <CustomDropdown
                      items={consultant.countries}
                      placeholder="Select Country"
                      id="country"
                      width="w-full"
                      onValueChange={(val) => {
                        setMyJoinAs(val);
                      }}
                      downArrow={downArrow}
                      upArrow={upArrow}
                    />
                  </div>

                  <div className="border border-gray-300 rounded mt-4 p-3">
                    <p
                      className="text-base pb-1.5"
                      style={{
                        borderBottom: '1px solid #80808036',
                        color: 'rgba(128, 128, 128, 0.63)',
                      }}
                    >
                      City
                    </p>
                    <CustomTextInput
                      name="city"
                      id="city"
                      type="text"
                      placeholder="Enter city"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* main add content */}

        <div
          className="px-10 py-5 flex  mt-10 justify-end "
          style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
        >
          <button
            onClick={() => {
              props.setVisible(false);
            }}
            style={{ border: '1px solid #38369A', color: '#38369A' }}
            className="text-base rounded px-4 py-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            form="add-language"
            className="purple text-white px-4 py-2 ml-4 rounded text-base"
          >
            Save Changes
          </button>
        </div>
      </div>
      </LoadingOverlay>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData,
})(AddCoordinates);