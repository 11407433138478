/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import logo from '../../../../../assets/img/regLogo.svg';
import ContinueFooter from '../../../components/ContinueFooter';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

// messgae icons
import messActive from '../../../../../assets/img/messActive.svg';
import messInActive from '../../../../../assets/img/messInActive.svg';
import messError from '../../../../../assets/img/messError.svg';

import { connect } from 'react-redux';
import {
  forgotPassword
} from '../../../../../state/actions/consultantAction';
import { useAlert } from 'react-alert';
import CustomTextInput from '../../../components/CustomTextInput';

 function ForgotPasswordForm({ forgotPassword }) {
   const history = useHistory();
    const alert = useAlert();

    const validationSchema = yup.object().shape({
      email: yup.string()
    });
    const handleSubmit = (formValues) => {
      console.log(formValues)
      forgotPassword(formValues.email, (res) => {
        if (Object.keys(res).length > 0) {
          alert.error(res.error);
        } else {
          history.push('/freelancer/reset-password');
        }
        
      });
    };
   return (
     <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
       <div className=" flex flex-col justify-center md:px-16 xl:px-32 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9 waitBorderBottom">
           Reset Password
         </h1>

         <Formik
           initialValues={{ email: '' }}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="forgot-password" onSubmit={props.handleSubmit}>
                 <CustomTextInput
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email address*"
                  // onChange={(e) => setEmail(e.target.value)}
                  activeIcon={messActive}
                  inactiveIcon={messInActive}
                  errorIcon={messError}
                />
               </Form>
             );
           }}
         
         </Formik>

         

         
       </div>
       <ContinueFooter
         percentage={0}
         id="forgot-password"
       />
       
     </div>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, { forgotPassword })(
  ForgotPasswordForm
);