/* eslint-disable import/no-anonymous-default-export */

import {
  GET_ROLE_DROPDOWN,
  GET_INDUSTRY_DROPDOWN,
  GET_FUNCTIONAL_DROPDOWN,
  GET_LANGUAGE_DROPDOWN,
  POST_CONSULTANT_REGISTER,
  POST_CONSULTANT_OTP,
  POST_LOADING,
  USER_TOKEN,
  STOP_LOADING,
  TELL_US,
  EXPERIENCE,
  CONSULTANT_DATA,
  COUNTRIES,
  SET_RESET_PASSWORD_TOKEN
} from '../actions/types';

const initialState = {
  registerToken: '',
  verifyOtpToken: '',
  resetPasswordToken: '',
  userToken: '',
  role_dropdown: [],
  industry_dropdown: [],
  functional_dropdown: [],
  laguage_dropdown: [],
  loading: false,
  tell_us: {},
  experience: {},
  consultantProfile: [],
  countries:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COUNTRIES:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case CONSULTANT_DATA:
      return {
        ...state,
        loading: false,
        consultantProfile: action.payload,
      };
    case EXPERIENCE:
      return {
        ...state,
        loading: false,
        experience: action.payload,
      };
    case TELL_US:
      return {
        ...state,
        loading: false,
        tell_us: action.payload,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case USER_TOKEN:
      return {
        ...state,
        userToken: action.payload,
        loading: false,
      };
    case POST_CONSULTANT_REGISTER:
      return {
        ...state,
        registerToken: action.payload,
        loading: false,
      };
    case POST_CONSULTANT_OTP:
      return {
        ...state,
        verifyOtpToken: action.payload,
        loading: false,
      };
    case SET_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        resetPasswordToken: action.payload,
        loading: false,
      };
    case POST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLE_DROPDOWN:
      return {
        ...state,
        role_dropdown: action.payload,
        loading: false,
      };
    case GET_INDUSTRY_DROPDOWN:
      return {
        ...state,
        industry_dropdown: action.payload,
        loading: false,
      };
    case GET_LANGUAGE_DROPDOWN:
      return {
        ...state,
        laguage_dropdown: action.payload,
        loading: false,
      };
    case GET_FUNCTIONAL_DROPDOWN:
      return {
        ...state,
        functional_dropdown: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
