import React from "react";
import "./TechDue.css";
import Reports from "../../assets/img/Tech/Reports.svg";
import DataRoom from "../../assets/img/Tech/DataRoom.svg";
import IntroCall from "../../assets/img/Tech/IntroCall.svg";
import Interviews from "../../assets/img/Tech/Interviews.svg";
import ActionPlan from "../../assets/img/Tech/ActionPlan.svg";
import DataRequest from "../../assets/img/Tech/DataRequest.svg";
import RiskAssesment from "../../assets/img/Tech/RiskAssesment.svg";
import DetailedReport from "../../assets/img/Tech/DetailedReport.svg";
import FinalDebriefing from "../../assets/img/Tech/FinalDebriefing.svg";
import GrowthPotential from "../../assets/img/Tech/GrowthPotential.svg";
import ExcellenceLevel from "../../assets/img/Tech/ExcellenceLevel.svg";
import ExecutiveSummary from "../../assets/img/Tech/ExecutiveSummary.svg";
import { Helmet } from "react-helmet";

function TechnicalDue(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Uncover the powerful insights with an expert-driven technical due diligence service to help you make an informed decision. "
        />
        <title>Technical Due Diligence Service | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <div className="tech">
        <div className="padding-tech">
          <h1 className="d">Objectives</h1>
        </div>
        <div className="tech-parent">
          <div className="grid lg:px-32 sm:px-24 px-2">
            <div className="bg-red">
              <div className="tech-icon">
                <img src={RiskAssesment} alt="RiskAssesment"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Risk Assesment</div>
                <div className="tech-icon-text">Deal risk level, ways and strategy for risk mitigation</div>
              </div>
            </div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={GrowthPotential} alt="GrowthPotential"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Growth Potential</div>
                <div className="tech-icon-text">Challenge analysis and go-to-market readiness assessment</div>
              </div>
            </div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={ExcellenceLevel} alt="ExcellenceLevel"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Excellence Level</div>
                <div className="tech-icon-text">Adherence to consistency of industry best practices</div>
              </div>
            </div>
          </div>
        </div>
        {/* seconddddddddd */}
        <div className="process padding-tech">
          <h1>Process</h1>
          <div className="tech-parent mb-p-3">
            <div className="grid lg:px-32 sm:px-24 px-2">
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={IntroCall} alt="IntroCall" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Intro Call</div>
                  <div className="tech-icon-text">Introduction with CTO and explanation of the tech DD process</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={DataRoom} alt="DataRoom" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Data Room</div>
                  <div className="tech-icon-text">Sharing of list of documents expected in the data room</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={DataRequest} alt="DataRequest" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Data Request Document</div>
                  <div className="tech-icon-text">Sharing of customized data request document</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={Interviews} alt="Interviews" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Interviews</div>
                  <div className="tech-icon-text">
                    8-10 hours of scheduled interviews (CTO 4 hrs, Product Lead 2 hrs, Engineering Team 2-4 hrs)
                  </div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={Reports} alt="Reports" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Reports</div>
                  <div className="tech-icon-text">Drafting of comprehensive reports with a grading approach</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={FinalDebriefing} alt="FinalDebriefing" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Final Debriefing</div>
                  <div className="tech-icon-text">Scheduled debrief to talk about the findings</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Thirdddddddddd */}
        <div className="bg-gray padding-tech">
          <div className="padding-tech">
            <h1 className="d">Deliverables</h1>
          </div>
          {/* <h1></h1> */}

          <div className="tech-parent">
            <div className="grid lg:px-32 sm:px-24 px-2">
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={DetailedReport} alt="DetailedReport"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Detailed Report</div>
                  <div className="tech-icon-text">15-20 pages report listing findings as per topics in the scope</div>
                </div>
              </div>
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={ExecutiveSummary} alt="ExecutiveSummary"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Executive Summary</div>
                  <div className="tech-icon-text">
                    Graded 4-5 pages report highlighting the most impactful information
                  </div>
                </div>
              </div>
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={ActionPlan} alt="ActionPlan"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Action Plan</div>
                  <div className="tech-icon-text">
                    Graded action plan outlining the optimal strategy and actionable insights
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-now">
          <button
            onClick={() => {
              props.showConatact("sss");
            }}
          >
            Get it Now
          </button>
        </div>
      </div>
    </>
  );
}

export default TechnicalDue;
