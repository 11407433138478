/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CaseHero from "./CaseHero";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCaseStudies } from "../../state/actions/caseStudyAction";
import MainCase from "./MainCase";
import { Helmet } from "react-helmet";

function CaseStudy({ getCaseStudies }) {
  useEffect(() => {
    getCaseStudies();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Read our case studies to see how StrategyConnect helps businesses like yours solve business challenges with an expert-driven approach. Hire experts to drive real business growth. "
        />
        <title>Case Studies | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <CaseHero />
      <MainCase />
      <Footer />
    </div>
  );
}

getCaseStudies.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  caseStudy: state.caseStudy,
});

export default connect(mapStateToProps, { getCaseStudies })(CaseStudy);
