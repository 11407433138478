import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WaitList from './SignUpStepOne/WaitList';
import Passcode from './SignUpStepOne/Passcode';
import SecureAccount from './SignUpStepOne/SecureAccount';
import Approach from '../../../components/Approach';
import AboutYourself from './AboutYourself';
import OnBoardExperience from './OnBoardExperience';
import EngagementModel from './EngagementModel';
import Login from './SignUpStepOne/Login';
import ForgotPassword from './SignUpStepOne/ForgotPassword';
import ResetPassword from './SignUpStepOne/ResetPassword';

export default function Freelancer() {
  let { path } = useRouteMatch();

  useEffect(() => {
    document.title = 'StrategyConnect | Freelance';
    window.scrollTo(0, 0);
  }, []);
  return (
    <Switch>
      <Route exact path={`${path}`} component={Approach} />
      <Route exact path={`${path}/sign-up/step-one`} component={WaitList} />
      <Route exact path={`${path}/sign-up/step-two`} component={Passcode} />
      <Route
        exact
        path={`${path}/sign-up/step-three`}
        component={SecureAccount}
      />
      <Route
        exact
        path={`${path}/sign-up/step-four`}
        component={AboutYourself}
      />
      <Route
        exact
        path={`${path}/sign-up/step-five`}
        component={OnBoardExperience}
      />
      <Route
        exact
        path={`${path}/sign-up/step-six`}
        component={EngagementModel}
      />
      <Route
        exact
        path={`${path}/login`}
        component={Login}
      />
      <Route
        exact
        path={`${path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route
        exact
        path={`${path}/reset-password`}
        component={ResetPassword}
      />
    </Switch>
  );
}
