import React, { useState, useMemo } from "react";
import { useField } from "formik";
import Select from "react-select";
import countryList from "react-select-country-list";

export default function CustomTextInput({ ...props }) {
  const [field, meta] = useField(props);
  const [clicked, setClicked] = useState(false);
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };
  return (
    <div
      style={{ width: props.wrapperWidth ? props.wrapperWidth : "auto", height: 46 }}
      className={`flex items-center justify-between ${props.mt ? props.mt : "mt-6"}   relative ${
        !meta.error && clicked ? "active" : clicked && meta.error ? "error" : "inactive"
      }-textInput`}
      style={{ marginLeft: props.magin }}
    >
      {props.phone && <Select options={options} placeholder="Select " value={value} onChange={changeHandler} />}
      <input
        type="search"
        autoComplete="off"
        style={{ color: "#38369A", width: props.phone ? "55%" : "100%", marginRight: 10 }}
        onClick={() => {
          setClicked(true);
        }}
        className={`focus:outline-none ${!props.width ? "" : props.width}`}
        {...field}
        {...props}
      />
      <img
        src={clicked && meta.error ? props.errorIcon : clicked && !meta.error ? props.activeIcon : props.inactiveIcon}
        alt="icon"
        className={`${!props.activeIcon ? "hidden" : ""}`}
      />
      <p
        style={{
          color: meta.error || props.hello ? "red" : "#38369A",
          top: meta.error || props.hello ? "45px" : "-0.5rem",
          left: meta.error || props.hello ? "-9px" : "1.25rem",
        }}
        className="absolute bg-white -top-2 text-xs px-2 left-5"
      >
        {meta.touched && meta.error ? meta.error : clicked ? props.placeholder : null}
        {/* {props.hello ? 'phone number is invaild' : ''} */}
      </p>
    </div>
  );
}
