import axios from "axios";

// const baseApi = 'http://localhost:3003/api/';
// const baseApi = "https://dev.strategyconnect.co/api/";
const baseApi = "https://api.strategyconnect.co/api/";

const Axios = axios.create({
  baseURL: baseApi,
});

export default Axios;
