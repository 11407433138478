import { combineReducers } from 'redux';
import startProjectReducer from './startProjectReducer';
import caseStudyReducer from './caseStudyReducer';
import errorReducer from './errorReducer';
import consultantReducer from './consultantReducer';
import companyReducer from './companyOnboardingReducer';

export default combineReducers({
  startProject: startProjectReducer,
  errors: errorReducer,
  caseStudy: caseStudyReducer,
  consultant: consultantReducer,
  company: companyReducer,
});
