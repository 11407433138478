/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import logo from "../../../../../assets/img/regLogo.svg";
import ContinueFooter from "../../../components/ContinueFooter";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
// messgae icons
import messActive from "../../../../../assets/img/messActive.svg";
import messInActive from "../../../../../assets/img/messInActive.svg";
import messError from "../../../../../assets/img/messError.svg";
// // password icons
import passActive from "../../../../../assets/img/passActive.svg";
import passInActive from "../../../../../assets/img/passInActive.svg";
import passError from "../../../../../assets/img/passError.svg";
import { useAlert } from "react-alert";
import CustomTextInput from "../../../components/CustomTextInput";

import API_PATH from "../../../../../api_config.json";
const axios1 = require("axios");
function LoginForm({ login }) {
  const history = useHistory();
  const [emailNotMatch, setEmailNotMatch] = useState("");
  const [passwordNotMatch, setPasswordNotMatch] = useState("");
  const [password, setPassword] = useState("");
  const alert = useAlert();

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    email: yup.string().email().required("Email is required"),
  });
  const getUserAcces = (formValues) => {};
  const handleSubmit = async (formValues) => {
    formValues.user_type_id = 3;
    //     email: "pankaj.kumar@konverge.ai"
    // password: "pankaj"
    // set_default: null
    // user_type_id: 3
    console.log(formValues);
    console.log("API_PATH.baseUrl", API_PATH.baseUrl);
    let y = getUserAcces(formValues.email);

    const response3 = await axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/users/getUserAccess`,
      data: { email: formValues.email },
    })
      .then((res) => {
        console.log("getUserAccess", res);
        // 1-Blogs,2-Careers,3-Both views
        if (res?.data?.data) {
          if (res?.data?.data?.isAuthorizedToPost == 0) {
            setEmailNotMatch("Sorry, you don't have access");
          }
          if (res?.data?.data?.isAuthorizedToPost == 1) {
            return 1;
          }
          if (res?.data?.data?.isAuthorizedToPost == 2) {
            return 2;
          }
          if (res?.data?.data?.isAuthorizedToPost == 3) {
            console.log("You  have access Both views");
            return 3;
          }
          return "pankaj";
        } else {
          setEmailNotMatch("Sorry, you don't have access");
          return false;
        }
        // isAuthorizedToPost
        // console.log("fileeeeeeeee Upload", res[0].asset_id);
        // setResumeValidation(false);
      })
      .catch((err) => {
        return "pankaj kumar";
        console.log(err);
      });

    const response = await axios1({
      method: "post",
      // v1.0/users/login
      url: `${API_PATH.baseUrl}/api/v1.0/users/login`,
      data: formValues,
    })
      .then((res) => {
        if (res.data.status == false) {
          setPasswordNotMatch(res.data.message);
        } else {
          if (response3) {
            localStorage.setItem("loginName", res?.data?.user?.name);
            localStorage.setItem("loginUserId", res?.data?.user?.user_id);
            localStorage.setItem("adminAccessType", response3);
            // history.push("/");
            window.location.href = "/";
            // window.location("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
      <div className=" flex flex-col justify-center md:px-16 xl:px-32 px-8 pb-28 lg:pb-32">
        <div className="flex items-center mx-auto mt-12">
          <img src={logo} alt="logo" className="" />
          <p className="text-base ml-2 text-black">StrategyConnect</p>
        </div>

        <h1 className="text-3xl text-center mt-9 waitBorderBottom">Login</h1>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          // onSubmit={handleSubmit}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            handleSubmit(values);
            // /v1.0/jobs/applications
          }}
        >
          {(props, isSubmitting) => {
            // setEmailNotMatch("");
            // setPasswordNotMatch("");

            return (
              <Form id="login" autoComplete="off">
                <CustomTextInput
                  autoComplete="off"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email address*"
                  // onChange={() => setEmailNotMatch("")}
                  // https://dev.strategyconnect.co/api/v1.0/users/getUserAccess

                  activeIcon={messActive}
                  inactiveIcon={messInActive}
                  errorIcon={messError}
                  onBlur={() => {
                    setEmailNotMatch("");
                    setPasswordNotMatch("");
                  }}
                />
                {<p className="text-error">{emailNotMatch}</p>}
                <CustomTextInput
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Password*"
                  // onChange={(e) => setPassword(e.target.value)}
                  activeIcon={passActive}
                  inactiveIcon={passInActive}
                  errorIcon={passError}
                  onBlur={() => {
                    setEmailNotMatch("");
                    setPasswordNotMatch("");
                  }}
                />
                {<p className="text-error">{passwordNotMatch}</p>}
                <div style={{ textAlign: "center", padding: "2rem" }}>
                  <button type="submit" className="p-2 rounded purple_re z-10 text-white text-sm">
                    Continue
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className="pt-8 font-light">
          {/* <ContinueFooter percentage={0} id="login" /> */}
          {/* Forgot password?{" "}
          <Link className="font-black" to="/freelancer/forgot-password">
            Reset here
          </Link> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default LoginForm;
