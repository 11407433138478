import React, { useState, useEffect } from "react";
import EllipsisText from "react-ellipsis-text";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import defaultCaseStudy from "../../../assets/img/defaultCaseStudy.png";

function FIlteredCards(props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [caseStudie, setCaseStudies] = useState();
  const [caseStudieImages, setCaseStudieImages] = useState();
  useEffect(() => {
    setCaseStudies(props.caseStudy);
    setCaseStudieImages(props.caseStudy_images);
  }, [props]);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div
      className={`${
        caseStudie && caseStudie.length === 0 && caseStudieImages && caseStudieImages.length === 0
          ? "flex"
          : "case_study_box"
      } w-full `}
    >
      {caseStudie && caseStudie.length === 0 && caseStudieImages && caseStudieImages.length === 0 ? (
        <p className="text-center mx-auto my-32">No Listings Found</p>
      ) : (
        caseStudie &&
        caseStudie.map((item, i) => {
          return (
            <Link key={item.cs_published_id} to={`/case-studies/${item.cs_published_id}`} className="m-w-100">
              <div className="case-box">
                <img
                  key={item.cs_published_id}
                  className="caseStudyImage"
                  src={item.signed_url || defaultCaseStudy}
                  loading="lazy"
                  alt="tyler"
                />
                {/* {caseStudieImages.length > 0 ? (
                  caseStudieImages.map((val) => {
                    if (item.case_study_id == val.case_study_id) {
                      return (
                        <img
                          key={val.case_study_id}
                          className="caseStudyImage"
                          src={val.signed_url ? val.signed_url : defaultCaseStudy}
                          loading="lazy"
                          alt="tyler"
                        />
                      );
                    }
                  })
                ) : (
                  <img className="caseStudyImage" src={defaultCaseStudy} loading="lazy" alt="tyler" />
                )} */}

                <div className="boxCaseStudyTop">
                  <div className="caseStudyText">{item.title}</div>
                  {JSON.parse(item.industries).length > 0 && (
                    <div style={{ fontSize: "0.8rem" }}>
                      <span style={{ fontWeight: "bold" }}> Industry : &nbsp;</span>
                      {JSON.parse(item.industries).map((indu, i) => {
                        if (i == 0) {
                          return <span>{indu.name}&nbsp;</span>;
                        } else if (i == 1) {
                          return <span>...</span>;
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          );
        })
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  Loading: state.caseStudy,
});

export default connect(mapStateToProps, {})(FIlteredCards);
