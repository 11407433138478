import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    headerDiv:{        
            background: '#f2f5f8',            
            '@media screen and (min-width:1025px)':{
                padding: '15px 0px',
               '& img':{
                   width:'18%',
                   margin: '0 auto',
               }
            },
            '@media screen and (max-width:767px)':{
                padding: '10px 0px',
                '& img':{
                    width:'200px',
                    margin: '0 auto',
                }
             },
             '@media screen and (min-width:768px) and (max-width:1024px)':{
                padding: '15px 0px',
               '& img':{
                   width:'30%',
                   margin: '0 auto',
               }
            },
            '@media screen and (min-width:960px)':{
               display:'none',
            },
    }
}));

export default useStyles;