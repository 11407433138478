import React from 'react';

export default function LineFive() {
  return (
    <svg
      width="573"
      height="232"
      className="mx-auto relative lineFive hidden xl:block"
      viewBox="0 0 573 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0V4.94017C1 60.1686 45.7715 104.94 101 104.94H472C527.229 104.94 572 149.712 572 204.94V232"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-dasharray="6 8"
      />
    </svg>
  );
}
