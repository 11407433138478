import React, { useEffect } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import MainAppHero from "./MainAppHero";
import MainStepOne from "./MainStepOne";
import MainStepThree from "./MainStepThree";
import MainStepTwo from "./MainStepTwo";
import MainStepFour from "./MainStepFour";
import StepFive from "./StepFive";
import { Helmet } from "react-helmet";

export default function MainApproach() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="We leverage a sprint-based, collaborative approach to enable an informed decision.
          "
        />
        <title>Sprint-Based Collaborative Approach | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <MainAppHero />
      <MainStepOne />
      <MainStepTwo />
      <MainStepThree />
      <MainStepFour />
      <StepFive />
      <Footer />
    </div>
  );
}
