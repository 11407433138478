import { country } from "../../pages/auth/Onboarding/OnboardingForm/Country";
import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";

import * as Yup from "yup";
import axios1 from "axios";
import classe from "./Carrers.module.css";
import ModalResponse from "./ModalResponse";
import Radio from "@material-ui/core/Radio";
import API_PATH from "../../api_config.json";
import error from "../../assets/img/error.png";
import upload from "../../assets/img/upload.png";
import success from "../../assets/img/success.png";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "../../pages/auth/Onboarding/OnboardingForm/Onboarding.css";
const axios = require("axios");

function JobApply(propsApply) {
  let history = useHistory();
  const classes = useStyles();
  const [coun, setCoun] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [phoneNoErrorMsg, setPhoneErrorMsg] = useState("");
  const [message, setMsg] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [cities, setCities] = useState([]);

  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState();
  const [filesId, setFilesId] = useState();
  const [fileNameError, setFileNameError] = useState("");
  const [fileSuccess, setFileSuccess] = useState(false);
  const [fileError, setFileError] = useState(false);

  const [countryCode, setCountryCode] = useState(null);
  const [formIkLike, setFormIkLike] = useState(null);
  const [formIkLikeValidation, setFormIkLikeValidation] = useState(null);
  const [resumeValidation, setResumeValidation] = useState(false);

  const getCities = async (country_code) => {
    const cityRes = axios
      .get(`${API_PATH.baseUrl}/api/v1.0/list/fetch-cities?search_term=${country_code}`)
      .then((response) => {
        if (response && response.data && response.data) {
          let cities = response.data.data.map((i) => `${i.city_ascii}, ${i.country}`);
          setCities(cities);
        }
      })
      .catch();
  };

  const fileUpload = (e) => {
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    let ext = file.name.split(".").pop();
    if (["pdf", "doc", "docx", "ppt", "pptx"].includes(ext)) {
      setFiles(e.target.files[0]);
      setFileSuccess(true);
      setFileError(false);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_platform_id", 1);
      formData.append("createdby_userid", 1);
      const response = axios1({
        method: "post",
        url: `${API_PATH.baseUrl}/api/v1.0/list/upload1`,
        data: formData,
      })
        .then((res) => {
          setResumeValidation(false);
          setFilesId(res.data[0].asset_id);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFileSuccess(false);
      setFileError(true);
      setFileNameError(" Valid file types .pdf .doc .docx .ppt .pptx");
    }
  };
  const getFormikLikeData = () => {
    let obj = {};
    let obj2 = {};
    propsApply?.detailsData?.job_related_question.map((res, index) => {
      obj[res.question.replace(/\s+/g, "").trim()] = "";
      obj2[res.question.replace(/\s+/g, "").trim()] = Yup.string().required(`${res.question} is required`);
      if (res.question.replace(/\s+/g, "").trim() == "EmailID") {
        obj2["EmailID"] = Yup.string().email("Email is invalid ").required(`${"Email Id"} is required`);
      }
      if (res.question.replace(/\s+/g, "").trim() == "YearsofExperience") {
        obj2["YearsofExperience"] = Yup.string()
          .required(`${res.question} is required`)
          .test("number", "Enter numeric input", (value) => {
            return /^-?[\d]+(?:e-?\d+)?$/.test(value);
          });
      }
      if (res.isRequired === 0) {
        obj2[res.question.replace(/\s+/g, "").trim()] = Yup.string();
      }
    });
    setFormIkLike(obj);
    setFormIkLikeValidation(obj2);
  };

  useEffect(() => {
    getFormikLikeData();
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    window.scrollTo(0, 0);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classe.careerDetails}>
      <div>
        <h4>Personal Details</h4>
      </div>
      {formIkLike && (
        <Formik
          autoComplete="off"
          initialValues={formIkLike && formIkLike}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            let aaa = propsApply?.detailsData.job_related_question.filter((pp) => {
              return pp[9];
              console.log("oppppppppppppppp", pp);
            });

            if (!countryCode && values.PhoneNumber) {
              setCountryError(true);
              return false;
            } else if (countryCode && values.PhoneNumber) {
              if (!isValidPhoneNumber(values.PhoneNumber, coun.countryCode)) {
                setPhoneErrorMsg("Invalid phone number format");
                return false;
              }
            }

            let sendData = {
              first_name: values.FirstName,
              last_name: values.LastName,
              email: values.EmailID,
              linkedin_url: values.LinkedInURL,
              country_code: values.CountryCode,
              phone: values.PhoneNumber,
              current_city: values.Location,
              years_of_experience: Number(values.YearsofExperience),
              resume_file_id: filesId,
              JobID: propsApply.detailsData.JobID,
              Job_Title: propsApply.detailsData.job_title,
              date: new Date(),
            };
            const response = axios1({
              method: "post",
              url: `${API_PATH.baseUrl}/api/v1.0/jobs/applications`,
              data: sendData,
            })
              .then((res) => {
                setVisibleModal(true);
                setMsg(res.data.message);
                localStorage.setItem("jobId", res.data.data.JobID);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          validationSchema={Yup.object().shape(formIkLikeValidation)}
        >
          {(props) => {
            const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
            return (
              <form
                autoComplete="off"
                id="onBoardingForm techDue"
                className={classes.onBoardingForm}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="input-grid">
                  {propsApply?.detailsData?.job_related_question.map((res, index) => {
                    let currentField = res.question.replace(/\s+/g, "").trim();
                    if (
                      res.question_type == "text" &&
                      res.question !== "Country Code" &&
                      res.question !== "Phone Number" &&
                      res.question !== "Location"
                    ) {
                      return (
                        <div key={index}>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              autoComplete: "none",
                            }}
                            autoComplete="off"
                            className={`${classes.paddingInput}`}
                            label={res.isRequired === 0 ? res.question + " (optional) " : res.question}
                            id={res.question}
                            placeholder={res.question}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.currentField}
                            name={res.question.replace(/\s+/g, "").trim()}
                            error={errors[currentField] && touched[currentField]}
                            helperText={errors[currentField] && touched[currentField] && errors[currentField]}
                          />
                        </div>
                      );
                    }

                    if (res.question == "Phone Number") {
                      return (
                        <div id="res-mtttp" className="mtttb country-grid">
                          <div>
                            <div className={classes.phoneNumberBlock}>
                              <label
                                className={classes.ccLabel}
                                style={{
                                  padding: "0px",
                                  color: " rgb(128 128 128 / 87%)",
                                  left: "4px",
                                  fontSize: "12px",
                                }}
                              >
                                Country Code
                              </label>
                              <p className="content-wrapper" style={{ border: "1px solid rgb(197 197 197)" }}>
                                {coun.countryFlag !== undefined && (
                                  <img
                                    src={coun.countryFlag}
                                    alt={coun.countryFlag}
                                    className="flag"
                                    style={{ marginLeft: "0px" }}
                                  />
                                )}
                                {coun.countryDialCode !== undefined && (
                                  <span className="country_dial_code" style={{ paddingTop: "0px", margin: "6px" }}>
                                    {coun.countryDialCode}
                                  </span>
                                )}
                              </p>
                              <select
                                className="country-code-select"
                                name={"CountryCode"}
                                onChange={(e) => {
                                  setCountryError(false);
                                  country
                                    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                    .map((country) => {
                                      if (country.name === e.target.value) {
                                        setCoun({
                                          countryDialCode: country.dial_code,
                                          countryName: country.name,
                                          countryCode: country.code,
                                          countryFlag: `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`,
                                        });
                                        getCities(country.code);
                                        setCountryCode(country.dial_code);
                                        values.CountryCode = country.dial_code;
                                      }
                                    });
                                }}
                                // value={coun.countryName}
                                value={values.CountryCode}
                              >
                                <option value="">Select Country</option>
                                {country
                                  .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                  .map((item, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={item.name}
                                        style={{ fontFamily: "Nunito Sans, sans-serif", color: "black" }}
                                      >
                                        {item.name + "     " + "(" + item.dial_code + ")"}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            {countryError ? (
                              <span className={classes.validationErrorText}>Country Code is required</span>
                            ) : null}

                            {<span className={classe.error}>{errors.CountryCode}</span>}
                          </div>
                          <div>
                            <TextField
                              className={`${classes.paddingInput}`}
                              label={res.isRequired === 0 ? res.question + " (optional) " : res.question}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id="mobile"
                              placeholder={res.question}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.currentField}
                              name={res.question.replace(/\s+/g, "").trim()}
                              error={errors[currentField] && touched[currentField]}
                              helperText={errors[currentField] && touched[currentField] && errors[currentField]}
                              inputProps={{
                                autoComplete: "none",
                              }}
                              onClick={() => {
                                setPhoneErrorMsg(null);
                              }}
                            />
                            {phoneNoErrorMsg ? (
                              <span
                                style={{ position: "relative", top: "-26px" }}
                                className={classes.validationErrorText}
                              >
                                {phoneNoErrorMsg}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                    if (res.question == "Location") {
                      return (
                        <>
                          <Autocomplete
                            key={index}
                            id="current_city"
                            name={res.question.replace(/\s+/g, "").trim()}
                            className={`${classes.paddingInput}`}
                            disableClearable
                            value={values.currentField}
                            options={cities}
                            getOptionLabel={(option) => option}
                            onChange={(_, value) => {
                              values[res.question.replace(/\s+/g, "").trim()] = value;
                            }}
                            error={errors[currentField] && touched[currentField]}
                            helperText={errors[currentField] && touched[currentField] && errors[currentField]}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "off";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name={res.question.replace(/\s+/g, "").trim()}
                                  // className={`${classes.paddingInput}`}
                                  type="search"
                                  autoComplete="off"
                                  variant="outlined"
                                  size="small"
                                  value={values.currentField}
                                  // margin="dense"
                                  label={res.isRequired === 0 ? res.question + " (optional) " : res.question}
                                  InputLabelProps={{ shrink: true }}
                                  error={errors[currentField] && touched[currentField]}
                                  helperText={errors[currentField] && touched[currentField] && errors[currentField]}
                                />
                              );
                            }}
                          />
                        </>
                      );
                    }

                    if (res.question_type == "radio") {
                      return (
                        <div className={classe.div} key={index}>
                          <label className={classe.label}>
                            {res.isRequired === 0 ? res.question + " (optional) " : res.question}
                          </label>
                          <RadioGroup
                            name={res.question.replace(/\s+/g, "").trim()}
                            value={values.currentField}
                            className={classe.g}
                            // value={values.currentField}
                            onChange={(e) => {
                              values[res.question.replace(/\s+/g, "").trim()] = e.target.value;
                            }}
                            row
                            aria-labelledby=""
                          >
                            {res.values.map((radio, radioIndex) => {
                              if (radio.value == "option") return false;
                              return (
                                <>
                                  <FormControlLabel
                                    key={radioIndex}
                                    value={radio.value}
                                    control={<Radio color="primary" />}
                                    label={radio.value}
                                    onChange={(e) => {
                                      values[res.question.replace(/\s+/g, "").trim()] = e.target.value;
                                    }}
                                    // name={res.question.replace(/\s+/g, "").trim()}
                                    name={res.question.replace(/\s+/g, "").trim()}
                                    error={errors[currentField] && touched[currentField]}
                                    helperText={errors[currentField] && touched[currentField] && errors[currentField]}
                                  />
                                </>
                              );
                            })}
                          </RadioGroup>
                          {<span className={classe.error}>{errors[currentField]}</span>}
                        </div>
                      );
                    }
                    if (res.question_type == "file") {
                      return (
                        <div className={classe.div} key={index}>
                          <label className={classe.label}>
                            {" "}
                            {res.isRequired === 0 ? res.question + " (optional) " : res.question}
                          </label>
                          <div className={classe.g}>
                            <input
                              type="file"
                              id="fileUpload"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                setFileName(e.target.files[0].name);
                                let ext = file.name.split(".").pop();
                                if (["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext)) {
                                  fileUpload(e);
                                  values[res.question.replace(/\s+/g, "").trim()] = e.target.files[0].name;
                                } else {
                                  setFileSuccess(false);
                                  setFileError(true);
                                  setFileNameError(" Valid file types .pdf .doc .docx .ppt .pptx");
                                }
                              }}
                              style={{ display: "none" }}
                              name={res.question.replace(/\s+/g, "").trim()}
                            />
                            {/* error={errors[currentField] && touched[currentField]} */}
                            <label className={classe.file} htmlFor="fileUpload">
                              {fileName && (
                                <>
                                  {fileSuccess && (
                                    <>
                                      {fileName} <img src={success} style={{ display: "inline" }} />
                                    </>
                                  )}
                                  {fileError && (
                                    <span>
                                      {fileNameError} <img src={error} style={{ display: "inline" }} />
                                    </span>
                                  )}
                                </>
                              )}
                              <img src={upload} style={{ float: "right" }} />
                            </label>
                          </div>
                          {touched[currentField] && errors[currentField] && (
                            <span className={classe.error}>{errors[currentField]}</span>
                          )}
                          {/* {resumeValidation && <span className={classe.error}>Resume is required</span>} */}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className={classe.center}>
                  <button type="submit" className={classe.carrierButton}>
                    Submit
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
      {visibleModal && (
        <ModalResponse
          onClose={() => {
            setVisibleModal(false);
            setMsg("");
            history.push("/careers");
          }}
          open={visibleModal}
          message={message}
        />
      )}
    </div>
  );
}

export default JobApply;
