import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import axios1 from "axios";
import CareersList from "./CareersList";
import classes from "./Carrers.module.css";
import CareersSearch from "./CareersSearch";
import API_PATH from "../../api_config.json";
import AddIcon from "@material-ui/icons/Add";
import CareersImage from "../../assets/img/Careers.jpg";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import ModalResponse from "./ModalResponse";

const user_tyep_id = localStorage.getItem("adminAccessType");
function Careers() {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [viewApplication, setViewApplication] = useState(false);
  const [msg, setMsg] = useState("");
  const [visible, setVisible] = useState(false);

  const getAllList = async (search_input) => {
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/jobs/fetchAll-jobs?offset=10&search_term=${search_input}&from=0`,
    })
      .then((res) => {
        if (res && res?.data && res?.data?.data && res?.data?.data?.rows?.length > 0) {
          setData(res.data.data.rows);
          let pagination = res.data.data.count / 10;
          // let pagination = res.data.data.count;
          if (pagination >= 0) {
            setCount(pagination);
          } else {
            setCount(0);
          }
        } else {
          setData([]);
          setCount(0);

          // let pagination = res.data.data.count;
          // if (pagination > 0) {
          //   setCount(pagination);
          // } else {
          //   setCount(0);
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteJob = async (id) => {
    await axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/jobs/delete-job`,
      data: { id: id },
    })
      .then((res) => {
        setMsg(res.data.message);
        setVisible(true);
        getAllList("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // window.scrollTo(0, 0);
    getAllList("");
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Careers | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div className={classes.pae}>
        <img src={CareersImage} className={classes.widthFull} />
        <h1 className={classes.cHeader}>Careers at StrategyConnect</h1>
        <div className={classes.lrP}>
          <div>
            {user_tyep_id == 2 || user_tyep_id == 3 ? (
              <h3 className={classes.cTitle}>
                {/* Open Positions */}
                <ul>
                  <li
                    className={!viewApplication ? classes.active : ""}
                    onClick={() => {
                      setViewApplication(false);
                      history.push("/careers");
                    }}
                  >
                    Open Positions
                  </li>
                  <li
                    className={viewApplication ? `${classes.active} ${classes.textLeft}` : classes.textLeft}
                    onClick={() => {
                      setViewApplication(true);
                      history.push("/careers/view-application");
                    }}
                  >
                    View Applications
                  </li>
                </ul>
                <button
                  className={classes.createBtn}
                  onClick={() => {
                    history.push(`/careers/create`);
                  }}
                >
                  Create <AddIcon />
                </button>
              </h3>
            ) : (
              <h3 className={classes.cTitle}>Open Positions</h3>
            )}
            {}
          </div>

          <CareersSearch
            getAllList={(search_input) => {
              getAllList(search_input);
            }}
          />
        </div>
      </div>
      <CareersList
        data={data}
        count={count}
        deleteJob={(id) => {
          deleteJob(id);
        }}
        //  props.deleteJob(res);
      />
      {visible && (
        <ModalResponse
          onClose={() => {
            setVisible(false);
            setMsg("");
          }}
          open={visible}
          message={msg}
        />
      )}
      <Footer />
    </div>
  );
}

export default Careers;
