/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import downArrow from '../../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../../assets/img/textUpArrow.svg';
import edit from '../../../../../assets/img/edit.svg';
import trash from '../../../../../assets/img/trash.svg';
import CustomDropdown from '../../../components/CustomDropdown';

export default function index({
  name,
  value,
  id,
  languagesValues,
  langArrayList,
  deleteFromArrayList,
  setArrList,
}) {
  const [employmentType, setEmploymentType] = useState('');

  const onSubmit = () => {
    setArrList(employmentType);
  };

  return (
    <div className="border border-gray-400 rounded mt-7 p-5">
      <div
        style={{
          borderBottom: '1px solid rgba(10, 10, 10, 0.1)',
          paddingBottom: 9,
        }}
        className="flex justify-between items-center"
      >
        <p style={{ color: '#707376' }}>Industries *</p>
      </div>

      <div className="flex justify-between items-center">
        <CustomDropdown
          items={languagesValues}
          placeholder="Select Expertise"
          id={id}
          width="w-full mr-4"
          onValueChange={(val) => {
            setEmploymentType(val);
          }}
          downArrow={downArrow}
          upArrow={upArrow}
        />

        <button
          style={{ height: 43 }}
          className="bg-indigo-800 text-white text-sm mt-5  h-7 px-3 rounded "
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          Save
        </button>
      </div>

      {langArrayList.map((item, index) => {
        return (
          <div
            key={index + 'dsjfkhndlkm'}
            style={{ backgroundColor: 'rgba(177, 181, 185, 0.09)' }}
            className="flex items-center rounded justify-between mt-4 py-2 px-3"
          >
            <p
              className="text-sm w-full"
              style={{
                color: '#0A0A0A',
                borderRight: '0.6px solid #B1B5B9',
              }}
            >
              {item.lang}
            </p>
            <div className="flex items-center ml-4">
              <img src={edit} alt="edit" className="cursor-pointer" />
              <img
                onClick={() => {
                  deleteFromArrayList(index);
                }}
                src={trash}
                alt="delete"
                className="ml-2 cursor-pointer"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
