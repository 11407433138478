import axios from "../../services/api";

import axios1 from "axios";
import API_PATH from "../../api_config.json";
import {
  GET_CASE_STUDIES,
  GET_CASE_STUDIES_ITEM,
  GET_CASE_STUDIES_ERROR,
  GET_CASE_STUDIES_LOADING,
  GET_CASE_STUDY,
  GET_CASE_STUDY_LOADING,
  GET_CASE_STUDY_ERROR,
  CLEAR_CASE_STUDY,
} from "./types";

export const getCaseStudies = () => (dispatch) => {
  dispatch(setCaseStudyLoading());
  //dev.strategyconnect.co/api/v1.0/knowledge-management/fetchAll-published-case-studies?from=0&offset=10&search_by=1&search_term=&sort_by=id&sort_order=DESC&user_type=Admin&user_id=467
  https: axios1({
    method: "get",
    url: `${API_PATH.baseUrl}/api/v1.0/knowledge-management/fetchAll-published-case-studies?from=0&offset=10&search_by=1&search_term=&sort_by=id&sort_order=DESC&user_type=Admin&user_id=467`,
    // data: {
    //   offset: 10,
    //   from: 0,
    //   org_id: 0,
    //   search_by: 1, //title:2   ,  industries:3,skill:4,
    //   search_term: "",
    //   sort_by: "id", // date
    //   sort_order: "DESC",
    // },
    // headers: {
    //   "auth-token": Cookies.get("token"),
    //   "user-id": this.props.data.user.data.id,
    // },
  })
    .then((res) => {
      const industry = res.data.data;
      localStorage.setItem("industry", JSON.stringify(industry));
      dispatch({
        type: GET_CASE_STUDIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CASE_STUDIES_ERROR,
        payload: "Error Fetching Data",
      });
    });
  // .get('data/generic/case_study/public-list?start=0&limit=10&filters={"industry_code":0,"capability_id":0}', {
  // .post('v1/nonsecure/get-all-published-case-studies', {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: "test",
  //     namespace: "WEB",

  //     // 'X-Requested-With': 'XMLHttpRequest',
  //     // 'Access-Control-Allow-Origin': '*',
  //     // crossorigin: 'true',
  //   },
  //   data : {
  //     "offset":10,
  //     "from":0,
  //     "org_id": 0,
  //     "search_by":3,
  //     "search_term":"",
  //     "sort_by":"id",
  //     "sort_order": "DESC"
  //   }
  // })
  // .then((res) => {
  //   const industry = res.data.data.list;
  //   localStorage.setItem("industry", JSON.stringify(industry));
  //   dispatch({
  //     type: GET_CASE_STUDIES,
  //     payload: res.data.data.list,
  //   });
  // })
  // .catch((err) => {
  //   dispatch({
  //     type: GET_CASE_STUDIES_ERROR,
  //     payload: "Error Fetching Data",
  //   });
  // });
};

export const clearCaseStudies = () => (dispatch) => {
  dispatch({
    type: CLEAR_CASE_STUDY,
    payload: [],
  });

  dispatch(getCaseStudies());
};

export const getCaseStudiesByIndustries =
  (id, limit = 100) =>
  (dispatch) => {
    dispatch(setCaseStudyLoading());
    axios
      .get(
        `data/generic/case_study/public-list?start=0&limit=${limit}&sort=id&sort_order=asc&filters={"industry_code":${id},"capability_id":0}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "test",
            namespace: "WEB",

            // 'X-Requested-With': 'XMLHttpRequest',
            // 'Access-Control-Allow-Origin': '*',
            // crossorigin: 'true',
          },
        }
      )
      .then((res) => {
        // const industry = res.data.data.list;
        // localStorage.setItem('industry', JSON.stringify(industry));
        dispatch({
          type: GET_CASE_STUDIES,
          payload: res.data.data.list,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CASE_STUDIES_ERROR,
          payload: "Error Fetching Data",
        });
      });
  };
export const getCaseStudiesByProjectTag = (tag) => (dispatch) => {
  dispatch(setCaseStudyLoading());
  axios
    .get(
      `data/generic/case_study/public-list?start=0&limit=100&sort=id&sort_order=asc&filters={"industry_code":0,"capability_id":0,"tags":[${tag}]}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "test",
          namespace: "WEB",

          // 'X-Requested-With': 'XMLHttpRequest',
          // 'Access-Control-Allow-Origin': '*',
          // crossorigin: 'true',
        },
      }
    )
    .then((res) => {
      // const industry = res.data.data.list;
      // localStorage.setItem('industry', JSON.stringify(industry));
      dispatch({
        type: GET_CASE_STUDIES,
        payload: res.data.data.list,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CASE_STUDIES_ERROR,
        payload: "Error Fetching Data",
      });
    });
};

export const getCaseStudiyItem = (id) => (dispatch) => {
  dispatch(setCaseStudyLoading());
  axios
    .get(`api/v1/caseStudyPublished/get-published-case-study-details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "test",
        namespace: "WEB",

        // 'X-Requested-With': 'XMLHttpRequest',
        // 'Access-Control-Allow-Origin': '*',
        // crossorigin: 'true',
      },
    })
    .then((res) => {
      dispatch({
        type: GET_CASE_STUDIES_ITEM,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CASE_STUDIES_ERROR,
        payload: "Error Fetching Data",
      });
    });
};

// set industry data loading
export const setCaseStudyLoading = () => {
  return {
    type: GET_CASE_STUDIES_LOADING,
  };
};

export const getCaseStudyDetail = (id) => (dispatch) => {
  dispatch({
    type: GET_CASE_STUDY_LOADING,
  });
  axios1({
    method: "get",
    url: `${API_PATH.baseUrl}/api/v1.0/knowledge-management/get-published-case-study-details?published_case_study_id=${id}`,
    // data: {
    //   published_case_study_id: parseInt(id),
    // },
  })
    .then((res) => {
      if (res.data.data.length > 0) {
        dispatch({
          type: GET_CASE_STUDY,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_CASE_STUDY_ERROR,
          payload: "something went wrong",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_CASE_STUDY_ERROR,
        payload: "Error Fetching Data",
      });
    });
  // axios
  //   .get(`data/generic/case_study/detail?id=${id}&events=["CASE_STUDY_VIEW"]&deep=["project_id","sections"]`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "test",
  //       namespace: "WEB",

  //       // 'X-Requested-With': 'XMLHttpRequest',
  //       // 'Access-Control-Allow-Origin': '*',
  //       // crossorigin: 'true',
  //     },
  //   })
  //   .then((res) => {
  //     console.log(res.data.data);
  //     if (res.data.meta.ok === 1) {
  //       dispatch({
  //         type: GET_CASE_STUDY,
  //         payload: res.data.data,
  //       });
  //     } else {
  //       dispatch({
  //         type: GET_CASE_STUDY_ERROR,
  //         payload: res.data.meta.message,
  //       });
  //     }
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: GET_CASE_STUDY_ERROR,
  //       payload: "Error Fetching Data",
  //     });
  //   });
};
