import React, { useEffect } from 'react';
import Navbar from '../../components/Home/SmallHome/components/Navbar';
import Footer from '../../components/Home/SmallHome/components/Footer';
import AboutProfile from '../Profile/AboutProfile';
import CompanyExperience from './CompanyExperience';
import ProfilePicture from '../Profile/ProfilePicture';
import CompanyProjects from './CompanyProjects';
import pep from '../../assets/img/company.webp';
import zazzy from '../../assets/img/zazzy.svg';

import { getCompanyData } from '../../state/actions/companyAction';
import { getFunctionalList } from '../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import LoadingOverlay from 'react-loading-overlay';
import CompanyProfileHeading from './CompanyProfileHeading';

function CompanyProfile({ getCompanyData, company, getFunctionalList }) {
  const alert = useAlert();

  useEffect(() => {
    var aValue = localStorage.getItem('companyAuth');

    getCompanyData(aValue, (res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
    getFunctionalList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
  }, []);
  return (
    <LoadingOverlay active={company.loading} spinner text=" ">
      <Navbar />
      <div className="xl:px-36 px-8 mt-20 ">
        <div className="lg:border  rounded mt-5   lg:mt-10 lg:mb-20 mb-10  border-gray-100">
          <ProfilePicture img={pep} />
          <div className="xl:px-20 md:px-10 px-0">
            <CompanyProfileHeading
              company={company}
              logo={zazzy}
              name={company.companyData.name}
              industryList={company.companyData.companyIndustry}
            />
            <AboutProfile aboutText={company.companyData.about} />
            <CompanyExperience
              functionalArray={company.companyData.functionalExpertise}
            />
            <CompanyProjects />
          </div>
        </div>
      </div>
      <Footer />
    </LoadingOverlay>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
  company: state.company,
});

export default connect(mapStateToProps, { getCompanyData, getFunctionalList })(
  CompanyProfile
);
