import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
// import useStyles from "./Onboarding.css";
import Select from "react-select";
import useStyles from "../../../pages/auth/Onboarding/OnboardingForm/Onboarding.css";
import { country } from "../../../pages/auth/Onboarding/OnboardingForm/Country";
import { isValidPhoneNumber } from "libphonenumber-js";
import CircularProgress from "@material-ui/core/CircularProgress";
// import SussessAlert from './../../components/Alert/SussessAlert';
import SussessAlert from "../../../pages/auth/components/Alert/SussessAlert";
import ErrorAlert from "../../../pages/auth/components/Alert/ErrorAlert";
import API_PATH from "../../../api_config.json";
// import CreatableSelect from 'react-select/creatable';
import CreatableSelect from "react-select/creatable";
import phone from "../../../assets/img/phone-white.svg";
import whats from "../../../assets/img/whatsWhite.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";

const axios = require("axios");
const Engagement = () => {
  const classes = useStyles();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [fCapabilities, setfCapabilities] = useState([]);
  const [fcId, setFcId] = useState([]);
  const [fcIdMaual, setFcIdManual] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [ifId, setIfId] = useState([]);
  const [ifIdMaual, setIfIdManual] = useState([]);
  const [coun, setCoun] = useState([]);
  const [cities, setCities] = useState([]);
  const [currCity, setCurrCity] = useState("");
  const [currCityStatus, setCurrCityStatus] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [checkCountryCode, setCheckCountryCode] = useState(false);
  const [preference, setPreference] = useState(3);
  const [remoteWorking, setRemoteWorking] = useState(1);
  const [contact, setContact] = useState("");
  const [contactStatus, setContactStatus] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [errorMagDesc, setErrorMagDesc] = useState("Somthing went wrong !");
  const [skillValue, setSkillValue] = useState([]);
  const [industryValue, setIndustryValue] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  // const [industry, setIndustry] = useState([]);
  const [fcCheck, setFCCheck] = useState(false);
  const [selectCountryCode, setSelectCountryCode] = useState(false);
  const [currentCityMsg, setCurrentCityMsg] = useState("Select country code first for city *");
  const [phoneNoErrorMsg, setPhoneErrorMsg] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    // getFunctionalCapabilities("");
    getIndustryFocus("");
  }, []);

  const isNumber = (val) => {
    return /^-?[\d]+(?:e-?\d+)?$/.test(val);
  };

  const initialValues = {
    firstName: "",
    email: "",
    currentOrganization: "",
    //   date: new Date(),
    budget: "",
  };

  const validationSchemaY = yup.object().shape({
    firstName: yup
      .string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    // firstName: yup.string().required('Name is required'),
    email: yup.string().email("Invalid email id").required("Email is required"),
  });

  const validationSchemaN = yup.object().shape({
    firstName: yup
      .string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    //   firstName: yup.string().required('Name is required'),
    email: yup.string().email("Invalid email id").required("Email is required"),
  });

  /*Set contact no validation */
  const countryCodeCheck = (e) => {
    setContactError(false);
    if (coun.countryDialCode === undefined || coun.countryDialCode === "") {
      setCheckCountryCode(true);
    } else {
      setCheckCountryCode(false);
      if (e.target.value !== "") {
        if (isNumber(e.target.value)) {
          setContactStatus(false);
          setContactError(false);
          setPhoneErrorMsg("");
          setContact(e.target.value);
        } else {
          setContactStatus(true);
          setContactError(true);
          setPhoneErrorMsg("Enter numeric input");
        }
      } else {
        setContactStatus(false);
        setContactError(false);
        setPhoneErrorMsg("");
        setContact(e.target.value);
      }
    }
  };
  /*Set contact no validation */

  /* Handle all validation on from submit */
  const handleSubmit = async (formValues, { resetForm }) => {
    setSuccessMsg(false);
    setErrorMsg(false);
    if (coun.countryDialCode === undefined || coun.countryDialCode === "") {
      setCheckCountryCode(true);
      setContactStatus(true);
    } else {
      setCheckCountryCode(false);
      if (contact === undefined || contact === "") {
        setContactStatus(true);
        setContactError(true);
        setPhoneErrorMsg("Phone number is required");
      } else {
        setContactStatus(false);
        setContactStatus(true);
        let isValidPhone = isValidPhoneNumber(contact, coun.countryCode);
        if (!isValidPhone) {
          setContactError(true);
          setPhoneErrorMsg("Phone number is invalid");
        } else {
          setContactError(false);
          setPhoneErrorMsg("");
          // if (currCity === '') {
          //     setCurrCityStatus(true);
          // }
          // else {
          setCurrCityStatus(false);
          // if (fcId.length > 0 || fcIdMaual.length > 0) {
          setFCCheck(false);
          setLoaderStatus(true);

          let sendData = {
            budget: formValues.budget,
            capabilities: fcId,
            country_code: coun.countryDialCode,
            created_by: 0,
            email: formValues.email,
            estimated_start_date: date,
            id: null,
            industries: ifId,
            mobile: contact,
            name: formValues.firstName,
            organization: formValues.currentOrganization,
          };
          await setContactDetails(sendData, resetForm);
        }
      }
    }
  };
  /* Handle all validation on from submit */

  // Other Capability Add
  const setOtherCapability = async (values) => {
    if (fcIdMaual.length > 0) {
      await axios({
        method: "post",
        data: { unapproved_skills: fcIdMaual, request_for: "project_lead", consultant_id: values },
        url: API_PATH.baseUrl + API_PATH.setOtherCapabilityData,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      }).then((res) => {
        console.log(res);
      });
    }
  };

  /* Set Final Project Lead Data */
  const setProjectLeadData = async (values, resetForm) => {
    try {
      await axios({
        method: "post",
        data: values,
        url: API_PATH.baseUrl + API_PATH.setProjectLeadsData,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(function (response) {
          if (response.status && response.status == "200") {
            let resData = response.data;
            if (resData.meta.ok == 1) {
              setOtherCapability(response.data.data.id); //
              setSuccessMsg(true);
              setLoaderStatus(false);
              resetForm({});
              setSkillValue([]);
              setIndustryValue([]);
              setContact("");
              setCurrCity("");
              setCities([]);
              setRemoteWorking(1);
              setPreference(3);
              setCoun({
                countryDialCode: "",
                countryName: "",
                countryCode: "",
                countryFlag: "",
              });
            } else {
              setErrorMsg(true);
              setLoaderStatus(false);
              setErrorMagDesc("This email id already exists !");
            }
          } else {
            setErrorMsg(true);
            setLoaderStatus(false);
            setErrorMagDesc("Somthing went wrong !");
          }
        })
        .catch(function (error) {
          setErrorMsg(true);
          setLoaderStatus(false);
          setErrorMagDesc("Somthing went wrong !");
        });
    } catch (error) {
      setErrorMsg(true);
      setLoaderStatus(false);
      setErrorMagDesc("Somthing went wrong !");
    }
  };
  /* Set Final Project Lead Data */

  /*Insert Leads from form*/

  const setContactDetails = async (values, resetForm) => {
    try {
      await axios({
        method: "post",
        data: values,
        url: `${API_PATH.baseUrl}/api/v1.0/leads/save-lead`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(function (response) {
          if (response?.data?.status == "200") {
            setSuccessMsg(true);
            setLoaderStatus(false);
            resetForm({});
            setSkillValue([]);
            setIndustryValue([]);
            setContact("");
            setCurrCity("");
            setCities([]);
            setRemoteWorking(1);
            setPreference(3);
            setCoun({
              countryDialCode: "",
              countryName: "",
              countryCode: "",
              countryFlag: "",
            });
            if (ifIdMaual.length > 0) {
              const param = {
                request_for: "project_lead",
                lead_id: response.data.data.id,
                unapproved_industries: ifIdMaual,
              };
              addNewUnapprovedIndustries(param);
            }
            if (fcIdMaual.length > 0) {
              const param = {
                request_for: "project_lead",
                lead_id: response.data.data.id,
                unapproved_capabilities: fcIdMaual,
              };
              addNewUnapprovedCapabilities(param);
            }
            // setProjectLeadData(data, resetForm);
          } else {
            setErrorMsg(true);
            setLoaderStatus(false);
            setErrorMagDesc("Somthing went wrong !");
          }
        })
        .catch(function (error) {
          setErrorMsg(true);
          setLoaderStatus(false);
          setErrorMagDesc("Somthing went wrong !");
        });
    } catch (error) {
      setErrorMsg(true);
      setLoaderStatus(false);
      setErrorMagDesc("Somthing went wrong !");
    }
  };

  const addNewUnapprovedIndustries = async (data) => {
    try {
      await axios({
        method: "post",
        data: data,
        url: `${API_PATH.baseUrl}/api/v1.0/knowledge-management/save-unapproved-industries`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewUnapprovedCapabilities = async (data) => {
    try {
      await axios({
        method: "post",
        data: data,
        url: `${API_PATH.baseUrl}/api/v1.0/talent-management/save-unapproved-skills`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*Insert Onboarding from Data*/

  /* Close Alert msg box */
  const closeSuccessAlert = () => {
    setSuccessMsg(false);
  };

  const closeErrorAlert = () => {
    setErrorMsg(false);
  };
  /* Close Alert msg box */

  /*Get Cities by using country code */
  const setCountryFilter = async (isoCode) => {
    try {
      axios({
        method: "post",
        url: API_PATH.baseUrl + API_PATH.filterCitiesConsultant,
        data: { code: isoCode },
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let cc = response.data.data;
            cc = cc.list;
            if (cc.length > 0) {
              let useCityName = [];
              cc.map((city) => {
                useCityName.push(city.city_ascii + ", " + city.country);
              });
              setCities(useCityName);
              setSelectCountryCode(false);
              setCurrentCityMsg("Select country code first for city *");
              setCurrCity("");
            } else {
              setCities([]);
              setSelectCountryCode(true);
              setCurrentCityMsg("No city available for this country code");
              setCurrCity("");
            }
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };
  /*Get Cities by using country code */

  /* Get Functional Capabilities by using api*/
  const getFunctionalCapabilities = (value) => {
    try {
      axios({
        method: "get",
        url: `${API_PATH.baseUrl}/api/v1.0/list/fetchAll-capabilities?search_term=${value}&limit=50`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let skills = response?.data?.data.map((item) => ({ value: item.id, label: item.name }));
            setfCapabilities(skills);
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };

  const handleFilter = (inputValue) => {
    if (inputValue.length >= 1) {
      getFunctionalCapabilities(inputValue);
    } else {
      setfCapabilities([]);
    }
  };

  /* Get Industry Focus by using api */

  const getIndustryFocus = (value) => {
    try {
      axios({
        method: "get",
        url: `${API_PATH.baseUrl}/api/v1.0/list/fetchAll-industries?search_term=${value}&limit=50`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let skills = response?.data?.data.map((item) => ({ value: item.id, label: item.name }));
            setIndustryList(skills);
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };

  const setFilterData = async (key, value) => {
    if (key === "industries") {
      let indus = [];
      indus = value.map((i) => {
        return i.value;
      });
      await setIfId(indus);
      setFCCheck(false);
    }
    if (key === "skills") {
      await setSkillValue(value);
      let skillsSet = [];
      value.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);
    }
  };

  const handleFCChange = async (newValue, actionMeta) => {
    await setSkillValue(newValue);
    if (actionMeta.action == "select-option") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);
    } else if (actionMeta.action == "create-option") {
      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setFcIdManual(skillSetManual);
      setFCCheck(false);
    } else if (actionMeta.action == "remove-value") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);

      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setFcIdManual(skillSetManual);
      setFCCheck(false);
    } else {
      await setFcIdManual([]);
      await setFcId([]);
      setFCCheck(false);
      await setSkillValue([]);
    }
  };

  const handleINDUSTRYChange = async (newValue, actionMeta) => {
    await setIndustryValue(newValue);
    if (actionMeta.action == "select-option") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setIfId(skillsSet);
      setFCCheck(false);
    } else if (actionMeta.action == "create-option") {
      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setIfIdManual(skillSetManual);
      setFCCheck(false);
    } else if (actionMeta.action == "remove-value") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);

      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setIfIdManual(skillSetManual);
      setFCCheck(false);
    } else {
      await setIfIdManual([]);
      await setIfId([]);
      setFCCheck(false);
      await setIndustryValue([]);
    }
  };
  console.log("this is hellooo", ifIdMaual);
  console.log("this is hellooo", fcIdMaual);
  return (
    <>
      <div className="flex lg:flex-row flex-col lg:my-20 my-10 justify-between  xl:px-28">
        <div className="mx-auto lg:w-96">
          <p className="lg:text-2xl text-xl text-center">Kickstart Engagement</p>
          <p className="text-sm grey-text mt-2 text-center">It takes less than a minute</p>
          <a
            href="tel:+971527344538"
            className="text-white purple lg:w-48 w-full flex items-center mx-auto lg:mt-14 mt-8 justify-between border-none focus:outline-none py-2 rounded px-5"
          >
            <img src={phone} loading="lazy" alt="phone-white" className="" />
            <p className="relative ">Phone Call</p>
            <p />
          </a>

          {/* <button className="text-white purple lg:w-48 w-full flex items-center mx-auto mt-5 justify-between border-none focus:outline-none py-2 rounded px-5">
      <img src={chat} loading="lazy" alt="phone-white" className="" />
      <p className="relative">Chat</p>
      <p />
    </button> */}

          <a
            target="_blank"
            href="https://wa.me/+971527344538"
            className="text-white purple lg:w-48 w-full flex items-center mx-auto mt-5 justify-between border-none focus:outline-none py-2 rounded px-5"
          >
            <img src={whats} loading="lazy" alt="phone-white" className="" />
            <p className="relative">Whatsapp</p>
            <p />
          </a>
        </div>
        <div className="h-96 w-0.5 hidden lg:block bg-gray-100" />
        <div
          className="mx-auto flex  flex-col  padding_top_border lg:border-none px-8 lg:px-0 lg:mt-0 mt-10 pt-10 lg:pt-0  justify-center items-center"
          style={{ width: "50%" }}
        >
          <p className="text-2xl text-center">Send us a message</p>
          <p className="text-sm grey-text text-center mt-2">A member of our team will get back to you shortly.</p>
          <Formik
            initialValues={initialValues}
            validationSchema={remoteWorking == 1 ? validationSchemaY : validationSchemaN}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors = {};
              if (values.budget && isNaN(values.budget)) {
                errors.budget = "Only number is allowed";
              }
              return errors;
            }}
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
              return (
                <Form
                  id="onBoardingForm"
                  className={classes.onBoardingForm}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="flex flex-wrap -mx-3 px-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="firstName"
                          name="firstName"
                          label="Name *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.firstName && touched.firstName && errors.firstName}
                          error={errors.firstName}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="email"
                          name="email"
                          label="Email ID *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.email && touched.email && errors.email}
                          error={errors.email}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 phoneNoBlock mobile-view">
                      <div className="w-full md:w-2/5 pt-6 ph-left">
                        <div className={classes.phoneNumberBlock}>
                          <label className={classes.ccLabel} style={{ padding: "0px" }}>
                            Country Code *
                          </label>
                          <p className="content-wrapper">
                            {coun?.countryFlag !== undefined && (
                              <img
                                src={coun?.countryFlag}
                                className="flag"
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                              />
                            )}
                            {coun.countryDialCode !== undefined && (
                              <span className="country_dial_code" style={{ paddingTop: "0px", margin: "6px" }}>
                                {coun.countryDialCode}
                              </span>
                            )}
                          </p>
                          <select
                            className="country-code-select"
                            onChange={(e) => {
                              country
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((country) => {
                                  if (country.name === e.target.value) {
                                    setCoun({
                                      countryDialCode: country.dial_code,
                                      countryName: country.name,
                                      countryCode: country.code,
                                      countryFlag: `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`,
                                    });
                                    setCountryFilter(country.code);
                                  }
                                });
                            }}
                            value={coun.countryName}
                          >
                            <option value="">Select Country</option>
                            {country
                              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                              .map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item.name}
                                    style={{ fontFamily: "Nunito Sans, sans-serif", color: "black" }}
                                  >
                                    {item.name + "     " + "(" + item.dial_code + ")"}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {checkCountryCode ? (
                          <span className={classes.validationErrorText}>Code is required</span>
                        ) : null}
                      </div>
                      <div
                        className={
                          contactStatus ? "w-full md:w-3/5 ph-right phoneValidation" : "w-full md:w-3/5 ph-right"
                        }
                      >
                        <div className="text-box">
                          <TextField
                            type="search"
                            autoComplete="off"
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={countryCodeCheck}
                            value={contact}
                            label="Phone Number *"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            style={{ paddingLeft: "2px" }}
                          />
                        </div>
                        {contactError ? <span className={classes.validationErrorText}>{phoneNoErrorMsg}</span> : null}
                      </div>
                      {/* Country Code ... */}
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="currentOrganization"
                          name="currentOrganization"
                          label="Organization"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.currentOrganization}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.currentOrganization && touched.currentOrganization && errors.currentOrganization
                          }
                          error={errors.currentOrganization}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div
                        className="text-box"
                        //  style={dimensions.width < 768 ? {} : { paddingTop: "0" }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            className="addLeadFormLeft"
                            variant="inline"
                            format="dd/MM/yyyy"
                            // margin="normal"
                            id="Est. Start Date "
                            label="Est. Start Date "
                            inputVariant="outlined"
                            value={date}
                            onChange={(date) => {
                              setDate(date);
                            }}
                            autoOk={true}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div
                        className="text-box"
                        //  style={dimensions.width < 768 ? {} : { paddingTop: "0" }}
                      >
                        <TextField
                          type="search"
                          autoComplete="off"
                          className="addLeadFormRight"
                          id="budget"
                          label="Budget"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          name="budget"
                          variant="outlined"
                          error={errors.budget}
                          helperText={errors.budget && touched.budget && errors.budget}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.budget}
                        />
                        <br />
                        {errors.budget && <br />}
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 drop-block-multi mobile-view-ot">
                      <div className={`${classes.multiSelect}`}>
                        <label className={classes.fcc_label}>Industry Focus</label>
                        <CreatableSelect
                          className={`creatableSelect ${classes.creatableSelect1}`}
                          isMulti
                          isClearable={false}
                          onChange={handleINDUSTRYChange}
                          onInputChange={(e) => {
                            if (e.length >= 1) {
                              getIndustryFocus(e);
                            } else {
                              setIndustryList([]);
                            }
                          }}
                          options={industryList}
                          value={industryValue}
                          formatCreateLabel={(userInput) =>
                            `Add "${userInput}" Your newly added capablities will be sent from admin approval`
                          }
                          placeholder="Input first few characters"
                        />
                        {/* <Select
                          className={`creatableSelect ${classes.creatableSelect1}`}
                          isMulti
                          isClearable={false}
                          onChange={async (newValue, actionMeta) => {
                            setFilterData("industries", newValue);
                            setIndustryValue(newValue);
                          }}
                          onInputChange={(e) => {
                            if (e.length >= 1) {
                              getIndustryFocus(e);
                            } else {
                              setIndustryList([]);
                            }
                          }}
                          options={industryList}
                          value={industryValue}
                          placeholder="Input first few characters "
                        /> */}
                      </div>

                      {/* <div className={`${classes.multiSelect}`}>
                        <Autocomplete
                          style={{ marginTop: "16px" }}
                          className="addLeadFormLeft pl"
                          limitTags={1}
                          multiple
                          disableClearable
                          id="industries"
                          options={industry}
                          getOptionLabel={(option) => (option.label ? option.label : "")}
                          value={industryValue}
                          renderInput={(params) => (
                            console.log("this is params", params),
                            (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Industry Focus"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )
                          )}
                          onChange={(event, newValue) => {
                            // this.indusrtyFileter(newValue);
                            setFilterData("industries", newValue);
                          }}
                        />
                      </div> */}
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 drop-block-multi mobile-view-ot">
                      {/* w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 drop-block-multi mobile-view-ot */}
                      <div className={`${classes.multiSelect}`}>
                        <label className={classes.fcc_label}>Capabilities</label>
                        <CreatableSelect
                          className={`creatableSelect ${classes.creatableSelect1}`}
                          isMulti
                          isClearable={false}
                          onChange={handleFCChange}
                          onInputChange={handleFilter}
                          options={fCapabilities}
                          value={skillValue}
                          formatCreateLabel={(userInput) =>
                            `Add "${userInput}" Your newly added capablities will be sent from admin approval`
                          }
                          placeholder="Input first few characters"
                        />
                        {/* <Autocomplete
                          style={{ marginTop: "16px" }}
                          className="addLeadFormRight pl"
                          disableClearable
                          label="Capabilities"
                          multiple
                          // disableClearable
                          limitTags={1}
                          id="multiple-limit-tags"
                          options={fCapabilities}
                          getOptionLabel={(option) => (option.label ? option.label : "")}
                          value={skillValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Capabilities"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "130px",
                            },
                          }}
                          onChange={(event, newValue) => {
                            setFilterData("skills", newValue);
                          }}
                        /> */}
                      </div>
                    </div>
                    {successMsg ? (
                      <div className="w-full px-3">
                        <SussessAlert message="Thank You For Contacting Us !" close={closeSuccessAlert} />
                      </div>
                    ) : null}
                    {errorMsg ? (
                      <div className="w-full px-3">
                        <ErrorAlert message={errorMagDesc} close={closeErrorAlert} />
                      </div>
                    ) : null}
                    <div className="w-full pt-10 text-center btn-mobile-view">
                      <button type="submit" className="round-btn">
                        {loaderStatus ? <CircularProgress /> : "Let’s Connect"}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default Engagement;
