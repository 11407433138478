import axios from '../../services/api';

import {
  GET_INDUSTRIES,
  GET_INDUSTRIES_ERROR,
  GET_INDUSTRIES_LOADING,
} from './types';


export const getIndustries = () => (dispatch) => {
  dispatch(setIndustryLoading());

  axios
    .get('data/generic/industry_categorization/list?limit=100', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'test',
        namespace: 'WEB',
      },
    })
    .then((res) => {
      console.log('got here ....');
      dispatch({
        type: GET_INDUSTRIES,
        payload: res.data.data.list,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INDUSTRIES_ERROR,
        payload: 'Error Fetching Data',
      });
    });
};


// set industry data loading
export const setIndustryLoading = () => {
  return {
    type: GET_INDUSTRIES_LOADING,
  };
};
