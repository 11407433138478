import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import axios1 from "axios";
import classes from "./Blog.module.css";
import API_PATH from "../../api_config.json";
import Avatar from "@material-ui/core/Avatar";
import Footer from "../Home/SmallHome/components/Footer";
import Navbar from "../Home/SmallHome/components/Navbar";

function BlogDetails() {
  let location = useLocation();
  let history = useHistory();
  let param = useParams();
  let paramId = param.id;
  const [blogs, setBlogs] = useState([]);
  const [suggested_blog, setSuggested_blog] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBlogs(paramId, "");
  }, []);

  const getSuggestedBlogs = async (category) => {
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/blogs/fetch-suggested-blogs?category=${category}`,
    })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.rows.length > 0) {
          setSuggested_blog(res.data.data.rows);
        } else {
          setSuggested_blog([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBlogs = async (val, createdby_userid) => {
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/blogs/fetch-blog-data?blog_id=${val}&user_id=${
        location && location.state && location.state.createdby_userid
          ? location.state.createdby_userid
          : createdby_userid
      }&user_type_id=${localStorage.getItem("user_type_id")}`,
    })
      .then((res) => {
        if (res && res?.data && res?.data?.data) {
          setBlogs(res?.data?.data);
          getSuggestedBlogs(res?.data?.data.category);
          history.push(location.state);
        } else {
          setBlogs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const dateFunc = (date) => {
    // let updatedate = "";
    if (date) {
      let temp_date = new Date(date);
      let year = temp_date.getFullYear();
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let month = months[temp_date.getMonth()];
      let day = temp_date.getDate();
      return `${day} ${month} ${year}`;
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Blogs | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div>
        <div>
          <div className={`${classes.detailsHeader} ${classes.detailsHeaderDiv}`}>
            <Avatar
              alt="Profile"
              src={
                blogs && blogs.published_by && blogs.published_by[0] && blogs.published_by[0].profile_signed_url
                  ? blogs.published_by[0].profile_signed_url
                  : "/static/images/avatar/1.jpg"
                // blogs?.published_by[0]?.profile_signed_url
                //   ? blogs?.published_by[0]?.profile_signed_url
                //   : "/static/images/avatar/1.jpg"
              }
              style={{ width: "56px", height: "56px" }}
            />
            <div>
              <span className={classes.bold}>
                {blogs && blogs.published_by && blogs.published_by[0] ? blogs.published_by[0].name : ""}
              </span>
              <p>
                {blogs && blogs.published_by && blogs.published_by[0].current_role
                  ? blogs?.published_by[0]?.current_role
                  : "Role"}{" "}
                and
                {blogs && blogs.published_by && blogs.published_by[0].company_name
                  ? blogs?.published_by[0]?.company_name
                  : " Organization"}{" "}
                <span className={classes.right}>
                  {blogs && blogs.published_by && blogs.published_by[0]
                    ? dateFunc(blogs.published_by[0].createdAt)
                    : ""}
                </span>
              </p>
            </div>
          </div>
          <h1 className={classes.cTitle}>{blogs?.title}</h1>
          <div className={`${classes.cTitleD} ull`} dangerouslySetInnerHTML={{ __html: blogs?.description }}></div>
        </div>
        <div>
          <h1 className={classes.blogg}>Suggested Blogs </h1>
          <div className={classes.list}>
            {suggested_blog &&
              suggested_blog.length > 0 &&
              suggested_blog.map((val, key) => {
                let updatedate = "";
                if (val.updatedAt) {
                  // let temp_date = new Date(val.updatedAt);
                  // let year = temp_date.getFullYear();
                  // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                  // let month = months[temp_date.getMonth()];
                  // let day = temp_date.getDate();
                  updatedate = dateFunc(val.updatedAt);
                }
                if (key < 3) {
                  return (
                    <div
                      className={classes.box}
                      onClick={() => {
                        history.push({
                          pathname: `/blogs/details${val.Id}`,
                          state: val,
                        });
                        getAllBlogs(val.Id, val.createdby_userid);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img src={val.signed_url} />
                      <h4>{val.title}</h4>
                      <div className={classes.blogDetails}>
                        <div>Publisher</div>
                        <span>{val.published_by}</span>
                        <div>Date</div>
                        <span>{updatedate}</span>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BlogDetails;
