import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import axios1 from "axios";
import classes from "./Carrers.module.css";
import API_PATH from "../../api_config.json";
import ApplicationList from "./ApplicationList";
import ApplicationSearch from "./ApplicationSearch";
import CareersImage from "../../assets/img/Careers.jpg";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";

function ApplicationView() {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [viewApplication, setViewApplication] = useState(true);
  let defaultValue = { search_term: "", from: 0, search_by: 1, sort_by: "createdAt", sort_order: "DESC", offset: 10 };
  const [search, setSearch] = useState(defaultValue);
  const changeStatusResume = async (id, status) => {
    await axios1({
      method : "post",
      url : `${API_PATH.baseUrl}/api/v1.0/jobs/change-application-status`,
      data : {
        id,
        status
      }
    }).then((res) => {
      if(res.data.status == 200){
        let defaultValue = { search_term: "", from: 0, offset: 10, search_by: 1, sort_by: "createdAt", sort_order: "DESC" };
        getAllList(defaultValue);
        return res;
      }
    })
  }
  const getAllList = async (search_input) => {
    let serchInput = `/api/v1.0/jobs/fetchAll-job-applications?offset=${search_input.offset}&search_term=${search_input?.search_term}&from=${search_input?.from}&search_by=${search_input?.search_by}&sort_by=${search_input?.sort_by}&sort_order=${search_input?.sort_order}`;
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}${serchInput}`,
    })
      .then((res) => {
        if (res && res?.data && res?.data?.data && res?.data?.data?.rows?.length > 0) {
          setData(res.data.data.rows);
          let pagination = res.data.data.count / 10;
          if (pagination >= 0) {
            setCount(pagination);
          } else {
            setCount(0);
          }
        } else {
          setData([]);
          setCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let defaultValue = { search_term: "", from: 0, offset: 10, search_by: 1, sort_by: "createdAt", sort_order: "DESC" };
    getAllList(defaultValue);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Careers | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div>
        <img src={CareersImage} className={classes.widthFull} />
        <h1 className={classes.cHeader}>Careers at StrategyConnect</h1>
        <div className={classes.lrP}>
          <div>
            <h3 className={classes.cTitle}>
              <ul>
                <li
                  className={!viewApplication ? classes.active : ""}
                  onClick={() => {
                    setViewApplication(false);
                    history.push("/careers");
                  }}
                >
                  Open Positions
                </li>
                <li
                  className={viewApplication ? `${classes.active} ${classes.textLeft}` : classes.textLeft}
                  onClick={() => {
                    setViewApplication(true);
                    history.push("/careers/view-application");
                  }}
                >
                  View Applications
                </li>
              </ul>
            </h3>
          </div>

          <ApplicationSearch
            getAllList={(search_input) => {
              getAllList(search_input);
              setSearch(search_input);
            }}
          />
        </div>
      </div>
      <ApplicationList
        data={data}
        count={count}
        getAllList={(search_input) => {
          getAllList({ ...search, from: search_input });
        }}
        changeStatusResume = {changeStatusResume}
      />
      <Footer />
    </div>
  );
}

export default ApplicationView;
