import React, { useState, useEffect } from "react";
import search from "../../../assets/img/search.svg";
import arrDown from "../../../assets/img/arrDown.svg";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import SIdeBar from "./SIdeBar";
import FIlteredCards from "./FIlteredCards";
import axios from "../../../services/api";
import axios1 from "axios";
import API_PATH from "../../../api_config.json";
import reset from "../../../assets/img/reset.svg";
import { TextField, IconButton } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

function MainCase({ caseStudy }) {
  const [caseStudie, setCaseStudies] = useState();
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [indusName, setIndusName] = useState("");
  const [projectName, setProjName] = useState("");
  const [typeNum, setTypeNum] = useState(null);
  const [showMore, setShowMore] = useState(0);
  const [searchBy, setSearchBy] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [images, setImages] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    console.log("this is setCaseStudies", caseStudy);
    setCaseStudies(caseStudy.caseStudies.data);
    if (caseStudy && caseStudy.caseStudies.data && caseStudy.caseStudies.data.length > 0) {
      let pagination = caseStudy.caseStudies.count / 10;
      setCount(pagination);
    } else {
      setCount(0);
    }
    // setImages(caseStudy.caseStudies.signed_url);
  }, [caseStudy]);

  const getCaseStudies = async (e, search_input) => {
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/knowledge-management/fetchAll-published-case-studies?from=${
        e === 0 ? 0 : 10 * e + 1
      }&offset=10&search_by=${parseInt(
        searchBy
      )}&search_term=${search_input}&sort_by=id&sort_order=DESC&user_type=Admin`,
      // data: {
      //   offset: 10,
      //   from: e === 0 ? 0 : 10 * e + 1,
      //   org_id: 0,
      //   search_by: parseInt(searchBy), //title:2   ,  industries:3,skill:4,
      //   search_term: search_input || "",
      //   sort_by: "id", // date
      //   sort_order: "DESC",
      // },
    })
      .then((res) => {
        const industry = res.data.data;
        localStorage.setItem("industry", JSON.stringify(industry));
        setCaseStudies(res.data.data);
        if (res && res.data.data && res.data.data.length > 0) {
          let pagination = res.data.count / 10;
          setCount(pagination);
        } else {
          setCount(0);
        }
        setImages(res.data.signed_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = async (e) => {
    await setSearchInput(e.target.value);
    getCaseStudies(0, e.target.value);
  };
  const reset_val = () => {
    setSearchInput("");
    setSearchBy(1);
    getCaseStudies(0, "");
  };
  return (
    <div className="flex justify-between lg:mt-20  mt-10 xl:px-32 px-4">
      <div
        className=" lg:w-8/12 w-11/12 mx-auto relative -top-8 flex justify-between items-center flex-wrap"
        style={{ width: "100%" }}
      >
        <div className="flex searchTop_new">
          <div className="sort-wrapper">
            <div className="sort">
              <select
                className="selectColor"
                value={searchBy}
                placeholder="Search by"
                onChange={(e) => {
                  setSearchBy(e.target.value);
                  setSearchInput("");
                  getCaseStudies(0, "");
                }}
              >
                <option value="1">Search by</option>
                <option value="2">Title</option>
                <option value="3">Industry</option>
              </select>
            </div>
            <div
              className="border mx-auto rounded border-gray-300 flex justify-between items-center p-3.5"
              style={{ width: "64%" }}
            >
              <input
                type="text"
                value={searchInput}
                className="border-none focus:outline-none text-base font-thin"
                placeholder="Enter Keywords"
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              <img src={search} loading="lazy" alt="search" className="" />
            </div>
            <div className="reset-parent" style={{ color: "#607ebb" }} onClick={reset_val}>
              <img src={reset} className="reset" />
              Reset
            </div>
          </div>
        </div>
        <FIlteredCards caseStudy={caseStudie} caseStudy_images={images} />
        {count > 0 && (
          <ReactPaginate
            previousLabel={"<"}
            previousClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
            disabledClassName={"border py-1 px-2 text-gray-300 text-sm rounded border-gray-300"}
            nextClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
            nextLabel={">"}
            breakLabel={"..."}
            // breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              getCaseStudies(e.selected, searchInput);
            }}
            containerClassName={
              " justify-between pagginaton_react pt-10 items-center w-full pr-12 mobile-pagination lg:flex my-8"
            }
            subContainerClassName={"pages pagination"}
            activeClassName={"text-white text-sm font-bold px-2 py-1 purple rounded focus:outline-none"}
          />
        )}

        {/* <div className=" py-8 w-full light-divide-line mt-8 block lg:hidden  rounded items-center">
          <p
            className="purple w-32 rounded text-white text-sm text-center mx-auto py-2 px-4"
            onClick={(e) => {
              setShowMore(showMore + 1);
              getCaseStudies(showMore + 1);
            }}
          >
            Show More
          </p>
        </div> */}
      </div>
      {/* mainComponent */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  caseStudy: state.caseStudy,
});

export default connect(mapStateToProps, {})(MainCase);
