import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import CommercialDueDiligence from "../../assets/img/Tech/CommercialDueDiligence.jpg";
import TechnicalDueDiligence from "../../assets/img/Tech/TechnologyDueDiligence.jpg";

import roundX from "../../assets/img/roundX.svg";
import xBlack from "../../assets/img/xBlack.svg";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../pages/auth/Onboarding/OnboardingForm/Onboarding.css";
import API_PATH from "../../api_config.json";
import { country } from "../../pages/auth/Onboarding/OnboardingForm/Country";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useLocation } from "react-router-dom";
import TechnicalDue from "./TechnicalDue";
import CommercialDue from "./CommercialDue";
const axios = require("axios");
const productText =
  "Engineering works with product team to ensure Alignment between product & technology roadmaps, Support, Incident Response, SEO / ASO, Analytics and SLAs.";
// "How closely engineering work with product team /n Alignment between product & technology roadmaps /n Support /n Incident Response /n SEO / ASO /n Analytics /n SLAs";
const teamText =
  "Engineering culture (values, spirit, motivation…), Potential skills missing on the team along with Organizational maturity such as OKRs, KPIs, Meetings, Communication. Assessment of leadership skills, hiring strategy and attractability of the company assuring Psychological Safety and Organizational Maturity";
// "Engineering culture (values, spirit, motivation…) /n Potential skills missing on the team /n Organizational maturity: OKRs, KPIs, Meetings, Communication /n Assessment of leadership skills /n Assessment of hiring strategy and attractability of the company /n Psychological Safety /n  Organizational Maturity";
const technologyText =
  "Points that are assessed are Application & Integration Architecture, Infrastructure Architecture, DevOps Architecture, Data Architecture (Modeling, Processing and Storage), Technical Debt, Scalability & Performance, Engineering processes, Testing, Data Privacy, Automation, Security, Governance, Public Website and Disaster Recovery";
// "Application & Integration Architecture /n Infrastructure Architecture /n DevOps Architecture /n Data Architecture (Modeling, Processing and Storage) /n Technical Debt /n Scalability & Performance /n Engineering processes /n Testing /n Data Privacy /n Automation /n Security /n Data Privacy /n Governance /n Public Website /n Disaster Recovery";
const c1TeamText =
  "Current Market Potential, Addressable Market Assessment, Key Markets Segments and Important Players, Identification of High Growth-High Margin Areas, Key Push-Pull Factor, Key Trends and Disruptions, Sustainability of Current Margins and Future Growth Outlook.";
const c2TeamText =
  "Assessment of Current Products and Services,Assessment of Organizational capabilities, Team structure,Financial sustainability,Operational excellence Brand awareness and goodwill, Key customers and SWOT analysis";
const c3TeamText =
  "Identification of Key Competitors, Benchmarking against Competitors, Disadvantages and Advantages over Competitors, Barriers to Entry of New Players.";
const c4TeamText =
  "Assessing the future business potential to see if the company has a clear path to income growth and if the business projections are realistic or whether the acquisition would add synergies and improve efficiencies and be able to fulfill the buyers own strategic objectives.";
const c5TeamText =
  "Assessing the Goals for Investment, Analyzing the Expected Returns and Synergies, Assessment of the Quantum and Timeline of the Investment Envisaged";
function BussinessStrategy() {
  const location = useLocation();
  let b = location.pathname.split("/strategy-services/");

  const classes = useStyles();
  const [coun, setCoun] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [phoneNoErrorMsg, setPhoneErrorMsg] = useState("");

  const [countryError, setCountryError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [cities, setCities] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [emailSend, setEmailSend] = useState(null);
  const [isShowConatact, setIsShowConatact] = useState(false);
  const [password, setPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [currCity, setCurrCity] = useState("");
  const [industry, setIndustry] = useState([]);
  const [skillValue, setSkillValue] = useState([]);
  const [comapanyName, setCompanyName] = useState(null);
  const [isShowModalText, setIsShowModalText] = useState(false);
  const [clientId, setClientId] = useState("");

  const [countryCode, setCountryCode] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(b[1]);

  const getHashFromId = (id) => {
    return (1000000 + parseInt(id) * 10).toString(32).toUpperCase();
  };
  /*Get Cities by using country code */
  const setCountryFilter = async (isoCode) => {
    let x = cities;
    try {
      axios({
        method: "post",
        url: API_PATH.baseUrl + API_PATH.filterCitiesConsultant,
        data: { code: isoCode },
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let cc = response.data.data;
            cc = cc.list;
            if (cc.length > 0) {
              let useCityName = [];
              cc.map((city) => {
                useCityName.push({ name: city.city_ascii + ", " + city.country, id: city.id });
              });
              let newArray = x.concat(useCityName);
              // x.push(useCityName);
            } else {
              setCities([]);
            }
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };

  const showConatact = (sss) => {
    window.scrollTo(0, 0);
    setIsShowConatact(true);
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    window.scrollTo(0, 0);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sign_in = async (e) => {
    e.preventDefault();
    let request_data = {
      data: {
        email: emailId,
        // email : '',
        password: password,
        user_type_id: "1",
      },
    };
    await axios({
      method: "POST",
      url: API_PATH.baseUrl + API_PATH.platform_login,
      data: request_data,
    })
      .then(async (result) => {
        if (result.data.meta.ok == 1) {
          // Cookies.set('token',JSON.stringify(a))
          var domain, domainParts, host;
          let client_id = getHashFromId(clientId);
          host = window.location.host;
          if (host.split(".").length === 1) {
            // no "." in a domain - it's localhost or something similar
            document.cookie = "token" + "=" + JSON.stringify(result.data.data) + "; path=/";
            // document.cookie = "redirect_urll" + "=" + "/clients/view/"+ client_id+"; path=/";
          } else {
            domainParts = host.split(".");
            domainParts.shift();
            domain = "." + domainParts.join(".");
            document.cookie = "token" + "=" + JSON.stringify(result.data.data) + "; path=/; domain=" + domain;
            // document.cookie = "redirect_urll" + "=" + "/clients/view/"+ client_id + "; path=/; domain=" + domain;
          }
          // window.open(API_PATH.platform_url + `clients/view/${getHashFromId(clientId)}`, "_blank");
          window.open(API_PATH.platform_url, "_blank");
          setVisible(false);
        } else {
          setErrorMsg("Password is incorrect");
        }
      })
      .catch((err) => {});
  };
  // console.log(!isValidPhoneNumber(mobile, countryId[0]), countryId[0], mobile, String(countryCode.split("+")[1]))

  return (
    <div>
      <Navbar />
      <div style={{ position: "relative" }}>
        {selectedPlan === "technical-due-diligence" && (
          <div>
            <img src={TechnicalDueDiligence} alt="technical-due-diligence" style={{ width: "100%" }} />
            {!isShowConatact && <TechnicalDue showConatact={showConatact} />}
          </div>
        )}
        {selectedPlan === "commercial-due-diligence" && (
          <div>
            <img src={CommercialDueDiligence} alt="commercial-due-diligence" style={{ width: "100%" }} />
            {!isShowConatact && <CommercialDue showConatact={showConatact} />}
          </div>
        )}
      </div>

      {isShowConatact && (
        <div id="techDue">
          <div className="lg:px-32 sm:px-24 px-2 w-full sm:pt-14 pt-8  ">
            <p className="sm:text-3xl text-xl   sm:px-0 font-bold heading-color">Contact details</p>
          </div>
          {/*  */}
          <Formik
            initialValues={{ name: "", email: "", company_name: "", mobile: "" }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              if (!countryCode && values.mobile) {
                setCountryError(true);
                return false;
              } else if (countryCode && values.mobile) {
                if (!isValidPhoneNumber(values.mobile, coun.countryCode)) {
                  setPhoneErrorMsg("Invalid mobile number format");
                  return false;
                }
              }
              if (selectedPlan === "CommercialDueDiligence") {
              }
              let sendData = {
                company_name: values.company_name,
                pkg_price: selectedPlan === "technical-due-diligence" ? 15000 : 10000,
                email: values.email,
                mobile: values.mobile,
                pkg_name: selectedPlan,
                client_name: values.name,
                admin_name: "Shubham Yadav",
                // admin_email: "pankaj.kumar@konverge.ai",
                admin_email: "shubham@strategyconnect.co",

                project_title:
                  selectedPlan === "technical-due-diligence"
                    ? `Technology Due Diligence-${values.name}`
                    : `Commercial Due Diligence-${values.name}`,

                project_description:
                  selectedPlan === "technical-due-diligence"
                    ? `My work focuses on three core pillars: Team, Product and Technology. By taking this approach I’m able to identify potential discrepancies in alignment and priorities which themselves translate into potential risk. `
                    : `Our holistic due diligence process has five core pillars which enable in the identification of potential discrepancies in alignment and priorities that could cause a potential risk. These are outlined below. `,
                country_code: countryCode,
                deliverables:
                  selectedPlan === "technical-due-diligence"
                    ? [
                        {
                          title: "TEAM ASSESSMENT",
                          description: teamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "PRODUCT ASSESSMENT",
                          description: productText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "TECHNOLOGY ASSESSMENT",
                          description: technologyText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                      ]
                    : [
                        {
                          title: "Market Opportunity Assessment",
                          description: c1TeamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "Business Assessment",
                          description: c2TeamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "Competitive Landscape Assessment",
                          description: c3TeamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "Business Plan Assessment",
                          description: c4TeamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                        {
                          title: "Investment Thesis",
                          description: c5TeamText,
                          outcomes: [
                            {
                              task: "A detailed assessment report with suggestions in the form of a PowerPoint presentation",
                            },
                          ],
                          duration: 1,
                          duration_type: "Weeks",
                        },
                      ],
              };
              setEmailId(values.email);
              await axios({
                method: "post",
                url: API_PATH.baseUrl + API_PATH.checkEmail,
                data: {
                  email: values.email,
                },
                headers: {
                  namespace: "STRATEGY",
                  "x-fwd-authorization": "test",
                },
              })
                .then(async function (response) {
                  if (response.data.data !== undefined) {
                    setClientId(response.data.data[0].id);
                  }
                  if (response.data.status === 2) {
                    return setIsShowModalText(true);
                  }
                  // setEmailSend(values.email);
                  // resetForm();
                  // if (response.data.meta.message == "Success!") {
                  //   setVisible(true);
                  //   setIsErrorMsg(false);
                  // } else {
                  //   setIsErrorMsg(true);
                  //   setErrorMsg(response.data.meta.error[0].message);
                  //   setVisible(true);
                  // }
                })
                .catch(async function (error) {});
              // console.log("sssssssssssss");
              // return false;
              await axios({
                method: "post",
                url: API_PATH.baseUrl + API_PATH.saveLeadFromWebsite,
                data: sendData,
                headers: {
                  namespace: "STRATEGY",
                  "x-fwd-authorization": "test",
                },
              })
                .then(async function (response) {
                  setEmailSend(values.email);
                  resetForm();
                  if (response.data.meta.message == "Success!") {
                    setVisible(true);
                    setIsErrorMsg(false);
                  } else {
                    setIsErrorMsg(true);
                    setErrorMsg(response.data.meta.error[0].message);
                    setVisible(true);
                  }
                })
                .catch(async function (error) {});
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required."),
              company_name: Yup.string().required("Company Name is required."),
              mobile: Yup.string()
                .required("Contact Number is required.")
                .test("number", "Enter numeric input", (value) => {
                  if (!countryCode && value) {
                    setCountryError(true);
                  }
                  return /^-?[\d]+(?:e-?\d+)?$/.test(value);
                }),
              email: Yup.string().email().required("Email Id is required."),
            })}
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
              return (
                <form
                  id="onBoardingForm techDue"
                  className={classes.onBoardingForm}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className=" lg:px-32 sm:px-24 px-2 w-full sm:pt-14 pt-8  input-grid">
                    <div>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          autoComplete: "none",
                        }}
                        autoComplete="off"
                        className={`${classes.paddingInput}`}
                        label="Name"
                        id="name"
                        placeholder="Name"
                        name="name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </div>
                    <div>
                      <TextField
                        className={`${classes.paddingInput}`}
                        label="Email Id"
                        id="email_1"
                        placeholder="Email Id"
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          autoComplete: "none",
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        className={`${classes.paddingInput}`}
                        label="Company Name"
                        id="company_name"
                        placeholder="Company Name"
                        name="company_name"
                        variant="outlined"
                        value={values.company_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.company_name && touched.company_name}
                        helperText={errors.company_name && touched.company_name && errors.company_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          autoComplete: "none",
                        }}
                      />
                    </div>
                    <div id="res-mtttp" className="mtttb country-grid">
                      <div>
                        <div className={classes.phoneNumberBlock}>
                          <label
                            className={classes.ccLabel}
                            style={{ padding: "0px", color: " rgb(128 128 128 / 87%)", left: "4px", fontSize: "12px" }}
                          >
                            Country Code
                          </label>
                          <p className="content-wrapper" style={{ border: "1px solid rgb(197 197 197)" }}>
                            {coun.countryFlag !== undefined && (
                              <img
                                src={coun.countryFlag}
                                alt={coun.countryFlag}
                                className="flag"
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                              />
                            )}
                            {coun.countryDialCode !== undefined && (
                              <span className="country_dial_code" style={{ paddingTop: "0px", margin: "6px" }}>
                                {coun.countryDialCode}
                              </span>
                            )}
                          </p>
                          <select
                            className="country-code-select"
                            onChange={(e) => {
                              setCountryError(false);
                              country
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((country) => {
                                  if (country.name === e.target.value) {
                                    setCoun({
                                      countryDialCode: country.dial_code,
                                      countryName: country.name,
                                      countryCode: country.code,
                                      countryFlag: `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`,
                                    });
                                    setCountryFilter(country.code);
                                    setCountryCode(country.dial_code);
                                  }
                                });
                            }}
                            value={coun.countryName}
                          >
                            <option value="">Select Country</option>
                            {country
                              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                              .map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item.name}
                                    style={{ fontFamily: "Nunito Sans, sans-serif", color: "black" }}
                                  >
                                    {item.name + "     " + "(" + item.dial_code + ")"}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {countryError ? (
                          <span className={classes.validationErrorText}>Country Code is required</span>
                        ) : null}
                      </div>
                      <div>
                        <TextField
                          className={`${classes.paddingInput}`}
                          label="Contact Number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="mobile"
                          placeholder="Contact Number"
                          name="mobile"
                          variant="outlined"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.mobile && touched.mobile}
                          helperText={errors.mobile && touched.mobile && errors.mobile}
                          inputProps={{
                            autoComplete: "none",
                          }}
                          onClick={() => {
                            setPhoneErrorMsg(null);
                          }}
                        />
                        {phoneNoErrorMsg ? (
                          <span style={{ position: "relative", top: "-26px" }} className={classes.validationErrorText}>
                            {phoneNoErrorMsg}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="order-now">
                    <button type="submit" className="btn-submit">
                      Submit
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={visible}>
          <div className="toaster-wrapper">
            <img
              src={dimensions.width < 640 ? xBlack : roundX}
              alt="roundX"
              loading="lazy"
              className="cursor-pointer"
              style={{ float: "right", padding: "20px" }}
              onClick={() => {
                setVisible(false);
                setEmailSend(null);
                // document.body.style.overflow = "";
              }}
            />
            {!isErrorMsg && (
              <div className="rounded bg-white  p-8" id="model_box" style={{ borderRadius: "20px" }}>
                <div className={classes.center}>
                  {isShowModalText ? (
                    <div className={classes.padding}>
                      <p>
                        <CheckCircleOutlineIcon className="mailIcon" />
                      </p>
                      <p className="mailBold">
                        Your project has been successfully created.
                        {/* <span style={{ display: "block", fontWeight: "bold" }}>{emailSend}</span> */}
                      </p>
                      <p>
                        {" "}
                        An email containing your login credentials has been shared. Please login with the password that
                        has been sent.
                      </p>
                      <p>Please login with your existing StrategyConnect credentials to meet your team.</p>
                      {/* <div>
                        <TextField
                          className={`${classes.paddingInput}`}
                          label="Password"
                          placeholder="Enter Password"
                          name="Password"
                          type="password"
                          variant="outlined"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            autoComplete: "none",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                      </div>
                      <p style={{ marginTop: "-28px", color: "red", fontSize: "initial", float: "left" }}>{errorMsg}</p>
                      <div className="order-now">
                        <button onClick={sign_in} type="submit" className="btn-submit">
                          Sign In
                        </button>
                      </div> */}
                    </div>
                  ) : (
                    <div className={classes.padding}>
                      <p>
                        <CheckCircleOutlineIcon className="mailIcon" />
                      </p>
                      <p className="mailBold">Your project has been successfully created.</p>
                      <p>Please login with your existing StrategyConnect credentials to meet your team.</p>
                      {/* <div style={{ marginTop: "2rem" }}>
                        <TextField
                          className={`${classes.paddingInput}`}
                          label="Password"
                          placeholder="Enter Password"
                          name="Password"
                          type="password"
                          variant="outlined"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrorMsg("");
                          }}
                          InputLabelProps={{
                            shrink: true,
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          inputProps={{
                            autoComplete: "none",
                          }}
                        />
                      </div>
                      <p style={{ marginTop: "-28px", color: "red", fontSize: "initial", float: "left" }}>{errorMsg}</p>
                      <div className="order-now">
                        <button onClick={sign_in} type="submit" className="btn-submit">
                          Sign In
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isErrorMsg && (
              <div className="rounded bg-white  p-8" id="model_box">
                <div className={classes.center}>
                  <p className={classes.bold}>Project not created!</p>
                  <div className={classes.padding}>
                    <p>
                      {errorMsg}
                      {/* <span>{emailId}</span> */}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>

      <Footer />
    </div>
  );
}

export default BussinessStrategy;
