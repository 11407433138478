import React from "react";
import classes from "./Carrers.module.css";
import loupe from "../../assets/img/loupe.png";

function CareersSearch(props) {
  return (
    <div className={classes.search}>
      <div>
        <div className={`${classes.center} ${classes.centerML}`}>
          <input
            type="search"
            placeholder="Search"
            style={{ width: "60%" }}
            onChange={(e) => {
              props.getAllList(e.target.value);
            }}
          />
          <img src={loupe} alt="search" />
        </div>
      </div>
    </div>
  );
}

export default CareersSearch;
