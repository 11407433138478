import React, { useEffect, useState } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import MarketHero from "./MarketHero";
import Chat from "../Home/SmallHome/components/Hero/Chat";
import World from "../Home/SmallHome/components/Hero/World";
import Rocket from "../Home/SmallHome/components/Hero/Rocket";
import Subplans from "./Subplans";
import Customize from "./Customize";
import EnquireModal from "./EnquireModal";

export default function MarketPlace() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    document.title = "StrategyConnect | MarketPlace";
    window.scrollTo(0, 0);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const imageList = [
    {
      Img: Chat,
      number: "5000+",
      text: "Consultants & Experts",
    },
    {
      Img: World,
      number: "100+",
      text: "Clients Covered",
    },
    {
      Img: Rocket,
      number: "200+",
      text: "Projects Delivered",
    },
  ];

  const subscriptionList = [
    {
      heading: "Market Analysis",
      points: [
        "Assessment of market scenario including customer segmentation, target customer personas, and market size estimation.",
        "Identifying unique value proposition and market positioning.",

        "Identifying competitor segmentation by product offering, price, customer focus,   geography and traction.",
      ],
    },
  ];
  return (
    <div>
      {modalState ? (
        <EnquireModal
          visible={modalState}
          setVisible={(val) => {
            setModalState(val);
          }}
        />
      ) : null}
      <Navbar />
      <MarketHero />
      <div className="lg:mx-56 sm:mx-32 mx-6 pt-10 topborder flex justify-between">
        {imageList.map((item) => {
          return (
            <div className="flex flex-col items-center content-center">
              <item.Img />
              {item.img}
              <p className="text-lg md:text-2xl font-bold md:mt-7 md:mb-3 leading-10">{item.number}</p>
              <p className="text-base  font-normal  text-center md:mb-10 lg:leading-7 grey-text">{item.text}</p>
            </div>
          );
        })}
      </div>
      <div
        style={{
          padding: `${
            dimensions.width <= 1200 && dimensions.width >= 1024
              ? "0 15%"
              : dimensions.width < 1024
              ? "0 10%"
              : "0 224px"
          } `,
        }}
      >
        <Subplans
          openModal={(val) => {
            setModalState(val);
          }}
          list={subscriptionList}
        />
      </div>

      {/* <Customize
        openModal={(val) => {
          console.log("gggggggggggggggggg", val);
          setModalState(val);
        }}
      /> */}

      <Footer />
    </div>
  );
}
