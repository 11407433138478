/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import rocket from "../../../../../../assets/img/rocLaunch.png";
import { Form, Formik } from "formik";
import * as yup from "yup";
import lineP from "../../../../../../assets/img/lineP.svg";
import roundX from "../../../../../../assets/img/roundX.svg";
import xBlack from "../../../../../../assets/img/xBlack.svg";
import Modal from "react-awesome-modal";
import { css } from "@emotion/core";
import PacmanLoader from "react-spinners/PacmanLoader";
import PageTwo from "./PageTwo";
import CustomTextInput from "../../../../../../pages/auth/components/CustomTextInput";
import { country } from "../../../../../../pages/auth/Onboarding/OnboardingForm/Country";
import { isValidPhoneNumber } from "libphonenumber-js";

const axios = require("axios");

const override = css`
  display: block;
  margin: 5px auto;
  position: relative;
  left: -10px;
  top: -4px;
`;

export default function KickStart(props) {
  const [clickContinue, setContinue] = useState(false);
  const [id, setId] = useState("");
  const [errorData, setErrorData] = useState("");
  const [globError, setGlobeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryDialCode, setCountryDialCode] = useState("");
  const [countryFlag, setCountryFlag] = useState("");
  const [isCountryDialCodeRequired, setIsCountryDialCodeRequired] = useState(false);
  const [countryCode, setcountryCode] = useState("");
  const [countryCodeText, setCountryCodeText] = useState("");
  const [countryErr, setCountryErr] = useState(false);
  const [validPhonenNumber, isValidPhonenNumber] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const dropList = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "vanilla", label: "lfkgjm" },
  ];

  const goBack = () => {
    setContinue(false);
    setCountryFlag("");
  };

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
  };

  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Full Name is Required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
    phoneNumber: yup.number().typeError("That doesn't look like a phone number").required("Phone Number is required"),
  });

  const handleSubmit = (formValues) => {
    if (countryCodeText == "") {
      setcountryCode("a");
      setCountryErr(true);
      return null;
    } else {
      if (formValues.phoneNumber !== "" && formValues.phoneNumber !== undefined && countryCodeText !== "") {
        let checkNumber = isValidPhoneNumber(formValues.phoneNumber, countryCodeText);
        let a = document.getElementById("phoneNumber").nextSibling;
        let b = a.nextSibling;
        if (!checkNumber) {
          isValidPhonenNumber(true);
          // if(b.style.color == 'rgb(56, 54, 154)'){
          //   b.style.color = 'red';
          //   b.innerHTML = 'Invalid Phone Number'
          // }
        } else {
          isValidPhonenNumber(false);
          b.style.color = "rgb(56, 54, 154)";
          b.innerHTML = "Phone Number *";
          setLoading(true);
          const data = {
            name: formValues.fullName,
            mobile: formValues.phoneNumber,
            country_code: countryCode,
            // country_code: formValues.countryCode,
            email: formValues.email,
          };
          axios
            .post("https://testapi.strategyconnect.co/api/data/generic/save/lead_client/", data, {
              headers: {
                Authorization: "test",
                "Content-Type": "application/json",
                namespace: "WEB",
              },
            })
            .then((res) => {
              const { ok, message } = res.data.meta;
              if (ok === 1) {
                setContinue(true);
                setLoading(false);
                setGlobeError(false);
                setId(res.data.data.id);
                setcountryCode("");
              } else {
                setLoading(false);
                setGlobeError(true);
                setErrorData(message);
              }
            })
            .catch((e) => {
              alert(e);
            });
        }
      }
    }
  };

  return (
    <Modal
      visible={props.visible}
      width={`${dimensions.width < 768 ? "100%" : "70%"}`}
      height={`${dimensions.width < 768 ? "90%" : "75%"}`}
      effect="fadeInLeft"
      onClickAway={() => props.setVisible(false)}
    >
      <div className="flex relative -top-8 rounded h-auto bg-white overflow-y-scroll hidescroll_bar">
        <div className="hidden xl:block" style={{ width: 500 }}>
          <img src={rocket} loading="lazy" alt="" className="z-10 relative -left-0.5" />
        </div>
        <div className="w-9/12 w-11/12 mx-auto p-4">
          <div className="flex justify-between items-center">
            <div />
            <img
              src={dimensions.width < 640 ? xBlack : roundX}
              alt="roundX"
              loading="lazy"
              className="cursor-pointer"
              onClick={() => {
                props.setVisible(false);
              }}
            />
          </div>
          <p className="font-bold sm:text-3xl text-lg text-center mt-10">Kickstart Engagement</p>

          <p className="text-center mt-4 text-base lg:w-2/5 w-11/12  mx-auto font-thin">
            Please fill the form and get started It takes less than a minute
          </p>

          <div>
            {clickContinue ? (
              <div>
                <div className="flex items-center mx-auto justify-between mt-8  w-32">
                  <p className="threeP text-xs flex items-center justify-center">1</p>
                  <img src={lineP} alt="" className="lineP" />
                  <div>
                    <div className="divOneP">
                      <p className="oneP flex items-center text-xs justify-center">2</p>
                    </div>
                  </div>
                </div>
                <p className="text-center text-sm font-bold text-indigo-700 relative left-12 mt-2">
                  Project Information
                </p>
              </div>
            ) : (
              <div>
                <div className="flex items-center mx-auto justify-between mt-8 w-32">
                  <div className="divOneP">
                    <p className="oneP flex items-center text-xs justify-center">1</p>
                  </div>
                  <img src={lineP} alt="" className="lineP" />
                  <p className="twoP text-xs flex items-center justify-center">2</p>
                </div>
                <p className="text-center text-sm font-bold text-indigo-700 relative right-12 mt-2">Personal Details</p>
              </div>
            )}

            {/* page one */}
            {clickContinue ? null : (
              <div className="w-full flex-col flex items-center">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                  {(_props) => {
                    return (
                      <Form id="step-one">
                        <CustomTextInput
                          name="fullName"
                          id="fullName"
                          type="search"
                          autoComplete="off"
                          placeholder="Name *"
                        />
                        <CustomTextInput
                          name="email"
                          id="email"
                          type="search"
                          autoComplete="off"
                          placeholder="Company Email Address"
                        />
                        <div style={{ display: "flex" }}>
                          <div
                            className="country-code-wrapper"
                            style={{
                              width: "131px",
                              height: "46px",
                              borderColor: countryErr ? "red" : countryCode !== "" ? "#38369a" : "#c4c4c4",
                            }}
                          >
                            <span
                              className={countryCode == "" ? "country-code-legend" : "country-code-legend-new"}
                              style={{
                                color: `${countryErr ? "red" : ""}`,
                                top: `${countryErr ? "42px" : ""}`,
                                left: `${countryErr ? "-12px" : ""}`,
                              }}
                            >
                              {countryErr ? "Code is require *" : "Country Code *"}
                              {/* {" "}
                              Country Code{"*"} */}
                            </span>
                            {countryDialCode !== "" && countryFlag !== "" && (
                              <p className="content-wrapper-2">
                                <img src={countryFlag} className="flag" />
                                <span className="country_dial_code"> {countryDialCode} </span>
                              </p>
                            )}
                            <select
                              // title={
                              //   this.state.countryName
                              //     ? this.state.countryName + "   (" + this.state.countryDialCode + ")"
                              //     : ""
                              // }
                              name="countryCode"
                              id="countryCode"
                              className="country-code-select"
                              onChange={(e) => {
                                country.map((country) => {
                                  if (country.name === e.target.value) {
                                    setCountryDialCode(country.dial_code);
                                    setCountryFlag(
                                      `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`
                                    );
                                    setIsCountryDialCodeRequired(false);
                                    setcountryCode(country.dial_code);
                                    setCountryCodeText(country.code);
                                    setCountryErr(false);
                                    // this.setState({
                                    //   countryDialCode: country.dial_code,
                                    //   countryName: country.name,
                                    //   countryCode: country.code,
                                    //   editCountryCode: country.code,
                                    //   isCountryDialCodeRequired: false,
                                    //   countryFlag: `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`,
                                    // });
                                    // this.setCountryFilter(country.code);
                                  }
                                });
                              }}
                            >
                              <option value="">Select Country</option>
                              {country.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item.name}
                                    style={{
                                      fontFamily: "Nunito Sans, sans-serif",
                                      color: "black",
                                    }}
                                  >
                                    {item.name + "     " + "(" + item.dial_code + ")"}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <CustomTextInput
                            name="phoneNumber"
                            id="phoneNumber"
                            type="search"
                            autoComplete="off"
                            placeholder={`Phone Number ${validPhonenNumber ? "invalid" : "*"}`}
                            magin="24px"
                            hello={validPhonenNumber}
                          />
                        </div>
                        {/* <CustomTextInput
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text"
                          placeholder="Phone Number *"
                        /> */}

                        <div className="flex items-center  mt-8">
                          <button
                            type="button"
                            style={{ width: "50%", marginLeft: "0px" }}
                            onClick={() => {
                              props.setVisible(false);
                            }}
                            className="ml-3.5 py-2 px-4 text-center w-28 rounded border border-gray-300 text-gray-400 text-sm cursor-pointer"
                          >
                            Cancel
                          </button>
                          <button
                            style={{ width: "50%" }}
                            onClick={_props.handleSubmit}
                            type="submit"
                            className="ml-3.5 py-2 px-4 w-28 text-center hover:bg-indigo-900 rounded purple text-white text-sm cursor-pointer"
                          >
                            {loading ? (
                              <PacmanLoader color={"#ffffff"} loading={loading} css={override} size={9} />
                            ) : (
                              "Proceed"
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {globError ? <p className="text-red-400 text-base font-bold mt-4 text-center">{errorData}</p> : null}
              </div>
            )}
            {/* page one */}

            {/* page Two */}
            {clickContinue ? <PageTwo dropList={dropList} id={id} setContinue={goBack} hidePopup={props} /> : null}
            {/* page Two */}
          </div>
        </div>
      </div>
    </Modal>
  );
}
