import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Form, Formik } from "formik";
import * as yup from "yup";
import useStyles from "./Onboarding.css";
import { country } from "./Country";
import { isValidPhoneNumber } from "libphonenumber-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "./Sidebar";
import SussessAlert from "./../../components/Alert/SussessAlert";
import ErrorAlert from "./../../components/Alert/ErrorAlert";
import API_PATH from "../../../../api_config.json";
import CreatableSelect from "react-select/creatable";

const axios = require("axios");
const OnboardingForm = () => {
  const classes = useStyles();
  const [fCapabilities, setfCapabilities] = useState([]);
  const [fcId, setFcId] = useState([]);
  const [fcIdMaual, setFcIdManual] = useState([]);
  const [coun, setCoun] = useState([]);
  const [cities, setCities] = useState([]);
  const [currCity, setCurrCity] = useState("");
  const [currCityStatus, setCurrCityStatus] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [checkCountryCode, setCheckCountryCode] = useState(false);
  const [preference, setPreference] = useState(3);
  const [remoteWorking, setRemoteWorking] = useState(1);
  const [contact, setContact] = useState("");
  const [contactStatus, setContactStatus] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [errorMagDesc, setErrorMagDesc] = useState("Somthing went wrong !");
  const [skillValue, setSkillValue] = useState([]);
  const [fcCheck, setFCCheck] = useState(false);
  const [selectCountryCode, setSelectCountryCode] = useState(false);
  const [currentCityMsg, setCurrentCityMsg] = useState("Select country code first for city *");
  const [phoneNoErrorMsg, setPhoneErrorMsg] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);

  //   useEffect(() => {
  //     getFunctionalCapabilities("");
  //   }, []);

  const optionOnChange = (e) => {
    if (e.target.name === "preference") {
      setPreference(e.target.value);
    } else {
      setRemoteWorking(e.target.value);
    }
  };

  const isNumber = (val) => {
    return /^-?[\d]+(?:e-?\d+)?$/.test(val);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    linkedUrl: "",
    email: "",
    chargesRemote: "",
    chargesSite: "",
    yearsOfExperience: "",
    currentOrganization: "",
    currentDesignation: "",
  };

  const validationSchemaY = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    //firstName: yup.string().required('First name is required'),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    email: yup.string().email("Invalid email id").required("Email is required"),
    chargesRemote: yup
      .string("")
      .required("Charges are required")
      .matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input"),
    chargesSite: yup.string("").matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input"),
    yearsOfExperience: yup
      .string("")
      .matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input")
      .required("Experience is required"),
    currentOrganization: yup.string().required("Current organization is required"),
    currentDesignation: yup.string().required("Current designation is required"),
  });

  const validationSchemaN = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    //firstName: yup.string().required('First name is required'),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Only character are allowed"),
    email: yup.string().email("Invalid email id").required("Email is required"),
    chargesRemote: yup.string("").matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input"),
    chargesSite: yup
      .string("")
      .required("Charges are required")
      .matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input"),
    yearsOfExperience: yup
      .string("")
      .matches(/^-?[\d]+(?:e-?\d+)?$/, "Enter numeric input")
      .required("Experience is required"),
    currentOrganization: yup.string().required("Current organization is required"),
    currentDesignation: yup.string().required("Current designation is required"),
  });

  /*Set contact no validation */
  const countryCodeCheck = (e) => {
    setContactError(false);
    if (coun.countryDialCode === undefined || coun.countryDialCode === "") {
      setCheckCountryCode(true);
    } else {
      setCheckCountryCode(false);
      if (e.target.value !== "") {
        if (isNumber(e.target.value)) {
          setContactStatus(false);
          setContactError(false);
          setPhoneErrorMsg("");
          setContact(e.target.value);
        } else {
          setContactStatus(true);
          setContactError(true);
          setPhoneErrorMsg("Enter numeric input");
        }
      } else {
        setContactStatus(false);
        setContactError(false);
        setPhoneErrorMsg("");
        setContact(e.target.value);
      }
    }
  };
  /*Set contact no validation */

  /* Handle all validation on from submit */
  const handleSubmit = async (formValues, { resetForm }) => {
    setSuccessMsg(false);
    setErrorMsg(false);
    let rWork = "0";
    let oSWork = "0";
    if (formValues.chargesRemote === "") {
      rWork = 0;
    } else {
      rWork = formValues.chargesRemote;
    }

    if (formValues.chargesSite === "") {
      oSWork = 0;
    } else {
      oSWork = formValues.chargesSite;
    }

    if (remoteWorking == 1 && formValues.chargesRemote !== "") {
      rWork = formValues.chargesRemote;
    } else {
      rWork = 0;
    }

    if (coun.countryDialCode === undefined || coun.countryDialCode === "") {
      setCheckCountryCode(true);
      setContactStatus(true);
    } else {
      setCheckCountryCode(false);
      if (contact === undefined || contact === "") {
        setContactStatus(true);
        setContactError(true);
        setPhoneErrorMsg("Phone number is required");
      } else {
        setContactStatus(false);
        setContactStatus(true);
        let isValidPhone = isValidPhoneNumber(contact, coun.countryCode);
        if (!isValidPhone) {
          setContactError(true);
          setPhoneErrorMsg("Phone number is invalid");
        } else {
          setContactError(false);
          setPhoneErrorMsg("");
          if (currCity === "") {
            setCurrCityStatus(true);
          } else {
            setCurrCityStatus(false);
            if (fcId.length > 0 || fcIdMaual.length > 0) {
              setFCCheck(false);
              setLoaderStatus(true);
              let values = {
                first_name: formValues.firstName,
                last_name: formValues.lastName,
                email: formValues.email,
                linkedin_profile: formValues.linkedUrl,
                country_code: coun.countryDialCode,
                phone: contact,
                current_city: currCity,
                working_remotely: remoteWorking,
                commitment_preference_id: preference,
                remote_per_day_rate: parseInt(rWork),
                onsite_per_day_rate: parseInt(oSWork),
                total_work_experience_years: parseInt(formValues.yearsOfExperience),
                current_employer: formValues.currentOrganization,
                current_role: formValues.currentDesignation,
                functional_capabilities: fcId,
                unapproved_skills: fcIdMaual,
                request_for: "consultant",
              };
              // await setLinkedInUrl(formValues.linkedUrl);
              await setOnboardingData(values, resetForm);
            } else {
              setFCCheck(true);
            }
          }
        }
      }
    }
  };
  /* Handle all validation on from submit */

  /*Insert Onboarding from Data*/

  const setOnboardingData = async (values, resetForm) => {
    let sendData = {
      email: values.email,
      mobileNo: values.phone,
      last_name: values.last_name,
      first_name: values.first_name,
      country_code: values.country_code,
      current_role: values.current_role,
      current_city: values.current_city,
      linkedin_url: values.linkedin_profile,
      working_remotely: values.working_remotely,
      current_employer: values.current_employer,
      capabilities: values.functional_capabilities,
      onsite_per_day_rate: values.onsite_per_day_rate,
      remote_per_day_rate: values.remote_per_day_rate,
      commitment_preference_id: values.commitment_preference_id,
      total_work_experience_years: values.total_work_experience_years,

      user_platform_id: 2,
      user_type_id: 2,
      createdby_userid: 1,
    };
    try {
      await axios({
        method: "post",
        data: sendData,
        url: API_PATH.baseUrl + "/api/v1.0/talent-management/save-consultant-details",
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(function (response) {
          if (response.data && response.data.status == "200") {
            let resData = response.data;
            resData = resData.meta;
            // if (resData.ok == 1) {
            setSuccessMsg(true);
            setLoaderStatus(false);
            resetForm({});
            setSkillValue([]);
            setContact("");
            setCurrCity("");
            setCities([]);
            setRemoteWorking(1);
            setPreference(3);
            setCoun({
              countryDialCode: "",
              countryName: "",
              countryCode: "",
              countryFlag: "",
            });
            if(fcIdMaual.length > 0){
              const param = {
                request_for: "consultant",
                consultant_id: response.data.consultant_id,
                unapproved_capabilities: fcIdMaual,
              };
              addNewUnapprovedCapabilities(param)
            }
          } else {
            setErrorMsg(true);
            setLoaderStatus(false);
            setErrorMagDesc("This email id already exists !");
          }
        })
        .catch(function (error) {
          setErrorMsg(true);
          setLoaderStatus(false);
          setErrorMagDesc("Somthing went wrong !");
        });
    } catch (error) {
      setErrorMsg(true);
      setLoaderStatus(false);
      setErrorMagDesc("Somthing went wrong !");
    }
  };

  const addNewUnapprovedCapabilities = async (data) => {
    try{
      await axios({
        method: "post",
        data: data,
        url: `${API_PATH.baseUrl}/api/v1.0/talent-management/save-unapproved-skills`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    }catch (error) {
      console.log(error)
    }
  }
  /*Insert Onboarding from Data*/

  /* Close Alert msg box */
  const closeSuccessAlert = () => {
    setSuccessMsg(false);
  };

  const closeErrorAlert = () => {
    setErrorMsg(false);
  };
  /* Close Alert msg box */

  /*Get Cities by using country code */
  const setCountryFilter = async (country_code) => {
    try {
      // .get()
      // .then((response) => {
      //   if (response && response.data && response.data) {
      //     let cities = response.data.data.map((i) => `${i.city_ascii}, ${i.country}`);
      //     setCities(cities);
      //   }
      // })
      axios({
        method: "get",
        url: `${API_PATH.baseUrl}/api/v1.0/list/fetch-cities?search_term=${country_code}`,

        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let cc = response.data.data;
            if (cc.length > 0) {
              let useCityName = [];
              cc.map((city) => {
                useCityName.push(city.city_ascii + ", " + city.country);
              });
              setCities(useCityName);
              setSelectCountryCode(false);
              setCurrentCityMsg("Select country code first for city *");
              setCurrCity("");
            } else {
              setCities([]);
              setSelectCountryCode(true);
              setCurrentCityMsg("No city available for this country code");
              setCurrCity("");
            }
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };
  /*Get Cities by using country code */

  /* Get Functional Capabilities by using api*/
  const getFunctionalCapabilities = (value) => {
    try {
      axios({
        method: "get",
        url: `${API_PATH.baseUrl}/api/v1.0/list/fetchAll-capabilities?search_term=${value}&limit=50`,
        headers: {
          namespace: "STRATEGY",
          "x-fwd-authorization": "test",
        },
      })
        .then(async function (response) {
          if (response) {
            let skills = response?.data?.data.map((item) => ({ value: item.id, label: item.name }));
            setfCapabilities(skills);
          }
        })
        .catch(async function (error) {});
    } catch (error) {}
  };
  /*Get  Functional Capabilities by using api*/

  const handleFilter = (inputValue) => {
    if (inputValue.length >= 1) {
      getFunctionalCapabilities(inputValue);
    } else {
      setfCapabilities([]);
    }
  };

  const handleFCChange = async (newValue, actionMeta) => {
    await setSkillValue(newValue);
    if (actionMeta.action == "select-option") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);
    } else if (actionMeta.action == "create-option") {
      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setFcIdManual(skillSetManual);
      setFCCheck(false);
    } else if (actionMeta.action == "remove-value") {
      let skillsSet = [];
      newValue.map((obj) => {
        if (isNumber(obj.value)) {
          skillsSet.push(obj.value);
        }
      });
      await setFcId(skillsSet);
      setFCCheck(false);

      let skillSetManual = [];
      newValue.map((obj) => {
        if (obj.__isNew__) {
          skillSetManual.push(obj.value);
        }
      });
      await setFcIdManual(skillSetManual);
      setFCCheck(false);
    } else {
      await setFcIdManual([]);
      await setFcId([]);
      setFCCheck(false);
      await setSkillValue([]);
    }
  };

  // /*Set multile Fuctional Capability on onchange */
  // const setFilterData = async (key, value) => {
  //     if (key === "id") {
  //         await setSkillValue(value);
  //         let skillSet = value.map((obj, key) => {
  //             return obj.id;
  //         });
  //         await setFcId(skillSet);
  //         setFCCheck(false);
  //     }
  // }
  // /*Set multile Fuctional Capability on onchange */

  return (
    <>
      <div className="flex md:flex-row flex-wrap onBoardingComponent">
        <div className="w-full md:w-2/5 text-center left-Block desktop-view-block">
          <Sidebar />
        </div>
        <div className="w-full md:w-3/5 p-4 pt-10 pb-10 right-Block">
          <h2 className="text-3xl grey-text-dark text-center h-light">Work With Top Companies From Around The World</h2>
          <h1 className="text-5xl mt-7 mb-10 h-bold grey-text-dark text-center">Register Yourself</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={remoteWorking == 1 ? validationSchemaY : validationSchemaN}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
              return (
                <Form
                  id="onBoardingForm"
                  className={classes.onBoardingForm}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <div className="flex flex-wrap -mx-3 px-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="firstName"
                          name="firstName"
                          label="First Name *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.firstName && touched.firstName && errors.firstName}
                          error={errors.firstName}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="lastName"
                          name="lastName"
                          label="Last Name *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.lastName && touched.lastName && errors.lastName}
                          error={errors.lastName}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="linkedUrl"
                          name="linkedUrl"
                          label="LinkedIn URL"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.linkedUrl}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="email"
                          name="email"
                          label="Email ID *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.email && touched.email && errors.email}
                          error={errors.email}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 phoneNoBlock mobile-view">
                      {/* Country Code ... */}
                      <div className="w-full md:w-2/5 pr-1 pt-6 ph-left">
                        <div className={classes.phoneNumberBlock}>
                          <label className={classes.ccLabel}>Country Code *</label>
                          <p className="content-wrapper">
                            {coun.countryFlag && <img src={coun.countryFlag} className="flag" />}
                            <span style={{ paddingTop: "6px" }}>{coun.countryDialCode}</span>
                          </p>
                          <select
                            className="country-code-select1"
                            onChange={(e) => {
                              country
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((country) => {
                                  if (country.name === e.target.value) {
                                    setCoun({
                                      countryDialCode: country.dial_code,
                                      countryName: country.name,
                                      countryCode: country.code,
                                      countryFlag: `https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`,
                                    });
                                    setCountryFilter(country.code);
                                  }
                                });
                            }}
                            value={coun.countryName}
                          >
                            <option value="">Select Country</option>
                            {country
                              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                              .map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item.name}
                                    style={{ fontFamily: "Nunito Sans, sans-serif", color: "black" }}
                                  >
                                    {item.name + "     " + "(" + item.dial_code + ")"}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {checkCountryCode ? (
                          <span className={classes.validationErrorText}>Code is required</span>
                        ) : null}
                      </div>
                      <div
                        className={
                          contactStatus ? "w-full md:w-3/5 ph-right phoneValidation" : "w-full md:w-3/5 ph-right"
                        }
                      >
                        <div className="text-box">
                          <TextField
                            type="search"
                            autoComplete="off"
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={countryCodeCheck}
                            value={contact}
                            label="Phone Number *"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        {contactError ? <span className={classes.validationErrorText}>{phoneNoErrorMsg}</span> : null}
                      </div>
                      {/* Country Code ... */}
                    </div>
                    <div className=" flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="w-full md:w-6/6 mt-6 drop-block-multi customDropDownCity">
                        <div className={classes.multiSelectCity}>
                          <Autocomplete
                            id="currentCity"
                            name="currentCity"
                            options={cities}
                            value={currCity}
                            disableClearable
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCurrCity(newValue);
                                setCurrCityStatus(false);
                              } else {
                                setCurrCity("");
                                setCurrCityStatus(true);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Location *"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                placeholder={
                                  selectCountryCode
                                    ? currentCityMsg
                                    : !selectCountryCode && cities.length > 0
                                    ? "Select your city"
                                    : currentCityMsg
                                }
                              />
                            )}
                          />
                          {currCityStatus ? (
                            <span className={classes.validationErrorText}>Location is required</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 mobile-view-ot">
                      <div className="option-block">
                        <p>
                          <span>Engagement Preference *</span>
                        </p>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="preference"
                            value={1}
                            onChange={(e) => {
                              optionOnChange(e);
                            }}
                            checked={preference == 1 ? true : false}
                          />
                          <span className="ml-2">Full Time</span>
                        </label>
                        <label className="inline-flex items-center ml-6 option-item">
                          <input
                            type="radio"
                            className="form-radio"
                            name="preference"
                            value={2}
                            onChange={(e) => {
                              optionOnChange(e);
                            }}
                            checked={preference == 2 ? true : false}
                          />
                          <span className="ml-2">Part Time</span>
                        </label>
                        <label className="inline-flex items-center ml-6 option-item">
                          <input
                            type="radio"
                            className="form-radio"
                            name="preference"
                            value={3}
                            onChange={(e) => {
                              optionOnChange(e);
                            }}
                            checked={preference == 3 ? true : false}
                          />
                          <span className="ml-2">Both</span>
                        </label>
                      </div>
                    </div>
                    <div className=" flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="w-full md:w-1/2 working-div-left">
                        <div className="text-box">
                          <TextField
                            type="search"
                            autoComplete="off"
                            id="yearsOfExperience"
                            name="yearsOfExperience"
                            label="Years of Experience *"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={values.yearsOfExperience}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.yearsOfExperience && touched.yearsOfExperience && errors.yearsOfExperience
                            }
                            error={errors.yearsOfExperience}
                          />
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 pl-1 mt-6 working-div-right customDropDown">
                        <div className={classes.dropDown}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Remote Working *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={remoteWorking}
                              onChange={optionOnChange}
                              label="Remote Working *"
                              name="remoteWorking"
                            >
                              <MenuItem value={1}>Yes</MenuItem>
                              <MenuItem value={0}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-wrap w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="w-full charges-Div left-block md:w-1/2 ">
                        <div
                          className={
                            remoteWorking == 0 ? "text-box left-charges-div notWorking" : "text-box left-charges-div"
                          }
                        >
                          <label className="dollerSignLabel">$</label>
                          <TextField
                            type="search"
                            autoComplete="off"
                            id="chargesRemote"
                            name="chargesRemote"
                            label={remoteWorking == 1 ? "Remote charges/hour *" : "Remote charges/hour"}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={values.chargesRemote}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.chargesRemote && touched.chargesRemote && errors.chargesRemote}
                            error={errors.chargesRemote}
                          />
                        </div>
                      </div>
                      <div className="w-full charges-Div right-block md:w-1/2 pl-1">
                        <div className="text-box right-charges-div">
                          <label className="dollerSignLabel">$</label>
                          <TextField
                            type="search"
                            autoComplete="off"
                            id="chargesSite"
                            name="chargesSite"
                            label={remoteWorking == 0 ? "On Site charges/hour *" : "On Site charges/hour"}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={values.chargesSite}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.chargesSite && touched.chargesSite && errors.chargesSite}
                            error={errors.chargesSite}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="currentOrganization"
                          name="currentOrganization"
                          label="Current Organization *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.currentOrganization}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.currentOrganization && touched.currentOrganization && errors.currentOrganization
                          }
                          error={errors.currentOrganization}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mobile-view">
                      <div className="text-box">
                        <TextField
                          type="search"
                          autoComplete="off"
                          id="currentDesignation"
                          name="currentDesignation"
                          label="Current Designation *"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.currentDesignation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.currentDesignation && touched.currentDesignation && errors.currentDesignation
                          }
                          error={errors.currentDesignation}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 drop-block-multi mobile-view-ot">
                      <div className={classes.multiSelect}>
                        <label className={classes.fcc_label}>Capabilities *</label>
                        <CreatableSelect
                          className="creatableSelect"
                          isMulti
                          isClearable
                          onChange={handleFCChange}
                          onInputChange={handleFilter}
                          options={fCapabilities}
                          value={skillValue}
                          formatCreateLabel={(userInput) => `Add "${userInput}"`}
                          placeholder="Input first few characters"
                        />

                        {fcCheck ? (
                          <span className={classes.validationErrorText}>Functional Capabilities is required</span>
                        ) : null}
                      </div>
                    </div>
                    {successMsg ? (
                      <div className="w-full px-3">
                        <SussessAlert message="You have been registered successfully !" close={closeSuccessAlert} />
                      </div>
                    ) : null}
                    {errorMsg ? (
                      <div className="w-full px-3">
                        <ErrorAlert message={errorMagDesc} close={closeErrorAlert} />
                      </div>
                    ) : null}
                    <div className="w-full pt-10 text-center btn-mobile-view">
                      <button type="submit" className="round-btn">
                        {loaderStatus ? <CircularProgress /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="w-full md:w-2/5 text-center left-Block mobile-view-block">
          <Sidebar />
        </div>
      </div>
    </>
  );
};
export default OnboardingForm;
