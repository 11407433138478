import React, { useState, useEffect } from "react";

export default function Customize(props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div
      className={`${
        dimensions.width >= 1024 && dimensions.width <= 1100 ? "px-16" : "xl:px-32 px-14"
      }  py-12  items-center`}
      style={{ backgroundColor: "rgba(177, 175, 229, 0.2)", textAlign: "center" }}
    >
      <div>
        <h1 className="xl:text-4xl text-2xl font-bold">Couldn't find what you were looking for?</h1>
        <p
          className="mt-3 xl:text-xl text-center xl:text-left text-base "
          style={{ color: "#707376", textAlign: "center", marginBottom: "1rem" }}
        >
          Customize as per your need.
        </p>
      </div>

      <button
        className="rounded bg-indigo-800 text-white text-center py-1.5 w-full-eng text-base font-light"
        onClick={props.openModal}
      >
        Customize
      </button>
    </div>
  );
}
