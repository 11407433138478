import React from "react";
import classes from "./Blog.module.css";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import loupe from "../../assets/img/loupe.png";
const user_tyep_id = localStorage.getItem("adminAccessType");

function CareersSearch(props) {
  let history = useHistory();
  return (
    <div className={classes.search}>
      <div className={` ${classes.grid2Carrer}`}>
        <div>
          <input
            className={classes.inputCenter}
            type="text"
            placeholder="Enter Keywords"
            onChange={(e) => {
              props.getCaseStudies(e.target.value, 0, 1);
            }}
          />
          <img src={loupe} alt="search" />
        </div>

        {user_tyep_id == 1 || user_tyep_id == 3 ? (
          <div className={classes.btnBlog}>
            <button
              onClick={() => {
                history.push(`/blogs/create`);
              }}
            >
              Publish <AddIcon />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CareersSearch;
