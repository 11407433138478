import React, { useState, useEffect } from 'react';
import edit from '../../../../assets/img/editP.png';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import messActive from '../../../../assets/img/messActive.svg';
import messInActive from '../../../../assets/img/messInActive.svg';
import messError from '../../../../assets/img/messError.svg';
import linkActive from '../../../../assets/img/linkActive.svg';
import linkInActive from '../../../../assets/img/linkInactive.svg';
import linkError from '../../../../assets/img/linkErr.svg';
import close from '../../../../assets/img/cancel.svg';
import Modal from 'react-awesome-modal';
import CustomTextInput from '../../../auth/components/CustomTextInput';
import CustomTextArea from '../../../auth/components/CustomTextArea';
import LanguagesComponent from '../../../auth/Freelancer/SignUpStepTwo/LanguagesComponent';
import {saveProfileData} from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import LoadingOverlay from 'react-loading-overlay';



function AddProject(props) {
  const { consultant, saveProfileData } = props;
  const alert = useAlert();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const bigHeight = (80 / 100) * dimensions.height;
  const midHeight = (90 / 100) * dimensions.height;
  const myHeight = (50 / 100) * dimensions.height;
  const smallHeight = dimensions.height;


  const [projectIndustryList, setProjectIndustryList] = useState(
    consultant.consultantProfile.projectsArray === undefined
      ? []
      : consultant.consultantProfile.projectsArray.industryList
  );
  const [projectFunctionalList, setProjectFunctionalList] = useState(
    consultant.consultantProfile.projectsArray === undefined
      ? []
      : consultant.consultantProfile.projectsArray.functionalList
  );
  const copyArray = [...projectIndustryList];
  const copyfuncArray = [...projectFunctionalList];




  const initialValues = {
    title: '',
    description: '',
    email: '',
    linkedIn: '',
  };

  const validationSchema = yup.object().shape({
    title: yup.string(),
    description: yup.string(),
    email: yup.string().email('Must be a valid Email'),
    linkedIn: yup.string().url('must be a valid url'),
  });

  const durationValues = [
    { item: '1 - 6 months', value: '1 - 6 months' },
    { item: '6 months - 1 year', value: '6 months - 1 year' },
    { item: '1 year +', value: '1 year +' },
    { item: '2 years +', value: '2 years +' },
  ];
  

  const handleSubmit = (formValues) => {
    formValues.industryList= projectIndustryList;
    formValues.functionalList = projectFunctionalList;
    const { consultantProfile } = consultant;
    
    const dataObject = {
      projectsArray: !consultantProfile.projectsArray ?[formValues] :  [...consultantProfile.projectsArray , formValues],
    };
    const authId = localStorage.getItem('authId');
    saveProfileData(dataObject, authId, (res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      } else {
        props.setVisible(false);
      }
    });
  };
  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      onClickAway={() => props.setVisible(false)}
    >
      <LoadingOverlay active={consultant.loading} spinner text=" ">
        <div
          style={{
            height:
              dimensions.width < 780
                ? smallHeight
                : dimensions.width === 1200
                ? midHeight
                : dimensions.width === 1024
                ? midHeight
                : bigHeight,
            width: dimensions.width < 780 ? dimensions.width : 'auto',
          }}
          className="rounded-lg bg-white "
        >
          <div
            style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
            className="flex items-center justify-between p-8"
          >
            <p className="text-xl">ADD PROJECT</p>
            <img
              src={close}
              alt=""
              onClick={() => {
                props.setVisible(false);
              }}
              className="cursor-pointer"
            />
          </div>
          {/* main add content */}
          <div className="lg:px-20 px-10 mt-5  w-full h-4/6 overflow-y-scroll hideScroll ">
            <div className="border border-gray-300  rounded p-4">
              <p
                style={{
                  paddingBottom: 7,
                  color: 'rgba(10, 10, 10, 0.41)',
                  marginBottom: 15,
                  borderBottom: '1px solid rgba(10, 10, 10, 0.11)',
                }}
              >
                Project Cover Illustration
              </p>
              <div className="w-full flex flex-wrap items-center">
                <img
                  src={edit}
                  alt=""
                  className="h-24 rounded lg:w-40 w-full lg:mr-12"
                  loading="lazy"
                />

                <button
                  onClick={() => {}}
                  style={{ border: '1px solid #38369A', background: '#38369A' }}
                  className="text-base rounded text-white mt-8 lg:mt-0 px-4 py-2"
                >
                  Upload Image
                </button>
              </div>
            </div>

            <div className="w-full mb-10">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  return (
                    <Form id="add-project">
                      <CustomTextInput
                        name="title"
                        id="title"
                        type="text"
                        placeholder="Add Title *"
                      />
                      <CustomTextArea
                        rows="3"
                        name="description"
                        id="description"
                        type="text"
                        placeholder="Breif description about top projects *"
                      />

                      <LanguagesComponent
                        itemOnePlaceholder="Select Industry"
                        itemTwoPlaceholder="Duration"
                        languagesValues={consultant.industry_dropdown}
                        fluencyValues={durationValues}
                        langArrayList={projectIndustryList}
                        deleteFromArrayList={(valId) => {
                          if (valId > -1) {
                            copyArray.splice(valId, 1);
                            setProjectIndustryList([...copyArray]);
                          }
                        }}
                        setArrList={(valOne, valTwo) => {
                          setProjectIndustryList([
                            ...projectIndustryList,
                            {
                              lang: valOne,
                              fluency: valTwo,
                              ex:
                                valTwo == '1 - 6 months'
                                  ? 1
                                  : valTwo == '6 months - 1 year'
                                  ? 1
                                  : valTwo == '1 year +'
                                  ? 1
                                  : valTwo == '2 years +'
                                  ? 2
                                  : null,
                            },
                          ]);
                        }}
                        placeholder="Industry focus *"
                      />

                      <LanguagesComponent
                        itemOnePlaceholder="Select capabilities"
                        itemTwoPlaceholder="Duration"
                        languagesValues={consultant.functional_dropdown}
                        fluencyValues={durationValues}
                        langArrayList={projectFunctionalList}
                        deleteFromArrayList={(valId) => {
                          if (valId > -1) {
                            copyfuncArray.splice(valId, 1);
                            setProjectFunctionalList([...copyfuncArray]);
                          }
                        }}
                        setArrList={(valOne, valTwo) => {
                          setProjectFunctionalList([
                            ...projectFunctionalList,
                            {
                              lang: valOne,
                              fluency: valTwo,
                              ex:
                                valTwo == '1 - 6 months'
                                  ? 1
                                  : valTwo == '6 months - 1 year'
                                  ? 1
                                  : valTwo == '1 year +'
                                  ? 1
                                  : valTwo == '2 years +'
                                  ? 2
                                  : null,
                            },
                          ]);
                        }}
                        placeholder="Functional capabilities *"
                      />

                      <div className="border rounded p-4 mt-5">
                        <p
                          style={{
                            paddingBottom: 7,
                            color: 'rgba(10, 10, 10, 0.41)',
                            marginBottom: 15,
                            borderBottom: '1px solid rgba(10, 10, 10, 0.11)',
                          }}
                        >
                          Give us one refference who can vouch for your
                          awesomness
                        </p>

                        <CustomTextInput
                          name="email"
                          id="email"
                          type="text"
                          placeholder="Email address"
                          activeIcon={messActive}
                          inactiveIcon={messInActive}
                          errorIcon={messError}
                        />
                        <CustomTextInput
                          name="linkedIn"
                          id="linkedIn"
                          type="text"
                          placeholder="LinkedIn profile link"
                          activeIcon={linkActive}
                          inactiveIcon={linkInActive}
                          errorIcon={linkError}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          {/* main add content */}

          <div
            className="px-10 py-5 flex justify-end"
            style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
          >
            <button
              onClick={() => {
                props.setVisible(false);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              form="add-project"
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Save Changes
            </button>
          </div>
      </div>
        </LoadingOverlay>
    </Modal>
  );
}


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData,
})(AddProject);
