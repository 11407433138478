import useStyles from "./AlertMsg.css";
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

const SussessAlert = props => {
    const classes = useStyles();
    return (
        <div className={classes.successMsg}>
            <p><CheckCircleOutlineIcon />{props.message}</p>
            <span onClick={props.close}><CloseIcon /></span>
        </div>
    );

}

export default SussessAlert;