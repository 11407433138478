import React from 'react';

export default function LineThree() {
  return (
    <svg
      width="174"
      className="relative hidden lineThree xl:block"
      height="900"
      viewBox="0 0 174 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        opacity="0.3"
        x1="102"
        y1="235.5"
        x2="174"
        y2="235.5"
        stroke="#B1AFE5"
      />
      <rect y="46" width="16" height="16" fill="#B3F4FA" />
      <line
        x1="84.4"
        y1="651.4"
        x2="84.4"
        y2="254.6"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      <line
        x1="8.6"
        y1="71.6"
        x2="8.60004"
        y2="999.4"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      <path
        d="M8.59883 1.5L8.59961 36.4"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 6"
      />
      <path
        d="M9 950.802V950.802C9 941.254 12.7966 932.098 19.5531 925.351L54.6583 890.298C73.444 871.54 84 846.083 84 819.535V657.302"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-dasharray="6 8"
      />
      <path
        d="M84 214.802V214.802C84 196.291 76.6393 178.539 63.54 165.459L9 111"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-dasharray="6 8"
      />
      <path
        d="M83 229L89.9632 235.963L83 242.926"
        stroke="#38369A"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
