import React from 'react';

export default function Chat() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 41.5C24.5 37.3645 21.1355 34 17 34C12.8645 34 9.5 37.3645 9.5 41.5C9.5 45.6355 12.8645 49 17 49C21.1355 49 24.5 45.6355 24.5 41.5ZM17 45.25C14.9323 45.25 13.25 43.5677 13.25 41.5C13.25 39.4323 14.9323 37.75 17 37.75C19.0677 37.75 20.75 39.4323 20.75 41.5C20.75 43.5677 19.0677 45.25 17 45.25Z"
        fill="black"
      />
      <path
        d="M17 49C9.76288 49 3.875 54.8879 3.875 62.125C3.875 63.1605 4.7145 64 5.75 64H28.25C29.2855 64 30.125 63.1605 30.125 62.125C30.125 54.8879 24.2371 49 17 49ZM7.81337 60.25C8.6845 55.9761 12.4726 52.75 17 52.75C21.5274 52.75 25.3155 55.9761 26.1866 60.25H7.81337Z"
        fill="black"
      />
      <path
        d="M54.5 41.5C54.5 37.3645 51.1355 34 47 34C42.8645 34 39.5 37.3645 39.5 41.5C39.5 45.6355 42.8645 49 47 49C51.1355 49 54.5 45.6355 54.5 41.5ZM47 45.25C44.9323 45.25 43.25 43.5677 43.25 41.5C43.25 39.4323 44.9323 37.75 47 37.75C49.0677 37.75 50.75 39.4323 50.75 41.5C50.75 43.5677 49.0677 45.25 47 45.25Z"
        fill="black"
      />
      <path
        d="M47 49C39.7629 49 33.875 54.8879 33.875 62.125C33.875 63.1605 34.7145 64 35.75 64H58.25C59.2855 64 60.125 63.1605 60.125 62.125C60.125 54.8879 54.2371 49 47 49ZM37.8134 60.25C38.6845 55.9761 42.4726 52.75 47 52.75C51.5274 52.75 55.3155 55.9761 56.1866 60.25H37.8134Z"
        fill="black"
      />
      <path
        d="M24.375 7.5H9.375C8.3395 7.5 7.5 8.3395 7.5 9.375C7.5 10.4105 8.3395 11.25 9.375 11.25H24.375C25.4105 11.25 26.25 10.4105 26.25 9.375C26.25 8.3395 25.4105 7.5 24.375 7.5Z"
        fill="#38369A"
      />
      <path
        d="M24.375 15H9.375C8.3395 15 7.5 15.8395 7.5 16.875C7.5 17.9105 8.3395 18.75 9.375 18.75H24.375C25.4105 18.75 26.25 17.9105 26.25 16.875C26.25 15.8395 25.4105 15 24.375 15Z"
        fill="#38369A"
      />
      <path
        d="M54.625 7.5H39.625C38.5895 7.5 37.75 8.3395 37.75 9.375C37.75 10.4105 38.5895 11.25 39.625 11.25H54.625C55.6605 11.25 56.5 10.4105 56.5 9.375C56.5 8.3395 55.6605 7.5 54.625 7.5Z"
        fill="#38369A"
      />
      <path
        d="M54.625 15H39.625C38.5895 15 37.75 15.8395 37.75 16.875C37.75 17.9105 38.5895 18.75 39.625 18.75H54.625C55.6605 18.75 56.5 17.9105 56.5 16.875C56.5 15.8395 55.6605 15 54.625 15Z"
        fill="#38369A"
      />
      <path
        d="M58.375 0H5.625C2.52338 0 0 2.52338 0 5.625V20.625C0 23.7266 2.52338 26.25 5.625 26.25H12.3484L15.5492 29.4509C16.2814 30.1831 17.4686 30.1831 18.2009 29.4509L21.4016 26.25H42.5983L45.7991 29.4509C46.5312 30.183 47.7185 30.1831 48.4507 29.4509L51.6516 26.25H58.375C61.4766 26.25 64 23.7266 64 20.625V5.625C64 2.52338 61.4766 0 58.375 0ZM60.25 20.625C60.25 21.6589 59.4089 22.5 58.375 22.5H50.875C50.3778 22.5 49.9009 22.6975 49.5493 23.0491L47.125 25.4734L44.7007 23.0491C44.3491 22.6975 43.8722 22.5 43.375 22.5H20.625C20.1278 22.5 19.6509 22.6975 19.2992 23.0491L16.875 25.4734L14.4508 23.0491C14.0991 22.6975 13.6222 22.5 13.125 22.5H5.625C4.59113 22.5 3.75 21.6589 3.75 20.625V5.625C3.75 4.59113 4.59113 3.75 5.625 3.75H58.375C59.4089 3.75 60.25 4.59113 60.25 5.625V20.625Z"
        fill="black"
      />
    </svg>
  );
}
