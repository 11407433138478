/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import SmallHome from "./SmallHome";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCaseStudies } from "../../state/actions/caseStudyAction";
import { Helmet } from "react-helmet";

function Home({ getCaseStudies }) {
  useEffect(() => {
    getCaseStudies();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Our strategy services help you get quick access to the top 1% expertise in technology, marketing, and data science directly to your team. Reach out to us to solve your challenges & drive results."
        />
        <title>Access Top 1% Experts - Drive Real Business Impact | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <SmallHome />
    </div>
  );
}

getCaseStudies.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getCaseStudies })(Home);
