import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import rocket from '../../../../../assets/img/bigRocket.svg';
import ForwardArrow from './ForwardArrow';
import EnquireModal from '../../../../MarketPlace/EnquireModal';

// import marketImg from '../../../../../assets/img/market.svg';

export default function MarketPlace() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <div className="flex justify-between flex-wrap items-center mt-24 xl:mt-0 xl:py-28 py-14 md:px-52 px-10 ">
      {/* <img src={marketImg} alt="" loading="lazy" className="" /> */}

      {modalState ? (
        <EnquireModal
          visible={modalState}
          setVisible={(val) => {
            setModalState(val);
          }}
        />
      ) : null}
      <LazyLoadImage
        alt={rocket}
        effect="blur"
        style={{
          height: '24rem',
          // flexGrow: 1,
          // position: 'relative',
          // left: dimensions.width < 1024 ? 0 : '50%',
        }}
        className=" h-96 flex-1 mx-auto"
        src={rocket}
      />

      <div
        className={`${
          dimensions.width <= 1200 && dimensions.width >= 1024
            ? 'w-5/12'
            : 'lg:w-3/6 w-full'
        } ${
          dimensions.width <= 1200 && dimensions.width >= 1024
            ? 'mt-0'
            : 'xl:mt-0 mt-28'
        } mb-10 xl:mb-0 `}
      >
        <h2 className="font-bold xl:text-5xl text-2xl">Marketplace Experts</h2>

        <p
          className="xl:text-xl text-base mt-6 font-light"
          style={{ color: '#707376' }}
        >
          Subscribe with us and gain full access to a trusted network of
          advisors that can guide you to success.
        </p>

        <div
          style={{
            borderTop: '0.8px solid #E0E3E7',
            marginTop: 28,
            paddingTop: 28,
          }}
        >
          <span className="flex flex-row">
            <ForwardArrow />
            <p
              className="xl:text-xl text-base font-light w-5/6"
              style={{ color: '#707376', marginLeft: 28 }}
            >
              Get full access to blue-chip advisors equipped and ready to
              support you to success at an affordable rate ( hourly ).
            </p>
          </span>
          <span className="flex flex-row mt-10">
            <ForwardArrow />
            <p
              className="xl:text-xl text-base font-light w-5/6"
              style={{ color: '#707376', marginLeft: 28 }}
            >
              Schedule your call on-demand via StrategyConnect Virtual Room.
            </p>
          </span>
        </div>

        {/* <Link
              to="/marketplace"
              className="py-2 px-3.5 rounded bg-indigo-800 text-white text-lg font-light text-center relative top-10"
            >
              Know More
            </Link> */}
        <button
          onClick={() => {
            setModalState(true);
          }}
          className="py-2 px-3.5 rounded bg-indigo-800 text-white text-lg font-light text-center relative top-10"
        >
          Know More
        </button>
      </div>
    </div>
  );
}
