import React from "react";
import "./TechDue.css";
import ActionPlan from "../../assets/img/Com/ActionPlan.svg";
import AdditionalDataRequest from "../../assets/img/Com/AdditionalDataRequest.svg";
import CompetitiveLandscape from "../../assets/img/Com/CompetitiveLandscape.svg";
import DueDiligenceMandate from "../../assets/img/Com/DueDiligenceMandate.svg";
import ExecutiveSummary from "../../assets/img/Com/ExecutiveSummary.svg";
import Finaldebriefing from "../../assets/img/Com/Finaldebriefing.svg";
import FutureBusinessPlans from "../../assets/img/Com/FutureBusinessPlans.svg";
import InterviewsDiscussions from "../../assets/img/Com/InterviewsDiscussions.svg";
import InvestmentThesis from "../../assets/img/Com/InvestmentThesis.svg";
import Reports from "../../assets/img/Com/Reports.svg";
import ResearchAnalysis from "../../assets/img/Com/ResearchAnalysis.svg";
import UnderstandingtheBusiness from "../../assets/img/Com/UnderstandingtheBusiness.svg";
import UnderstandingtheMarket from "../../assets/img/Com/UnderstandingtheMarket.svg";
import DataRoom from "../../assets/img/Com/DataRoom.svg";
import DetailedReport from "../../assets/img/Com/DetailedReport.svg";
import { Helmet } from "react-helmet";

function CommercialDue(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Understand the market potential, competitive landscape, business plan, and future growth strategy with our expert-driven Commercial Due Diligence services. "
        />
        <title>Commercial Due Diligence Service | StrategyConnec</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <div className="tech">
        <div className="padding-tech">
          <h1 className="d">Objectives </h1>
        </div>
        <div className="tech-parent">
          <div className="grid lg:px-32 sm:px-24 px-2">
            <div className="bg-red">
              <div className="tech-icon">
                <img src={UnderstandingtheMarket} alt="UnderstandingtheMarket"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Understanding the Market</div>
                <div className="tech-icon-text">
                  Identifying market potential, market segmentation, key players and trends
                </div>
              </div>
            </div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={UnderstandingtheBusiness} alt="UnderstandingtheBusiness"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Understanding the Business</div>
                <div className="tech-icon-text">
                  Evaluating the current position & long term viability of the business-finances, operations & team
                </div>
              </div>
            </div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={CompetitiveLandscape} alt="CompetitiveLandscape"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Competitive Landscape</div>
                <div className="tech-icon-text">
                  Identifying the key players, their value propositions & the market structure
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-1 lg:px-32 sm:px-24 px-2">
            <div></div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={FutureBusinessPlans} alt="FutureBusinessPlans"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Future Business Plans</div>
                <div className="tech-icon-text">Assessing the future growth strategy and financial projections</div>
              </div>
            </div>
            <div className="bg-red">
              <div className="tech-icon">
                <img src={InvestmentThesis} alt="InvestmentThesis"></img>
              </div>
              <div className="tech-icon-box">
                <div className="tech-icon-header">Investment Thesis</div>
                <div className="tech-icon-text">Assessing investment quantum, timelines & expected returns</div>
              </div>
            </div>
          </div>
        </div>
        {/* seconddddddddd */}
        <div className="process padding-tech">
          <h1>Process</h1>
          <div className="tech-parent mb-p-3">
            <div className="grid lg:px-32 sm:px-24 px-2">
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={DueDiligenceMandate} alt="DueDiligenceMandate" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Due Diligence Mandate</div>
                  <div className="tech-icon-text">Understanding the investment thesis</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={DataRoom} alt="DataRoom" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Data Room</div>
                  <div className="tech-icon-text">Providing a list of documents by the target in the data room</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={AdditionalDataRequest} alt="AdditionalDataRequest" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Additional Data Request</div>
                  <div className="tech-icon-text">
                    Issuing custom data request to the target via a series of questionnaires
                  </div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={InterviewsDiscussions} alt="InterviewsDiscussions" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Interviews & Discussions</div>
                  <div className="tech-icon-text">Collaborating with key stakeholders on buy & sell sides</div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={ResearchAnalysis} alt="ResearchAnalysis" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Research & Analysis</div>
                  <div className="tech-icon-text">
                    Conducting in-depth research on internal and external business factors
                  </div>
                </div>
              </div>
              <div className="bg-process">
                <div className="bg-process-i">
                  <img src={Reports} alt="Reports" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Reports</div>
                  <div className="tech-icon-text">Drafting a comprehensive report with an investment approach</div>
                </div>
              </div>
            </div>
            <div className="lg:px-32 sm:px-24 px-2 tecc">
              <div className="bg-process bg-process-1">
                <div className="bg-process-i">
                  <img src={Finaldebriefing} alt="Finaldebriefing" />
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Final Debriefing</div>
                  <div className="tech-icon-text">Scheduling a debrief to discuss the ﬁndings</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Thirdddddddddd */}
        <div className="bg-gray padding-tech">
          <div className="padding-tech">
            <h1 className="d">Deliverables</h1>
          </div>
          {/* <h1></h1> */}

          <div className="tech-parent">
            <div className="grid lg:px-32 sm:px-24 px-2">
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={DetailedReport} alt="DetailedReport"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Detailed Report</div>
                  <div className="tech-icon-text">Detailed report on ﬁndings, organized by topics in the scope</div>
                </div>
              </div>
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={ExecutiveSummary} alt="ExecutiveSummary"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Executive Summary</div>
                  <div className="tech-icon-text">
                    2-3 pages report detailing most relevant information presented during ﬁnal debrief
                  </div>
                </div>
              </div>
              <div className="bg-red">
                <div className="tech-icon">
                  {" "}
                  <img src={ActionPlan} alt="ActionPlan"></img>
                </div>
                <div className="tech-icon-box">
                  <div className="tech-icon-header">Recommended Action Plan</div>
                  <div className="tech-icon-text">Recommendations based on ﬁndings</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-now">
          <button
            onClick={() => {
              props.showConatact("sss");
            }}
          >
            Get it Now
          </button>
        </div>
      </div>
    </>
  );
}

export default CommercialDue;
