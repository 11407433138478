import React from 'react';

export default function SecondLine() {
  return (
    <svg
      width="174"
      height="1105"
      viewBox="0 0 174 1204"
      className="relative hidden lineTwo xl:block"
      style={{ left: 9 }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        opacity="0.3"
        x1="102"
        y1="270.5"
        x2="174"
        y2="270.5"
        stroke="#B1AFE5"
      />
      <rect y="41" width="16" height="16" fill="#3B3A97" />
      <line
        x1="8.6"
        y1="66.6"
        x2="8.60005"
        y2="1203.4"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      <line
        x1="83.4"
        y1="814.4"
        x2="83.4"
        y2="291.6"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      <line
        x1="84.6"
        y1="217.6"
        x2="84.6"
        y2="250.4"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      <path
        d="M9 1115.8V1115.8C9 1106.25 12.7966 1097.1 19.5531 1090.35L54.6583 1055.3C73.444 1036.54 84 1011.08 84 984.535V822.302"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-dasharray="6 8"
      />
      <path
        d="M84 209.802V209.802C84 191.291 76.6393 173.539 63.54 160.459L9 106"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-dasharray="6 8"
      />
      <line
        x1="8.6"
        y1="0.6"
        x2="8.6"
        y2="56.4"
        stroke="#B1AFE5"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="6 8"
      />
      {/* <path
        d="M75.5854 279.415C77.8332 281.662 80.8212 282.9 84 282.9C87.1788 282.9 90.1668 281.662 92.4146 279.415C94.6623 277.167 95.9 274.179 95.9 271C95.9 267.821 94.6623 264.833 92.4146 262.585C90.1668 260.338 87.1788 259.1 84 259.1C80.8212 259.1 77.8332 260.338 75.5854 262.585C75.5854 262.585 75.5854 262.585 75.5854 262.585M75.5854 279.415L75.5147 279.485C73.2482 277.219 72 274.205 72 271C72 267.795 73.2482 264.781 75.5147 262.515L75.5854 262.585M75.5854 279.415C73.3377 277.167 72.1 274.179 72.1 271C72.1 267.821 73.3377 264.833 75.5854 262.585M75.5854 279.415V262.585M84 260.775C78.3618 260.775 73.775 265.362 73.775 271C73.775 276.638 78.3618 281.225 84 281.225C89.6382 281.225 94.225 276.638 94.225 271C94.225 265.362 89.6382 260.775 84 260.775ZM83.9531 271.791C81.527 271.791 79.5531 269.817 79.5531 267.391C79.5531 264.965 81.527 262.991 83.9531 262.991C86.3792 262.991 88.3531 264.965 88.3531 267.391C88.3531 269.817 86.3792 271.791 83.9531 271.791ZM83.9531 264.666C82.4504 264.666 81.2281 265.888 81.2281 267.391C81.2281 268.893 82.4504 270.116 83.9531 270.116C85.4558 270.116 86.6781 268.893 86.6781 267.391C86.6781 265.888 85.4558 264.666 83.9531 264.666Z"
        fill="#0023C4"
        stroke="#F7F7FC"
        stroke-width="0.2"
      /> */}
    </svg>
  );
}
