import React, { useState, useEffect } from 'react';
import close from '../../../../assets/img/cancel.svg';
import Modal from 'react-awesome-modal';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import LanguagesComponent from '../../../auth/Freelancer/SignUpStepTwo/LanguagesComponent';
import CustomTextInput from '../../../auth/components/CustomTextInput';
import CustomDropdown from '../../../auth/components/CustomDropdown';
import downArrow from '../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../assets/img/textUpArrow.svg';
import CustomDatePicker from '../../../auth/Freelancer/SignUpStepThree/SignUpThreeForm/CustomDatePicker';
import CustomTextArea from '../../../auth/components/CustomTextArea';
import {
  saveProfileData,
} from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { useAlert } from 'react-alert';



function AddExperienceModal(props) {
  const {  saveProfileData,consultant } = props;
  const alert = useAlert();
  

  const [professionActive, setProfessionActive] = useState(false);
  const [employmentType, setEmploymentType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const bigHeight = (80 / 100) * dimensions.height;
  const midHeight = (90 / 100) * dimensions.height;
  const smallHeight = dimensions.height;

  const [industryList, setIndustryList] = useState(
    !consultant.consultantProfile.industryList ? [] : consultant.consultantProfile.industryList
  );
  const [functionalList, setFunctionalList] = useState(!consultant.consultantProfile.functionalList ? [] : consultant.consultantProfile.functionalList);
  const copyArray = [...industryList];
  const copyfuncArray = [...functionalList];


  const durationValues = [
    { item: '< 1', value: '< 1' },
    { item: '1', value: '1' },
    { item: '2 ', value: '2' },
    { item: '3', value: '3' },
    { item: '4', value: '4' },
    { item: '5', value: '5' },
    { item: '6', value: '6' },
    { item: '7', value: '7' },
    { item: '8', value: '8' },
    { item: '9', value: '9' },
    { item: '10 >', value: '10 >' },
    // { item: '1 - 6 months', value: '1 - 6 months' },
    // { item: '6 months - 1 year', value: '6 months - 1 year' },
    // { item: '1 year +', value: '1 year +' },
    // { item: '2 years +', value: '2 years +' },
  ];

  const employmentTypeItems = [
    { item: 'Remote', value: 'Remote' },
    { item: 'Full Time', value: 'Full Time' },
    { item: 'Part Time', value: 'Part time' },
  ];

  const initialValues = {
    position: '',
    company: '',
    location: '',
    responsibilities: '',
  };

  const validationSchema = yup.object().shape({
    company: yup.string(),
    location: yup.string(),
    position: yup.string(),
    responsibilities: yup.string(),
  });


  const handleSubmit = (formValues) => {
    const dataObject = {
      industryList: industryList,
      functionalList: functionalList,
      professionalData: 
       formValues.position.length  < 1 ? []:
        !consultant.consultantProfile.professionalData
          ? [{
              position: formValues.position,
              location: formValues.location,
              responsibilities: formValues.responsibilities,
              startDate: startDate,
              endDate: endDate,
              company: formValues.company,
              employmentType: employmentType,
            }]
          : [
            ...consultant.consultantProfile.professionalData,
            {
              
              position: formValues.position,
              location: formValues.location,
              responsibilities: formValues.responsibilities,
              startDate: startDate,
              endDate: endDate,
              company: formValues.company,
              employmentType: employmentType,
            },]
      
    };
    const authId = localStorage.getItem('authId');
    saveProfileData(dataObject, authId, (res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      } else {
         props.setVisible(false);
      }
    });
  };

  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width == 1200
          ? '45%'
          : dimensions.width == 1024
          ? '50%'
          : '40%'
      }
      onClickAway={() => props.setVisible(false)}
    >
      <LoadingOverlay active={consultant.loading} spinner text=" ">
        <div
          style={{
            height:
              dimensions.width < 780
                ? smallHeight
                : dimensions.width == 1200
                ? midHeight
                : dimensions.width == 1024
                ? midHeight
                : bigHeight,
          }}
          className="rounded-lg bg-white "
        >
          <div
            style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
            className="flex items-center justify-between p-8"
          >
            <p className="text-xl uppercase">
              {props.heading}
              {/* Add experience */}
            </p>
            <img
              src={close}
              alt=""
              onClick={() => {
                props.setVisible(false);
              }}
              className="cursor-pointer"
            />
          </div>

          {/* main content */}
          <div className="xl:px-24 md:px-14 px-10 my-3  h-4/6 overflow-y-scroll hideScroll">
            {/* innerContent */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                return (
                  <Form
                    onSubmit={formProps.handleSubmit}
                    id="Add_experience_modal"
                  >
                    <LanguagesComponent
                      itemOnePlaceholder="Select Industry"
                      itemTwoPlaceholder="Duration"
                      languagesValues={consultant.industry_dropdown}
                      fluencyValues={durationValues}
                      langArrayList={industryList}
                      deleteFromArrayList={(valId) => {
                        if (valId > -1) {
                          copyArray.splice(valId, 1);
                          setIndustryList([...copyArray]);
                        }
                      }}
                      setArrList={(valOne, valTwo) => {
                        setIndustryList([
                          ...industryList,
                          {
                            lang: valOne,
                            fluency: valTwo,
                            ex:
                              valTwo == '< 1'
                                ? 1
                                : Number(valTwo) == 1
                                ? 1
                                : Number(valTwo) > 1 && Number(valTwo) <= 3
                                ? 1
                                : Number(valTwo) > 3 && Number(valTwo) <= 9
                                ? 2
                                : Number(valTwo) == '10 >'
                                ? 3
                                : 0,
                          },
                        ]);
                      }}
                      placeholder="Industry focus *"
                    />

                    <LanguagesComponent
                      itemOnePlaceholder="Select capabilities"
                      itemTwoPlaceholder="Duration"
                      languagesValues={consultant.functional_dropdown}
                      fluencyValues={durationValues}
                      langArrayList={functionalList}
                      deleteFromArrayList={(valId) => {
                        if (valId > -1) {
                          copyfuncArray.splice(valId, 1);
                          setFunctionalList([...copyfuncArray]);
                        }
                      }}
                      setArrList={(valOne, valTwo) => {
                        setFunctionalList([
                          ...functionalList,
                          {
                            lang: valOne,
                            fluency: valTwo,
                            ex:
                              valTwo == '< 1'
                                ? 1
                                : Number(valTwo) == 1
                                ? 1
                                : Number(valTwo) > 1 && Number(valTwo) <= 3
                                ? 1
                                : Number(valTwo) > 3 && Number(valTwo) <= 9
                                ? 2
                                : Number(valTwo) == '10 >'
                                ? 3
                                : 0,
                          },
                        ]);
                      }}
                      placeholder="Functional capabilities *"
                    />

                    <div
                      className={`flex  flex-col justify-between mt-6 relative cursor-pointer ${
                        professionActive ? 'active' : 'inactive'
                      }-textInput`}
                    >
                      <p style={{ color: '#707376' }} className="text-base">
                        Professional information *
                      </p>
                      <div
                        style={{
                          borderTop: '1px solid rgba(10, 10, 10, 0.05)',
                        }}
                        className="mt-3 w-full mb-3"
                      >
                        <CustomTextInput
                          name="position"
                          id="position"
                          type="text"
                          placeholder="Position *"
                        />

                        <CustomDropdown
                          items={employmentTypeItems}
                          placeholder="Employment type *"
                          id="employmentType"
                          width="w-full"
                          onValueChange={(val) => {
                            setEmploymentType(val);
                            setProfessionActive(true);
                          }}
                          downArrow={downArrow}
                          upArrow={upArrow}
                        />

                        <CustomTextInput
                          name="company"
                          id="company"
                          type="text"
                          placeholder="Company *"
                        />

                        <CustomTextInput
                          name="location"
                          id="location"
                          type="text"
                          placeholder="Location *"
                        />

                        <div className="flex lg:flex-row flex-col w-full items-center justify-between">
                          <CustomDatePicker
                            title="Start date *"
                            placeholder="Select Start Date"
                            returnedValue={setStartDate}
                            width="lg:w-40 w-full"
                          />
                          <CustomDatePicker
                            placeholder="Select End Date"
                            title="End date *"
                            returnedValue={setEndDate}
                            width="lg:w-40 w-full "
                          />
                        </div>

                        <CustomTextArea
                          rows="3"
                          name="responsibilities"
                          id="responsibilities"
                          type="text"
                          placeholder={`Your responsibility at ${formProps.values.company}`}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            {/* innerContent */}
          </div>
          {/* main content */}

          <div
            className="px-10 py-5 flex justify-end"
            style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
          >
            <button
              onClick={() => {
                props.setVisible(false);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Cancel
            </button>
            <button
              form="Add_experience_modal"
              type="submit"
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Save Changes
            </button>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData
})(AddExperienceModal);
