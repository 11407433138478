/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import logo from '../../../../../assets/img/regLogo.svg';
import OtpInput from 'react-otp-input';
import ContinueFooter from '../../../components/ContinueFooter';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import { connect } from 'react-redux';
import {
  postRegisterData,
  verifyPassCode,
} from '../../../../../state/actions/consultantAction';
import { useAlert } from 'react-alert';

const percentage = 66.6;

 function PassForm({ data, postRegisterData, consultant, verifyPassCode }) {
   const history = useHistory();
   const [otp, setOtp] = useState('');
   const [resendActive, setResendActive] = useState(false);
    const alert = useAlert();

   return (
     <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
       <div className=" flex flex-col justify-center md:px-16 xl:px-32 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9 waitBorderBottom">
           Enter Passcode
         </h1>

         <form
           id="passcode"
           onSubmit={(e) => {
             e.preventDefault();
             const verifyData = {
               code: otp,
               token: consultant.registerToken,
               type: 'EMAIL_VERIFICATION',
             };
             //  console.log(otp);
             verifyPassCode(verifyData, (res) => {
               if (Object.keys(res).length > 0) {
                 alert.error(res.error);
               } else {
                 history.push('/freelancer/sign-up/step-three');
               }
               
             });
           }}
         >
           <div className="mt-10">
             <OtpInput
               inputStyle={{
                 height: 40,
                 width: 40,
                 border: '1.5px solid #D1D4D8',
                 borderRadius: 5,
               }}
               focusStyle={{
                 outline: 'none',
                 height: 40,
                 width: 40,
                 border: '1.5px solid #38369A',
                 color: '#38369A',
                 borderRadius: 5,
               }}
               containerStyle="justify-between"
               value={otp}
               onChange={(val) => setOtp(val)}
               numInputs={6}
               shouldAutoFocus={false}
               isInputNum
             />
           </div> 
         </form>

         <div className="flex justify-between mt-5 otpBottom">
           <p className="text-base font-thin" style={{ color: '#0A0A0A' }}>
             {/* 0:25 sec */}
             <Countdown
               onComplete={() => {
                 setResendActive(true);
               }} 
               date={Date.now() + 600000}
               zeroPadTime={0}
             />
           </p>

           <button
             disabled={!resendActive}
             onClick={() => {
               if (resendActive) {
                 postRegisterData(data, () => {
                   console.log('resent data');
                 });
               }
             }}
             className="text-base "
             style={{ color: !resendActive ? 'gray' : '#38369A' }}
           >
             Resend Code
           </button>
        
        
         </div>

         <p style={{ color: '#707376' }} className="text-center mt-7 font-thin">
           we sent a email with a verification code to{' '}
           <span
             style={{ color: '#38369A', fontWeight: '500', cursor: 'pointer' }}
           >
             {data.email}
           </span>{' '}
           . if you don’t receive the email, check your spam folder,
         </p>
       </div>

       <ContinueFooter
         goBack={() => {
           history.push('/freelancer/sign-up/step-one');
         }}
         percentage={percentage}
         id="passcode"
       />
     </div>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, { postRegisterData, verifyPassCode })(
  PassForm
);