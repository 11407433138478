import React from 'react';

export default function Check() {
  return (
    <svg
      width="24"
      height="31"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.47122 10.7474C5.34656 10.8727 5.17656 10.9427 4.99989 10.9427C4.82322 10.9427 4.65322 10.8727 4.52856 10.7474L0.764558 6.98272C0.373891 6.59205 0.373891 5.95871 0.764558 5.56872L1.23589 5.09738C1.62656 4.70672 2.25922 4.70672 2.64989 5.09738L4.99989 7.44738L11.3499 1.09738C11.7406 0.706716 12.3739 0.706716 12.7639 1.09738L13.2352 1.56872C13.6259 1.95938 13.6259 2.59272 13.2352 2.98272L5.47122 10.7474Z"
        fill="#38369A"
      />
    </svg>
  );
}
