import React from 'react';

export default function ExperienceTextBox({ setValue }) {

  return (
    <div
      className={`flex items-center justify-between mt-6 relative cursor-pointer ${'inactive'}-textInput`}
    >
      {/* <p style={{ color: '#707376', opacity: 0.5 }}>
        
      </p> */}
      <div
        style={{ color: 'black', opacity: 0.3 }}
        className="font-bold text-xl"
      >
        <input
          placeholder="Total years of experience"
          className="focus:outline-none"
          style={{ width: '100%' }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
