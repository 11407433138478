/* eslint-disable import/no-anonymous-default-export */

import {
  COMPANY_SAVE_AUTH_TOKEN,
  POST_LOADING,
  STOP_LOADING,
  POST_COMPANY_OTP,
  COMPANY_DATA,
} from '../actions/types';

const initialState = {
  registerToken: '',
  companyOtpToken:'',
  loading:false,
  companyData:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPANY_SAVE_AUTH_TOKEN:
      return {
        ...state,
        loading: false,
        registerToken: action.payload,
      };
    case POST_COMPANY_OTP:
      return {
        ...state,
        loading: false,
        companyOtpToken: action.payload,
      };
    case COMPANY_DATA:
      return {
        ...state,
        loading: false,
        companyData: action.payload,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case POST_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
