/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import logo from '../../../../../assets/img/regLogo.svg';
import ContinueFooter from '../../../components/ContinueFooter';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

// messgae icons
import messActive from '../../../../../assets/img/messActive.svg';
import messInActive from '../../../../../assets/img/messInActive.svg';
import messError from '../../../../../assets/img/messError.svg';
// // password icons
import passActive from '../../../../../assets/img/passActive.svg';
import passInActive from '../../../../../assets/img/passInActive.svg';
import passError from '../../../../../assets/img/passError.svg';
import { connect } from 'react-redux';
import {
  resetPassword
} from '../../../../../state/actions/consultantAction';
import { useAlert } from 'react-alert';
import CustomTextInput from '../../../components/CustomTextInput';

 function ResetPasswordForm({ resetPassword, consultant }) {
   const history = useHistory();
    const alert = useAlert();

    const validationSchema = yup.object().shape({
      password: yup
        .string()
        .min(6, 'Has to have at least 6 characters')
        .required('This is required'),
      password_again: yup
        .string()
        .min(6, 'Has to have at least 6 characters')
        .required('This is required'),
      code: yup.string()
    });
    const handleSubmit = (formValues) => {
      console.log(formValues);
      let token = consultant.resetPasswordToken;
      resetPassword(token, formValues.code, formValues.password, formValues.password_again, (res) => {
        if (Object.keys(res).length > 0) {
          alert.error(res.error);
        } else {
          history.push('/profile');
        }
        
      });
    };
   return (
     <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
       <div className=" flex flex-col justify-center md:px-16 xl:px-32 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9 waitBorderBottom">
           Reset Password
         </h1>

         <Formik
           initialValues={{ code: '', password: '', password_again: '' }}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="reset-password" onSubmit={props.handleSubmit}>
                 <CustomTextInput
                  name="code"
                  id="code"
                  type="string"
                  placeholder="OTP*"
                  // onChange={(e) => setEmail(e.target.value)}
                  activeIcon={messActive}
                  inactiveIcon={messInActive}
                  errorIcon={messError}
                />
                <CustomTextInput
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Password*"
                  // onChange={(e) => setPassword(e.target.value)}
                  activeIcon={passActive}
                  inactiveIcon={passInActive}
                  errorIcon={passError}
                />
                <CustomTextInput
                  name="password_again"
                  id="password_again"
                  type="password"
                  placeholder="Password*"
                  // onChange={(e) => setPassword(e.target.value)}
                  activeIcon={passActive}
                  inactiveIcon={passInActive}
                  errorIcon={passError}
                />
                
               </Form>
             );
           }}
         
         </Formik>

         

         
       </div>
       <ContinueFooter
         percentage={0}
         id="reset-password"
       />
       
     </div>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, { resetPassword })(
  ResetPasswordForm
);