import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    successMsg: {
        textAlign: 'center',
        width: '100%',
        padding: '10px',
        fontSize: '1.2rem',
        background:'#00A130',
        border:'1px solid #00A130',
        borderRadius:'5px',
        marginTop: '40px',
        position:'relative',
        '& p':{
            color:'#fff',
            width: '90%',
        },
        '& svg':{
         marginRight:'10px',
        },
        '& span':{           
                position: 'absolute',
                right: '0px',
                top: '10px',
                cursor: 'pointer',
                color: '#fff',            
               }
        },
        ErrorMsg:{
            textAlign: 'center',
        width: '100%',
        padding: '10px',
        fontSize: '1.2rem',
        background:'red',
        border:'1px solid red',
        borderRadius:'5px',
        marginTop: '40px',
        position:'relative',
        '& p':{
            color:'#fff',
            width: '90%',
        },
        '& svg':{
         marginRight:'10px',
        },
        '& span':{           
                position: 'absolute',
                right: '0px',
                top: '10px',
                cursor: 'pointer',
                color: '#fff',            
               }
        }  
}));

export default useStyles;