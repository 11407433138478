export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_INDUSTRIES_LOADING = 'GET_INDUSTRIES_LOADING';
export const GET_INDUSTRIES_ERROR = 'GET_INDUSTRIES_ERROR';
export const GET_CASE_STUDIES = 'GET_CASE_STUDIES';
export const GET_CASE_STUDIES_ITEM = 'GET_CASE_STUDIES_ITEM';
export const GET_CASE_STUDIES_LOADING = 'GET_CASE_STUDIES_LOADING';
export const GET_CASE_STUDIES_ERROR = 'GET_CASE_STUDIES_ERROR';
export const GET_CASE_STUDY = 'GET_CASE_STUDY';
export const GET_CASE_STUDY_LOADING = 'GET_CASE_STUDY_LOADING';
export const GET_CASE_STUDY_ERROR = 'GET_CASE_STUDY_ERROR';
export const CLEAR_CASE_STUDY = 'CLEAR_CASE_STUDY';

//consultant types
export const USER_TOKEN = 'USER_TOKEN';
export const GET_ROLE_DROPDOWN = 'GET_ROLE_DROPDOWN';
export const GET_ROLE_DROPDOWN_LOADING = 'GET_ROLE_DROPDOWN_LOADING';
export const GET_FUNCTIONAL_DROPDOWN = 'GET_FUNCTIONAL_DROPDOWN';
export const GET_FUNCTIONAL_DROPDOWN_LOADING = 'GET_FUNCTIONAL_DROPDOWN_LOADING';
export const GET_INDUSTRY_DROPDOWN = 'GET_INDUSTRY_DROPDOWN';
export const GET_INDUSTRY_DROPDOWN_LOADING = 'GET_INDUSTRY_DROPDOWN_LOADING';
export const GET_LANGUAGE_DROPDOWN = 'GET_LANGUAGE_DROPDOWN';
export const GET_LANGUAGE_DROPDOWN_LOADING = 'GET_LANGUAGE_DROPDOWN_LOADING';
export const POST_CONSULTANT_REGISTER = 'POST_CONSULTANT_REGISTER';
export const POST_CONSULTANT_OTP = 'POST_CONSULTANT_OTP';
export const POST_LOADING = 'POST_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const TELL_US = 'TELL_US';
export const EXPERIENCE = 'EXPERIENCE';
export const ENGAGEMENT_MODEL = 'ENGAGEMENT_MODEL';
export const CONSULTANT_DATA = 'CONSULTANT_DATA';
export const COUNTRIES = 'COUNTRIES';
export const SET_RESET_PASSWORD_TOKEN = 'SET_RESET_PASSWORD_TOKEN';


// company onbaording
export const COMPANY_SAVE_AUTH_TOKEN = 'COMPANY_SAVE_AUTH_TOKEN';
export const POST_COMPANY_OTP = 'POST_COMPANY_OTP';
export const COMPANY_DATA = 'COMPANY_DATA';

