import React, { useMemo, useState } from "react";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import classes from "./Carrers.module.css";
import Modal from "@material-ui/core/Modal";
import API_PATH from "../../api_config.json";
import xBlack from "../../assets/img/xBlack.svg";
import Backdrop from "@material-ui/core/Backdrop";
import classesText from "../Blogs/Blog.module.css";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import * as yup from "yup";
import axios1 from "axios";
import { Formik } from "formik";
import CancelIcon from "@material-ui/icons/Cancel";

function ApplicationMail(props) {
  const [imgPreview, setImagePreView] = useState(null);
  const [fileNameError, setFileNameError] = useState("");

  const [filesId, setFilesId] = useState(null);

  const [succesMsg, setSuccesMsg] = useState("");
  const fileUpload = (e) => {
    const file = e.target.files[0];
    let ext = file.name.split(".").pop();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_platform_id", 1);
    formData.append("createdby_userid", 1);

    const response = axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/list/upload1`,
      data: formData,
    })
      .then((res) => {
        setFilesId(res.data[0].asset_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const remove = () => {
    setImagePreView(null);
  };
  const validationSchemaY = yup.object().shape({
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Message is required"),
  });

  const sendEmail = async (values) => {
    let sendData = {
      id: props.selectedRow.Id,
      message: values.message,
      pdf_asset_id: filesId,
      requestFrom: "CareerPage",
      subject: values.subject,
      user_id: localStorage.getItem("loginUserId"),
      user_platform_id: 1,
      createdby_userid: localStorage.getItem("loginUserId"),
    };

    const response = axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/list/sendEmail`,
      data: sendData,
    })
      .then((res) => {
        if (res && res.data && res.data.message) {
          setSuccesMsg(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={() => {
            props.onClose();
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={props.open}>
            <div className="toaster-wrapper" style={{ width: "40%" }}>
              <img
                src={xBlack}
                alt="roundX"
                loading="lazy"
                className="cursor-pointer"
                style={{ float: "right", padding: "20px" }}
                onClick={() => {
                  props.onClose();
                }}
              />

              {
                <div className="rounded bg-white  p-8" id="model_box">
                  <div className={classes.center}>
                    <div>
                      <Formik
                        initialValues={{
                          subject: "",
                          message: "",
                          pdf_asset_id: "",
                          id: props?.id,
                          requestFrom: props?.requestFrom,
                          //   user_id: authUser()?.user_id,
                        }}
                        validationSchema={validationSchemaY}
                        // validate={(values) => validateForm(values)}
                        onSubmit={(values) => sendEmail(values)}
                      >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  id="subject"
                                  margin="dense"
                                  type="search"
                                  autoComplete="off"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  label="Subject"
                                  name="subject"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.subject}
                                  helperText={errors.subject && touched.subject && errors.subject}
                                  onError={errors.subject && touched.subject && errors.subject}
                                  error={errors.subject && touched.subject}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  id="message"
                                  margin="dense"
                                  type="search"
                                  autoComplete="off"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  rows={4}
                                  label="Message"
                                  name="message"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message}
                                  onError={errors.message && touched.message && errors.message}
                                  error={errors.message && touched.message}
                                  helperText={errors.message && touched.message && errors.message}
                                />
                              </Grid>
                              <div className={classes.gridImage}>
                                <div htmlFor="coverImage" className={classes.coverImage}>
                                  <label htmlFor="coverImage" className={classes.coverImageLabel}>
                                    <AddCircleOutlineIcon />
                                    Upload Document
                                    {
                                      <span className={classes.errorImage} sty>
                                        {fileNameError}
                                      </span>
                                    }
                                  </label>

                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="coverImage"
                                    onChange={(e) => {
                                      setFileNameError("");
                                      let file = e.target.files[0];
                                      let ext = file.name.split(".").pop();

                                      if (["pdf", "doc", "docx", "ppt", "pptx"].includes(ext)) {
                                        setImagePreView(e.target.files[0].name);
                                        fileUpload(e);
                                      } else {
                                        setFileNameError(" Supports only  .pdf .doc .docx .ppt .pptx");
                                      }
                                    }}
                                  ></input>
                                </div>

                                <div className={classes.coverImage} style={{ position: "relative" }}>
                                  {imgPreview && <CancelIcon className={classesText.cross} onClick={remove} />}
                                  {imgPreview && <span>{imgPreview}</span>}
                                </div>
                              </div>

                              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left" }}>
                                {succesMsg && <Alert severity="success">{succesMsg}</Alert>}
                                <button className={classes.carrierButton} style={{ marginTop: "1rem" }}>
                                  Send
                                </button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              }
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default ApplicationMail;
