import React, { useState } from "react";
import classes from "./Carrers.module.css";
import loupe from "../../assets/img/loupe.png";
import RefreshIcon from "@material-ui/icons/Refresh";
import applicationClass from "./Application.module.css";

function ApplicationSearch(props) {
  const [sortBy, setSortBy] = useState({
    default: true,
    createdAt: false,
    isShotlised: false,
    years_of_experience: false,
  });
  let defaultValue = { search_term: "", from: 0, offset: 10, search_by: 1, sort_by: "createdAt", sort_order: "DESC" };
  const [sortt, setSortt] = useState(1);
  const [searchh, setSearchh] = useState("");
  const [sortt2, setSortt2] = useState("createdAt");
  const [search, setSearch] = useState(defaultValue);
  const reset = () => {
    setSortt(1);
    setSearchh("");
    setSortt2("default");
    setSearch(defaultValue);
    props.getAllList(defaultValue);
  };

  return (
    <div className={classes.search}>
      <div className={applicationClass.bg}>
        <div className={applicationClass.input}>
          <div>
            <select
              value={sortt}
              onChange={(event) => {
                setSearch({
                  ...search,
                  search_by: event.target.value,
                  search_term: "",
                });
                setSortt(event.target.value);
                setSearchh("");
                props.getAllList({ ...search });
              }}
            >
              <option value={1}>Search by</option>
              <option value={2}>Job title</option>
              <option value={3}>Job ID</option>
            </select>
          </div>
          <div className={applicationClass.searchjj}>
            <input
              type="search"
              placeholder="Search"
              className={applicationClass.searchj}
              value={searchh}
              onChange={(event) => {
                setSearchh(event.target.value);
                props.getAllList({ ...search, search_term: event.target.value });
              }}
            />
            <img src={loupe} alt="search" />
          </div>
        </div>
        <div className={applicationClass.input3}>
          <div>
            <select
              value={sortt2}
              onChange={(e) => {
                if (e.target.value === "default") {
                  setSortt2("default");
                  setSearchh("");
                  setSortt(1);
                  setSearch({ ...search, sort_by: e.target.value, sort_order: "DESC", search_term: "", search_by: 1 });
                  setSortBy({ default: true, createdAt: false, years_of_experience: false, isShotlised: false });
                }
                if (e.target.value === "createdAt") {
                  setSearchh("");
                  setSortt2(e.target.value);
                  setSortt(1);
                  setSearch({ ...search, sort_by: e.target.value, sort_order: "DESC" });
                  props.getAllList({ ...search, sort_by: e.target.value, sort_order: "DESC" });
                  setSortBy({ default: false, createdAt: true, years_of_experience: false, isShotlised: false });
                }
                if (e.target.value === "years_of_experience") {
                  setSearchh("");
                  setSortt2(e.target.value);
                  setSortt(1);
                  setSearch({ ...search, sort_by: e.target.value, sort_order: "DESC" });
                  props.getAllList({ ...search, sort_by: e.target.value, sort_order: "DESC" });

                  setSortBy({ default: false, createdAt: false, years_of_experience: true, isShotlised: false });
                }
                if (e.target.value === "isShotlised") {
                  setSearchh("");
                  setSortt2(e.target.value);
                  setSortt(1);
                  setSearch({ ...search, sort_by: e.target.value, sort_order: "ASC" });
                  props.getAllList({ ...search, sort_by: e.target.value, sort_order: "ASC" });

                  setSortBy({ default: false, createdAt: false, years_of_experience: false, isShotlised: true });
                }
              }}
            >
              <option value="default">Sort By</option>
              <option value="createdAt">Date</option>
              <option value="years_of_experience">Experience</option>
              <option value="isShotlised">Shortlisted</option>
            </select>
          </div>
          <div>
            <div>
              {sortBy.default && (
                <select>
                  <option value="default">Default</option>
                </select>
              )}
              {sortBy.createdAt && (
                <select
                  onChange={(e) => {
                    setSearch({ ...search, sort_order: e.target.value });
                    props.getAllList({ ...search, sort_order: e.target.value });
                  }}
                >
                  <option value="DESC">Newest to Oldest</option>
                  <option value="ASC">Oldest to Newest</option>
                </select>
              )}
              {sortBy.years_of_experience && (
                <select
                  onChange={(e) => {
                    setSearch({ ...search, sort_order: e.target.value });
                    props.getAllList({ ...search, sort_order: e.target.value });
                  }}
                >
                  <option value="DESC">High to Low</option>
                  <option value="ASC">Low to High </option>
                </select>
              )}
              {sortBy.isShotlised && (
                <select
                  onChange={(e) => {
                    setSearch({ ...search, sort_order: e.target.value });
                    props.getAllList({ ...search, sort_order: e.target.value });
                  }}
                >
                  <option value="ASC">Accept</option>
                  <option value="DESC">Reject </option>
                </select>
              )}
            </div>
          </div>
          <div onClick={reset} className={applicationClass.resetGrid}>
            <RefreshIcon />
            <button>Reset</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationSearch;
