import React, { useMemo, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import roundX from "../../assets/img/roundX.svg";
import xBlack from "../../assets/img/xBlack.svg";
import classes from "./Carrers.module.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { PDFViewer } from "react-view-pdf";
// import PDFViewer from "pdf-viewer-reactjs";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
function ApplicationResume(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const pdfUrl = useMemo(() => ({ url: props?.selectedRow ? props?.selectedRow : "file" }));
  return (
    <div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={() => {
            props.onClose();
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={props.open}>
            <div className="toaster-wrapper" style={{ width: "50%" }}>
              <img
                src={xBlack}
                alt="roundX"
                loading="lazy"
                className="cursor-pointer"
                style={{ float: "right", padding: "20px" }}
                onClick={() => {
                  props.onClose();
                }}
              />

              {
                <div className="rounded bg-white  p-8" id="model_box">
                  <div className={classes.resumeButton}>
                    <button
                      className={classes.resumeBtnAccept}
                      onClick={() => {
                        let data = props.changeStatusResume(props.data.Id, 2);
                        if (data) {
                          props.onClose();
                        }
                      }}
                    >
                      Accept
                    </button>{" "}
                    &nbsp;
                    <button
                      className={classes.resumeBtnReject}
                      onClick={() => {
                        let data = props.changeStatusResume(props.data.Id, 3);
                        if (data) {
                          props.onClose();
                        }
                      }}
                    >
                      Reject
                    </button>
                  </div>
                  <div className={`${classes.center} ${classes.center3}`}>
                    {props?.selectedRow && <PDFViewer url={props?.selectedRow} className={classes.pdff} />}
                  </div>
                </div>
              }
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default ApplicationResume;
