import React, { useState } from 'react';
import logo from '../../../../assets/img/regLogo.svg';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import CustomRates from '../../components/CustomRates';
import CustomDropdown from '../../components/CustomDropdown';
import downArrow from '../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../assets/img/textUpArrow.svg';
import ContinueFooter from '../../components/ContinueFooter';
import ProfileCompleteModal from '../SignUpStepThree/SignUpThreeForm/ProfileCompleteModal';
import { saveProfileData } from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import CustomTextInput from '../../components/CustomTextInput';


function EngagementForm({ consultant, saveProfileData }) {
  const [employmentType, setEmploymentType] = useState('');
  const [visible, setVisible] = useState(false);
  const [preference, setPreference] = useState('');

   const history = useHistory();
   const alert = useAlert();

  const initialValues = {
    daily: '',
    hourly: '',
    monthly: '',
    engagement:'',
  };

  const validationSchema = yup.object().shape({
    daily: yup.number(),
    hourly: yup.number(),
    monthly: yup.number(),
    engagement: yup.string().required('Location required'),
  });

  const handleSubmit = (formValues) => {
    const profileData = {
      ...formValues,
      ...consultant.tell_us,
      ...consultant.experience,
      preference: preference,
    };
    // console.log({ profileData });

    saveProfileData(profileData, consultant.verifyOtpToken, (res)=>{
          if (Object.keys(res).length > 0) {
            alert.error(res.error);
          } else {
           setVisible(true);
          }
      
    });

    //  history.push('/freelancer/sign-up/step-three');
  };

   const employmentTypeItems = [
     { item: 'Remote', value:3 },
     { item: 'Full Time', value: 1 },
     { item: 'Part Time', value: 2},
   ];

  return (
    <div className="h-screen lg:w-3/5  relative w-full overflow-y-scroll hideScroll">
      <ProfileCompleteModal
        visible={visible}
        setVisible={(val) => {
          setVisible(val);
        }}
        to={'/profile'}
      />
      <div className=" flex flex-col justify-center md:px-16 xl:px-24 px-8 pb-28 lg:pb-40">
        <div className="flex items-center mx-auto mt-12">
          <img src={logo} alt="logo" className="" />
          <p className="text-base ml-2 text-black ">StrategyConnect</p>
        </div>

        <h1 className="text-3xl text-center mt-9 waitBorderBottom">
          Your engagement model
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form id="engagementModel" onSubmit={props.handleSubmit}>
                <CustomDropdown
                  items={employmentTypeItems}
                  placeholder="Engagement preference"
                  id="engagement"
                  width="w-full"
                  onValueChange={(val) => {
                    setPreference(val);
                  }}
                  downArrow={downArrow}
                  upArrow={upArrow}
                />

                <CustomTextInput
                  name="engagement"
                  id="engagement"
                  type="text"
                  placeholder="City *"
                />

                <CustomRates daily="daily" hourly="hourly" monthly="monthly" />
              </Form>
            );
          }}
        </Formik>
      </div> 
      <ContinueFooter
        goBack={() => {
          history.push('/freelancer/sign-up/step-five');
        }}
        id="engagementModel"
        percentage={90}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData,
})(EngagementForm);
