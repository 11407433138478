import React, { useState } from "react";
import classes from "./Blog.module.css";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

function CareersList(props) {
  let history = useHistory();
  const [categorys, setCategorys] = useState(1);
  const category = (e) => {
    if (document.getElementById("allstepr")) {
      let allButton = document.getElementById("allstepr").childNodes;
      allButton.forEach(function (items) {
        if (items.id == e.currentTarget.id) {
          return document.getElementById(items.id).classList.add(classes.active);
        } else {
          return document.getElementById(items.id).classList.remove(classes.active);
        }
      });
    }
    // if (e.currentTarget.id == "All") {
    //   props.getCaseStudies("", 0, 1);
    // } else {
    //   props.getAllBlogsByCategory(e.currentTarget.id);
    // }
  };
  return (
    <>
      <div className={classes.steper} id="allstepr">
        <button
          className={classes.active}
          id="All"
          onClick={(e) => {
            category(e);
            props.getCaseStudies("", 0, 1);
            setCategorys(1);
          }}
        >
          All
        </button>
        <button
          id="Business"
          onClick={(e) => {
            category(e);
            props.getCaseStudies("", 0, 2);
            setCategorys(2);
          }}
        >
          Business
        </button>
        <button
          id="Finance"
          onClick={(e) => {
            category(e);
            props.getCaseStudies("", 0, 3);
            setCategorys(3);
          }}
        >
          Finance
        </button>
        <button
          id="Strategy"
          onClick={(e) => {
            category(e);
            props.getCaseStudies("", 0, 4);
            setCategorys(4);
          }}
        >
          Strategy
        </button>
        <button
          id="Marketing"
          onClick={(e) => {
            category(e);
            props.getCaseStudies("", 0, 5);
            setCategorys(5);
          }}
        >
          Marketing
        </button>
      </div>
      <div className={classes.list}>
        {props.blogs.length > 0 ? (
          props.blogs.map((val) => {
            let updatedate = "";
            if (val.updatedAt) {
              let temp_date = new Date(val.updatedAt);
              let year = temp_date.getFullYear();
              const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              let month = months[temp_date.getMonth()];
              let day = temp_date.getDate();
              updatedate = `${day} ${month} ${year}`;
            }
            return (
              <div
                className={classes.box}
                onClick={() => {
                  history.push({
                    pathname: `/blogs/details${val.Id}`,
                    state: val,
                  });
                }}
              >
                <img src={val.signed_url} style={{ height: "200px", objectFit: "cover", width: "100%" }} />
                <h4>{val.title}</h4>
                <div className={classes.blogDetails}>
                  <div>Publisher</div>
                  <span>{val.published_by}</span>
                  <div>Date</div>
                  <span>{updatedate}</span>
                </div>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
        {props?.blogs?.length <= 0 && <div className={classes.noData}>No Data Available</div>}
      </div>
      {props.count > 0 && (
        <ReactPaginate
          previousLabel={"<"}
          previousClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          disabledClassName={"border py-1 px-2 text-gray-300 text-sm rounded border-gray-300"}
          nextClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={props.count}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            props.getCaseStudies("", e.selected * 10, categorys);
          }}
          containerClassName={
            " justify-between pagginaton_react pt-10 items-center w-full pr-12 mobile-pagination lg:flex my-8"
          }
          subContainerClassName={"pages pagination"}
          activeClassName={"text-white text-sm font-bold px-2 py-1 purple rounded focus:outline-none"}
        />
      )}
    </>
  );
}

export default CareersList;
