import React from "react";
import "./App.css";
import Xaas from "./components/Xaas";
import Home from "./components/Home";
import Profile from "./pages/Profile";
import About from "./components/About";
import Contact from "./components/Contact";
import Clients from "./components/Clients";
import ComingSoon from "./pages/ComingSoon";
import CaseStudy from "./components/CaseStudy";
import Onboarding from "./pages/auth/Onboarding";
import Freelancer from "./pages/auth/Freelancer";
import MarketPlace from "./components/MarketPlace";
import CompanyProfile from "./pages/CompanyProfile";
import MainApproach from "./components/MainApproach";
import BusinessPlan from "./components/BusinessPlan";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermAndCondition from "./components/TermAndCondition";
import BussinessStrategy from "./components/BusinessPlan/BussinessStrategy";
import Careers from "./components/Careers/Careers";
import CareerDetails from "./components/Careers/CareerDetails";
import CareersCreate from "./components/Careers/CareersCreate";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import Blogs from "./components/Blogs/Blogs";
import BlogCreate from "./components/Blogs/BlogCreate";
import BlogDetails from "./components/Blogs/BlogDetails";
import LoginForm from "./pages/auth/Freelancer/SignUpStepOne/Login/LoginForm";
import Login from "./pages/auth/Freelancer/SignUpStepOne/Login";
import ApplicationView from "./components/Careers/ApplicationView";
// import { PrivateRoute } from './PrivateRoute';

function App() {
  return (
    <BrowserRouter>
      <Route exact path={process.env.PUBLIC_URL + "/"} component={Home} /> {/* done */}
      <Route path={process.env.PUBLIC_URL + "/about-us"} component={About} /> {/* done */}
      <Route path={process.env.PUBLIC_URL + "/contact"} component={Contact} />
      <Route path={process.env.PUBLIC_URL + "/marketplace"} component={MarketPlace} />
      <Route exact path={process.env.PUBLIC_URL + "/strategy-services"} component={BusinessPlan} /> {/* done */}
      <Route exact path={process.env.PUBLIC_URL + "/strategy-services/:id"} component={BussinessStrategy} />{" "}
      <Route exact path={process.env.PUBLIC_URL + "/case-studies/:id"} component={Clients} /> {/* done */}
      <Route exact path={process.env.PUBLIC_URL + "/case-studies"} component={CaseStudy} /> {/* done */}
      <Route path={process.env.PUBLIC_URL + "/xaas"} component={Xaas} />
      <Route path={process.env.PUBLIC_URL + "/our-approach"} component={MainApproach} />
      <Route path={process.env.PUBLIC_URL + "/freelancer"} component={Freelancer} />
      <Route path={process.env.PUBLIC_URL + "/onboarding"} component={Onboarding} />
      <Route path={process.env.PUBLIC_URL + "/coming-soon"} component={ComingSoon} />
      <Route path={process.env.PUBLIC_URL + "/profile"} component={Profile} />
      <Route path={process.env.PUBLIC_URL + "/company/profile"} component={CompanyProfile} />
      <Route path={process.env.PUBLIC_URL + "/privacy-policy"} component={PrivacyPolicy} />
      <Route path={process.env.PUBLIC_URL + "/terms-conditions"} component={TermAndCondition} />
      <Route exact path={process.env.PUBLIC_URL + "/blogs"} component={Blogs} />
      <Route exact path={process.env.PUBLIC_URL + "/blogs/create"} component={BlogCreate} />
      <Route exact path={process.env.PUBLIC_URL + "/blogs/details:id"} component={BlogDetails} />
      <Route exact path={process.env.PUBLIC_URL + "/careers"} component={Careers} />
      <Route exact path={process.env.PUBLIC_URL + "/careers/create"} component={CareersCreate} />
      <Route exact path={process.env.PUBLIC_URL + "/careers/details"} component={CareerDetails} />
      <Route exact path={process.env.PUBLIC_URL + "/login"} component={Login} />
      <Route exact path={process.env.PUBLIC_URL + "/careers/view-application"} component={ApplicationView} />
      {/* /careers/view-application */}
      {/* <Switch>
      <PrivateRoute exact path={process.env.PUBLIC_URL + "/profile"} component={Profile} />
      <PrivateRoute exact path={process.env.PUBLIC_URL + "/company/profile"} component={CompanyProfile} />
      </Switch> */}
    </BrowserRouter>
  );
}

export default App;
