import React, { useState, useRef, useEffect,useLayoutEffect } from 'react';
import { Form, Formik } from 'formik';
import close from '../../../../assets/img/cancel.svg';
// import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import logo from '../../../../assets/img/regLogo.svg';
import zazzy from '../../../../assets/img/zazzy.svg';
import CustomTextInput from '../../components/CustomTextInput';
// linkedIn icons
import linkActive from '../../../../assets/img/linkActive.svg';
import linkInActive from '../../../../assets/img/linkInactive.svg';
import linkError from '../../../../assets/img/linkErr.svg';
//twitter icons
import twitActive from '../../../../assets/img/twitActive.svg';
import twitInActive from '../../../../assets/img/twitInactive.svg';
import twitError from '../../../../assets/img/twitError.svg';

import LogoUpload from '../../Freelancer/SignUpStepThree/SignUpThreeForm/LogoUpload';
import LanguagesComponent from '../../Freelancer/SignUpStepTwo/LanguagesComponent';
import CustomTextArea from '../../components/CustomTextArea';
import CustomIndustryList from './CustomIndustryList';
import ProfileCompleteModal from '../../Freelancer/SignUpStepThree/SignUpThreeForm/ProfileCompleteModal';
import CompanyContinue from '../CompanyContinue';
import {
  getIndustryList,
} from '../../../../state/actions/consultantAction';
import { postCompanyData } from '../../../../state/actions/companyAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';






 function CompanyFormThree(props) {
   const [dimensions, setDimensions] = useState({
     width: window.innerWidth,
     height: window.innerHeight,
   });
   const [visible, setVisible] = useState(false);
   const [myLogo, setMyLogo] = useState(zazzy);
   const { setPage } = props;
   const percentage = 95;
   const alert = useAlert();

   const { industry_dropdown } = props.consultant;
   const { getIndustryList, postCompanyData, company } = props;

   useLayoutEffect(() => {
     getIndustryList((res) => {
       if (Object.keys(res).length > 0) {
         alert.error(res.error);
       }
     });
   }, []);

   useEffect(() => {
     function handleResize() {
       setDimensions({
         width: window.innerWidth,
         height: window.innerHeight,
       });
     }

     window.addEventListener('resize', handleResize);

     return (_) => {
       window.removeEventListener('resize', handleResize);
     };
   });

   const [industryList, setIndustryList] = useState([]);
   const [functionalList, setFunctionalList] = useState([]);
   const copyIndustryList = [...industryList];
   const copyfuncArray = [...functionalList];

   const initialValues = {
     industies: '',
     location: '',
     website: '',
     about: '',
     twitter: '',
     linkedIn: '',
   };

   const durationValues = [
     { item: '5 - 15', value: '5 - 15' },
     { item: '20 - 35', value: '20 - 35' },
     { item: '50 +', value: '50 +' },
   ];

   const validationSchema = yup.object().shape({
     location: yup.string().required('Location is required'),
     about: yup
       .string()
       .min(5, 'minimum of 5 characters')
       .required('about is required'),
     website: yup
       .string()
       .url('valid url required')
       .required('website is required'),
     twitter: yup.string().url('valid url required'),
     linkedIn: yup.string().url('valid url required'),
   });

   const handleClick = (event) => {
     hiddenFileInput.current.click();
   };
   const handleChange = (event) => {
     const fileUploaded = event.target.files[0];
     let reader = new FileReader();
     reader.readAsDataURL(fileUploaded);
     reader.onload = () => {
       // console.log(reader.result);
       setMyLogo(reader.result);
     };
   };

   const hiddenFileInput = useRef(null);

   const handleSubmit = (formValues) => {
     formValues.companyIndustry = industryList;
     formValues.functionalExpertise = functionalList;
     console.log({ formValues });
     postCompanyData(formValues, company.companyOtpToken, (res) => {
       if (Object.keys(res).length > 0) {
         alert.error(res.error);
       } else {
         // setPage(2, submitObj);
         setVisible(true);
       }
     });
   };

   return (
     <div
       style={{
         height:
           dimensions.width < 780 ? 680 : dimensions.width < 1300 ? 590 : 680,
       }}
       className=" relative lg:w-3/6 w-full overflow-y-scroll hideScroll"
     >
       <ProfileCompleteModal
         visible={visible}
         to={'/company/profile'}
         setVisible={(val) => {
           setVisible(val);
         }}
       />
       <img
         src={close}
         alt=""
         loading="lazy"
         className="absolute top-0 right-0 pr-8 cursor-pointer pt-8"
         onClick={() => {
           props.setVisible(false);
         }}
       />
       <div className=" flex flex-col justify-center lg:px-12 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9">
           Tell us about your company
         </h1>

         <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="company-three-data" onSubmit={props.handleSubmit}>
                 <LogoUpload
                   handleClick={handleClick}
                   hiddenFileInput={hiddenFileInput}
                   handleChange={handleChange}
                   logo={myLogo}
                 />

                 <CustomIndustryList
                   setArrList={(valOne) => {
                     setIndustryList([...industryList, { lang: valOne }]);
                   }}
                   languagesValues={industry_dropdown}
                   deleteFromArrayList={(valId) => {
                     if (valId > -1) {
                       copyIndustryList.splice(valId, 1);
                       setIndustryList([...copyIndustryList]);
                     }
                   }}
                   langArrayList={industryList}
                   id="industries"
                 />
                 <LanguagesComponent
                   itemOnePlaceholder="Select Expertise"
                   itemTwoPlaceholder="Team size"
                   languagesValues={industry_dropdown}
                   fluencyValues={durationValues}
                   langArrayList={functionalList}
                   deleteFromArrayList={(valId) => {
                     if (valId > -1) {
                       copyfuncArray.splice(valId, 1);
                       setFunctionalList([...copyfuncArray]);
                     }
                   }}
                   setArrList={(valOne, valTwo) => {
                     setFunctionalList([
                       ...functionalList,
                       {
                         lang: valOne,
                         fluency: valTwo,
                         ex:
                           valTwo == '5 - 15'
                             ? 1
                             : valTwo == '20 - 35'
                             ? 2
                             : valTwo == '50 +'
                             ? 3
                             : null,
                       },
                     ]);
                   }}
                   placeholder="Functional Expertise"
                 />
                 
                 <CustomTextInput
                   width="w-full"
                   name="location"
                   id="location"
                   type="text"
                   placeholder="Location *"
                 />
                 <CustomTextInput
                   name="website"
                   id="website"
                   type="text"
                   placeholder="Company Website link *"
                 />
                 <CustomTextArea
                   rows="3"
                   name="about"
                   id="about"
                   type="text"
                   placeholder="About *"
                 />
                 <CustomTextInput
                   name="twitter"
                   id="twitter"
                   type="text"
                   placeholder="Twitter profile URL"
                   activeIcon={twitActive}
                   inactiveIcon={twitInActive}
                   errorIcon={twitError}
                 />
                 <CustomTextInput
                   name="linkedIn"
                   id="linkedIn"
                   type="text"
                   placeholder="Linkedin profile URL *"
                   activeIcon={linkActive}
                   inactiveIcon={linkInActive}
                   errorIcon={linkError}
                 />
               </Form>
             );
           }}
         </Formik>
       </div>
       <CompanyContinue
         goBack={() => {
           setPage(2);
         }}
         percentage={percentage}
         id="company-three-data"
       />
     </div>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
  company:state.company,
});

export default connect(mapStateToProps, {
  getIndustryList,
  postCompanyData,
})(CompanyFormThree);
