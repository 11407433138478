import React, { useEffect } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import LatestUpdates from "../Home/SmallHome/components/Footer/LatestUpdates";
import Engagement from "./Engagement";
import Address from "./Address";
import { Helmet } from "react-helmet";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Contact us to empower your businesses and prepare them for scalable success "
        />
        <title>Contact Us | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <h1 className="lg:text-3xl text-xl text-center mt-20 lg:px-48 px-4">Get in touch with us</h1>
      <p className="text-sm grey-text text-center mt-2 lg:px-48 px-4">Let’s scale your brand, together</p>
      <Engagement />
      <Address />
      <LatestUpdates />
      <Footer />
    </div>
  );
}

export default Contact;
