import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import logo from '../../../../../assets/img/regLogo.svg';
import inIcon from '../../../../../assets/img/inUser.svg';
import actIcon from '../../../../../assets/img/actUser.svg';
import errIcon from '../../../../../assets/img/errUser.svg';
import ContinueFooter from '../../../components/ContinueFooter';
// // messgae icons
import messActive from '../../../../../assets/img/messActive.svg';
import messInActive from '../../../../../assets/img/messInActive.svg';
import messError from '../../../../../assets/img/messError.svg';
// // phone call icons
import phoneActive from '../../../../../assets/img/phone-callInact.svg';
import phoneInActive from '../../../../../assets/img/phone-callAct.svg';
import phoneError from '../../../../../assets/img/phone-callErr.svg';
// //arrow icons
import downArrow from '../../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../../assets/img/textUpArrow.svg';
import CustomDropdown from '../../../components/CustomDropdown';
import CustomTextInput from '../../../components/CustomTextInput';
import { postRegisterData } from '../../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import Searchable from 'react-searchable-dropdown';


const percentage = 33.3;

function WaitForm({ consultant, postRegisterData }) {
  const [myJoinAs, setMyJoinAs] = useState('');
  const [conditions, setConditions] = useState(false);

  
  const joinDropDown = () => {
    let joinArray = [];
      consultant.role_dropdown.map((item) => {
        joinArray.push({ value: item.item, label: item.value });
      });
      return joinArray;
  }


  let history = useHistory();
  const alert = useAlert();

  const initialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
  };
 
  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is Required'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
  });

  const handleSubmit = (formValues) => {
    console.log(formValues);
    if(!conditions){
      alert.error('Must accept terms and conditions');
    }else{
formValues.joinAs = myJoinAs;
const formData = {
  name: formValues.fullName,
  email: formValues.email,
  phone: formValues.phoneNumber,
  role: formValues.joinAs,
};

localStorage.setItem('registerFormValue', JSON.stringify(formData));

postRegisterData(formData, (res) => {
  if (Object.keys(res).length > 0) {
    alert.error(res.error);
  } else {
    history.push({
      pathname: '/freelancer/sign-up/step-two',
      state: { data: formData },
    });
  }
});
    }
  };


  return (
    <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
      <div className=" flex flex-col justify-center md:px-16 xl:px-24 px-8 pb-28 lg:pb-32">
        <div className="flex items-center mx-auto mt-12">
          <img src={logo} alt="logo" className="" />
          <p className="text-base ml-2 text-black">StrategyConnect</p>
        </div>

        <h1 className="text-3xl text-center mt-9 waitBorderBottom">
          Join the wait list
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form id="wait-list" onSubmit={props.handleSubmit}>
                <div className="mt-10">
                  {/* <CustomDropdown
                    items={consultant.role_dropdown}
                    placeholder="Join as *"
                    id="joinAs"
                    width="w-full"
                    onValueChange={(val) => {
                      setMyJoinAs(val);
                    }}
                    downArrow={downArrow}
                    upArrow={upArrow}
                  /> */}
 
                  <Searchable
                    value=""
                    placeholder="Join as *" // by default "Search"
                    notFoundText="No result found" // by default "No result found"
                    options={joinDropDown()}
                    onSelect={(value) => {
                      setMyJoinAs(value);
                    }}
                    listMaxHeight={200} //by default 140
                  />

                  <CustomTextInput
                    name="fullName"
                    id="fullName"
                    type="text"
                    placeholder="Full Name *"
                    activeIcon={actIcon}
                    inactiveIcon={inIcon}
                    errorIcon={errIcon}
                  />

                  <CustomTextInput
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Email address*"
                    activeIcon={messActive}
                    inactiveIcon={messInActive}
                    errorIcon={messError}
                  />

                  <CustomTextInput
                    phone={true}
                    name="phoneNumber"
                    id="phoneNumber" 
                    type="text"
                    placeholder="Phone Number *"
                    activeIcon={phoneActive}
                    inactiveIcon={phoneInActive}
                    errorIcon={phoneError}
                  />
                </div>

                <p
                  style={{ color: 'rgba(112, 115, 118, 0.65)' }}
                  className="text-sm mt-7 font-thin"
                >
                  <span style={{ color: '#707376' }} className="font-bold">
                    Disclaimer:
                  </span>{' '}
                  I acknowledge that any information, application process,
                  screening details, expert network, client information is
                  strictly private &amp; confidential.
                </p>

                <label
                  style={{ color: '#38369A' }}
                  className="checkbox mt-6 cursor-pointer"
                >
                  <span className="checkbox__input">
                    <input checked={conditions} onChange={() => setConditions(!conditions)} type="checkbox" name="checkbox" />
                    <span className="checkbox__control">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                          d="M1.73 12.91l6.37 6.37L22.79 4.59"
                        />
                      </svg>
                    </span>
                  </span>
                  <span onClick={()=>{setConditions(!conditions)}} className="radio__label ">
                    I acceept the terms and conditions
                  </span>
                </label>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ContinueFooter
        
        percentage={percentage}
        id="wait-list"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  // consultant: state.consultant,
});

export default connect(mapStateToProps, { postRegisterData})(WaitForm);


 