import React, { useState, useEffect } from 'react';
import close from '../../../../assets/img/cancel.svg';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-awesome-modal';
import {saveProfileData} from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import LanguagesComponent from '../../../auth/Freelancer/SignUpStepTwo/LanguagesComponent';
import { useAlert } from 'react-alert';
import LoadingOverlay from 'react-loading-overlay';

function AddLanguage(props) {
  const alert = useAlert();
  const { saveProfileData } = props;
  const { laguage_dropdown, consultantProfile,loading } = props.consultant;
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [languageList, setLanguageList] = useState(
    !consultantProfile.languageList ? [] : consultantProfile.languageList
  );
  const copyLanguageArray = [...languageList];

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const smallHeight = dimensions.height;

  const fluencyValues = [
    { item: 'Basic', value: 'Basic' },
    { item: 'Professional', value: 'Professional' },
    { item: 'Native', value: 'Native' },
  ];

  const initialValues = {

  };

  const validationSchema = yup.object().shape({
  
  });

  
  const onSubmit = () =>{
      
      const authId = localStorage.getItem('authId');
      const dataObj = { languageList };
      saveProfileData(dataObj, authId, (res) => {
        if (Object.keys(res).length > 0) {
          alert.error(res.error);
        } else {
          props.setVisible(false);
        }
      });
  }

   const handleSubmit = () => {
     
   };
  
 
  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width == 1200
          ? '45%'
          : dimensions.width == 1024
          ? '50%'
          : '40%'
      }
      onClickAway={() => props.setVisible(false)}
    >
      <LoadingOverlay active={loading} spinner text=" ">
        <div
          style={{
            height: dimensions.width < 780 ? smallHeight : 'auto',
            width: dimensions.width < 780 ? dimensions.width : 'auto',
          }}
          className="rounded-lg bg-white overflow-y-scroll hideScroll"
        >
          <div
            style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
            className="flex items-center justify-between p-8"
          >
            <p className="text-xl">ADD LANGUAGES</p>
            <img
              src={close}
              alt=""
              onClick={() => {
                props.setVisible(false);
              }}
              className="cursor-pointer"
            />
          </div>
          {/* main add content */}
          <div className="lg:px-20 px-10 mt-5 lg:mt-10 ">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                return (
                  <Form onSubmit={props.handleSubmit}>
                    <LanguagesComponent
                      itemOnePlaceholder="Select Language"
                      itemTwoPlaceholder="Fluency"
                      languagesValues={laguage_dropdown}
                      fluencyValues={fluencyValues}
                      langArrayList={languageList}
                      deleteFromArrayList={(valId) => {
                        if (valId > -1) {
                          copyLanguageArray.splice(valId, 1);
                          setLanguageList([...copyLanguageArray]);
                        }
                      }}
                      setArrList={(valOne, valTwo) => {
                        setLanguageList([
                          ...languageList,
                          { lang: valOne, fluency: valTwo },
                        ]);
                      }}
                      placeholder="Languages *"
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          {/* main add content */}

          <div
            className="px-10 py-5 flex  mt-10 justify-end"
            style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
          >
            <button
              onClick={() => {
                props.setVisible(false);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              form="add-language"
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Save Changes
            </button>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData,
})(AddLanguage);
