import React,{useState, useLayoutEffect} from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import ContinueFooter from '../../components/ContinueFooter';
import logo from '../../../../assets/img/regLogo.svg';
import ExperienceTextBox from '../SignUpStepThree/SignUpThreeForm/ExperienceTextbox';
import LanguagesComponent from '../SignUpStepTwo/LanguagesComponent';
import CustomEmployerList from '../../components/CustomEmployerList';
import { useHistory } from 'react-router-dom';
import {
  getFunctionalList,
  getLanguageList,
  getIndustryList,
  setExperienceData,
} from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';

function OnBoardExpForm({ consultant,setExperienceData, getFunctionalList,getIndustryList,getLanguageList }) {
  const {
    industry_dropdown,
    functional_dropdown,
    laguage_dropdown,
  } = consultant;

  const alert = useAlert();

  useLayoutEffect(()=>{
    getFunctionalList((res)=>{
      if(Object.keys(res).length > 0){
        alert.error(res.error)
      }
    });
    getIndustryList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
    getLanguageList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
  },[])

  const [industryList, setIndustryList] = useState([]);
  const [functionalList, setFunctionalList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [employList, setEmployList] = useState([]);
  const [years, setyears] = useState('');

   const copyArray = [...industryList];
   const copyfuncArray = [...functionalList];
   const copyLanguageArray = [...languageList];
   const copyEmployArray = [...employList];


  const history = useHistory();


  const initialValues = {
    company: '',
  };

  const validationSchema = yup.object().shape({
    company: yup.string(),
  });

  const durationValues = [
    { item: '< 1', value: '< 1' },
    { item: '1', value: '1' },
    { item: '2 ', value: '2' },
    { item: '3', value: '3' },
    { item: '4', value: '4' },
    { item: '5', value: '5' },
    { item: '6', value: '6' },
    { item: '7', value: '7' },
    { item: '8', value: '8' },
    { item: '9', value: '9' },
    { item: '10 >', value: '10 >' },
    // { item: '1 - 6 months', value: '1 - 6 months' },
    // { item: '6 months - 1 year', value: '6 months - 1 year' },
    // { item: '1 year +', value: '1 year +' },
    // { item: '2 years +', value: '2 years +' },
  ];

  const fluencyValues = [
    { item: 'Basic', value: 'Basic' },
    { item: 'Professional', value: 'Professional' },
    { item: 'Native', value: 'Native' },
  ];

  const handleSubmit = (formValues) => {
   
    if(industryList.length == 0 || functionalList.length == 0 || employList.length == 0 || languageList.length == 0 || years.length == 0){
      alert.error('all fields must have values');
    }else{
      formValues.industryList = industryList;
      formValues.functionalList = functionalList;
      formValues.languageList = languageList;
      formValues.employList = employList;
      formValues.years = years;
  
      setExperienceData(formValues,(res)=>{
         if (Object.keys(res).length > 0) {
           alert.error(res.error);
         } else {
           history.push('/freelancer/sign-up/step-six');
         }
        
      });
    }
    
    
  };
  return (
    <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
      <div className=" flex flex-col justify-center md:px-16 xl:px-24 px-8 pb-28 lg:pb-32">
        <div className="flex items-center mx-auto mt-12">
          <img src={logo} alt="logo" className="" />
          <p className="text-base ml-2 text-black">StrategyConnect</p>
        </div>

        <h1 className="text-3xl text-center mt-9 waitBorderBottom">
          Add your experience
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form id="onBoardExp" onSubmit={props.handleSubmit}>
                <ExperienceTextBox setValue={(val)=>{setyears(val)}} /> 
                <LanguagesComponent
                  itemOnePlaceholder="Select Industry"
                  itemTwoPlaceholder="Duration in years"
                  languagesValues={industry_dropdown}
                  fluencyValues={durationValues}
                  langArrayList={industryList} 
                  deleteFromArrayList={(valId) => {
                    if (valId > -1) {
                      copyArray.splice(valId, 1);
                      setIndustryList([...copyArray]);
                    }
                  }}
                  setArrList={(valOne, valTwo) => {
                    setIndustryList([
                      ...industryList,
                      {
                        lang: valOne,
                        fluency: valTwo,
                        ex:
                          valTwo == '< 1'
                            ? 1
                            : Number(valTwo) == 1
                            ? 1
                            : Number(valTwo) > 1 && Number(valTwo) <= 3
                            ? 1
                            : Number(valTwo) > 3 && Number(valTwo) <= 9
                            ? 2
                            : Number(valTwo) == '10 >'
                            ? 3
                            : 0,
                        // valTwo == '1'
                        //   ? 1
                        //   : valTwo == '6 months - 1 year'
                        //   ? 1
                        //   : valTwo == '1 year +'
                        //   ? 1
                        //   : valTwo == '10 >'
                        //   ? 3
                        //   : null,
                      },
                    ]);
                  }}
                  placeholder="Industry focus *"
                /> 
                <LanguagesComponent
                  itemOnePlaceholder="Select capabilities"
                  itemTwoPlaceholder="Duration"
                  languagesValues={functional_dropdown}
                  fluencyValues={durationValues}
                  langArrayList={functionalList}
                  deleteFromArrayList={(valId) => {
                    if (valId > -1) {
                      copyfuncArray.splice(valId, 1);
                      setFunctionalList([...copyfuncArray]);
                    }
                  }}
                  setArrList={(valOne, valTwo) => {
                    setFunctionalList([
                      ...functionalList,
                      {
                        lang: valOne,
                        fluency: valTwo,
                        ex:
                       valTwo == '< 1'
                            ? 1
                            : Number(valTwo) == 1
                            ? 1
                            : Number(valTwo) > 1 && Number(valTwo) <= 3
                            ? 1
                            : Number(valTwo) > 3 && Number(valTwo) <= 9
                            ? 2
                            : Number(valTwo) == '10 >'
                            ? 3
                            : 0, 
                      },
                    ]);
                  }}
                  placeholder="Functional capabilities *"
                />

                <CustomEmployerList
                  name="company"
                  value={props.values.company}
                  langArrayList={employList}
                  deleteFromArrayList={(valId) => {
                    if (valId > -1) {
                      copyEmployArray.splice(valId, 1);
                      setEmployList([...copyEmployArray]);
                    }
                  }}
                  setArrList={(valOne) => {
                    setEmployList([
                      ...employList,
                      { lang: valOne},
                    ]);
                  }}
                />

                <LanguagesComponent
                  itemOnePlaceholder="Select Language"
                  itemTwoPlaceholder="Fluency"
                  languagesValues={laguage_dropdown}
                  fluencyValues={fluencyValues}
                  langArrayList={languageList}
                  deleteFromArrayList={(valId) => {
                    if (valId > -1) {
                      copyLanguageArray.splice(valId, 1);
                      setLanguageList([...copyLanguageArray]);
                    }
                  }}
                  setArrList={(valOne, valTwo) => {
                    setLanguageList([
                      ...languageList,
                      { lang: valOne, fluency: valTwo },
                    ]);
                  }}
                  placeholder="Languages *"
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <ContinueFooter
        goBack={() => {
          history.push('/freelancer/sign-up/step-four');
        }}
        percentage={55.5}
        id="onBoardExp"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {setExperienceData, getIndustryList, getLanguageList,getFunctionalList })(OnBoardExpForm);
