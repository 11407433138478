import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import Axios from "axios";
import { Formik } from "formik";
import ModalResponse from "./ModalResponse";
import classes from "./Carrers.module.css";
import API_PATH from "../../api_config.json";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function CareersCreate() {
  let location = useLocation();
  let history = useHistory();
  let initialValue = [
    {
      question: "First Name",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "Last Name",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "LinkedIn URL",
      values: [""],
      isRequired: false,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "Email ID",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },

    {
      question: "Country Code",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "Phone Number",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "Location",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "Years of Experience",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "text",
      isNew: 0,
    },
    {
      question: "How would you rate yourself",
      values: ["Beginner", "Intermediate", "Advanced"],
      isRequired: false,
      isEnabled: true,
      question_type: "radio",
      isNew: 0,
    },
    {
      question: "Resume",
      values: [""],
      isRequired: true,
      isEnabled: true,
      question_type: "file",
      isNew: 0,
    },
  ];

  const editorRef = useRef(null);
  const [message, setMsg] = useState("");
  const [visible, setVisible] = useState(false);

  const [addOption, setAddOption] = React.useState(false);
  const [addOptionArr, setAddOptionArr] = React.useState([]);
  const [personalDetails, setPersonalDetails] = useState(initialValue);
  const [JobDescriptionError, setJobDescriptionError] = useState();
  const [responsibilityError, setResponsibilityError] = useState();

  const handleChangeEnabled = (event) => {
    // let copy = [...personalDetails];
    // let objIndex = copy.findIndex((obj) => obj.question == event.target.name);
    // copy[objIndex].isEnabled = event.target.checked;
    // setPersonalDetails(copy);
  };
  const handleChangeRequired = (event) => {
    let copy = [...personalDetails];
    let objIndex = copy.findIndex((obj) => obj.question == event.target.name);
    copy[objIndex].isRequired = event.target.checked;
    setPersonalDetails(copy);
  };

  const sendData = async (values) => {
    if (!values.jobDescription) {
      setJobDescriptionError("Job description  is required");
      return false;
    }
    if (!values.responsibility) {
      setResponsibilityError("Responsibility  is required");
      return false;
    }

    let personal_details = personalDetails.concat(addOptionArr);
    let sendData = {
      id: location?.state?.id,
      job_title: values.jobTitle,
      years_of_experience: values.experience,
      job_description: values.jobDescription,
      responsibilities: values.responsibility,
      personal_details: personal_details,
    };

    let sendUrl = location.state ? API_PATH.editJob : API_PATH.postJob;
    await Axios({
      method: "post",
      url: `${API_PATH.baseUrl}${sendUrl}`,
      data: sendData,
    })
      .then((res) => {
        setVisible(true);
        setMsg(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Careers | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div className={`${classes.create} `}>
        <Formik
          initialValues={{
            jobTitle: location?.state?.job_title ? location?.state?.job_title : "",
            experience: location?.state?.years_of_experience ? location?.state?.years_of_experience : "",
            jobDescription: location?.state?.job_description ? location?.state?.job_description : "",
            responsibility: location?.state?.responsibilities ? location?.state?.responsibilities : "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.jobTitle) {
              errors.jobTitle = "Job title is required";
            }
            if (!values.experience) {
              errors.experience = "Experience is required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            sendData(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            return (
              <form noValidate autoComplete="off" onSubmit={handleSubmit} className={classes.carrierForm}>
                <div className={` xl:px-32 px-0 privacyPolicy`}>
                  <div>
                    <h3>Create new Opportunities</h3>
                  </div>
                  <div className={classes.form2}>
                    <div>
                      <TextField
                        className={classes.mb2}
                        id="jobTitle"
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name={"jobTitle"}
                        label="Job Title"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobTitle}
                      />

                      {<div className={classes.error}> {errors.jobTitle && touched.jobTitle && errors.jobTitle}</div>}
                    </div>
                    <div>
                      <TextField
                        id="experience"
                        margin="dense"
                        type="number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Years of Experience"
                        variant="outlined"
                        name={"experience"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.experience}
                      />
                      {
                        <div className={classes.error}>
                          {errors.experience && touched.experience && errors.experience}
                        </div>
                      }
                    </div>
                  </div>
                  <div>
                    <h3>Job Description</h3>
                  </div>
                  <Editor
                    name="jobDescription"
                    initialValue={values.jobDescription}
                    apiKey="4cb7llw9xpc87xh5p1g8jftaieayrxasq7fm6xsddokjoove"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    // initialValue={""}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat",
                      content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newText) => {
                      // SetJobDescription(newText);
                      values.jobDescription = newText;
                      setJobDescriptionError("");
                      setResponsibilityError("");
                    }}
                  />

                  {
                    <div className={classes.error} style={{ top: 0 }}>
                      {JobDescriptionError}
                    </div>
                  }

                  <div>
                    <h3>Responsibilities</h3>
                  </div>
                  <Editor
                    apiKey="4cb7llw9xpc87xh5p1g8jftaieayrxasq7fm6xsddokjoove"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={values.responsibility}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat",
                      content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newText) => {
                      values.responsibility = newText;
                      // setJobDescriptionError("");
                      // setResponsibilityError("");
                    }}
                  />
                  {
                    <div className={classes.error} style={{ top: 0 }}>
                      {responsibilityError}
                    </div>
                  }
                </div>
                <div className={`${classes.mb3} ${classes.bgCreate}`}>
                  <h3>Personal Details</h3>
                  <div className={classes.carrierGrid}>
                    {personalDetails &&
                      personalDetails?.map((reValue, index) => {
                        return (
                          <div key={index} className={classes.textGrid}>
                            <span className={classes.border}>{reValue?.question}</span>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={reValue?.isEnabled}
                                  onChange={handleChangeEnabled}
                                  name={reValue?.question}
                                  color="primary"
                                />
                              }
                              label="Enabled"
                            />
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Switch
                                  color="primary"
                                  checked={reValue.isRequired}
                                  // checked={state.checkedA}
                                  onChange={handleChangeRequired}
                                  name={reValue.question}
                                />
                              }
                              label="Required"
                            />
                          </div>
                        );
                      })}

                    {/* Add Another Field */}
                  </div>

                  {addOptionArr?.length > 0 && (
                    <div className={classes.addForm}>
                      {addOptionArr.map((res, index) => {
                        return (
                          <>
                            {addOption && (
                              <div>
                                <TextField
                                  className={classes.mb8}
                                  id="outlined-basic"
                                  margin="dense"
                                  fullWidth
                                  label="Field name"
                                  variant="outlined"
                                  onChange={(e) => {
                                    let copy = [...addOptionArr];
                                    copy[index].question = e.target.value;
                                    setAddOptionArr(copy);
                                  }}
                                />
                                {res?.values.map((ress, indexI) => {
                                  if (ress === "option") {
                                    return (
                                      <>
                                        <TextField
                                          className={classes.mb8}
                                          id="outlined-basic"
                                          margin="dense"
                                          fullWidth
                                          // label={`Option${indexI + 1}`}
                                          label={`Option`}
                                          variant="outlined"
                                          onBlur={(e) => {
                                            let copy = [...addOptionArr];

                                            const result = copy[index].values.filter((word) => word !== "option");
                                            copy[index].values = [...copy[index].values, e.target.value];
                                            setAddOptionArr(copy);
                                          }}
                                        />
                                      </>
                                    );
                                  }
                                })}

                                {res.question_type !== "text" && (
                                  <div style={{ margin: 0, textAlign: "left" }}>
                                    <button
                                      className={classes.btnn}
                                      type="button"
                                      onClick={() => {
                                        let copy = [...addOptionArr];
                                        copy[index].values = [...copy[index].values, "option"];

                                        setAddOptionArr(copy);
                                      }}
                                    >
                                      <AddIcon /> Add another option
                                    </button>
                                  </div>
                                )}
                                <div>
                                  <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                      <Switch
                                        color="primary"
                                        checked={res.isRequired}
                                        onChange={(event) => {
                                          let copy = [...addOptionArr];
                                          copy[index].isRequired = event.target.checked;
                                          setAddOptionArr(copy);
                                        }}
                                      />
                                    }
                                    label="Required"
                                  />
                                </div>
                              </div>
                            )}

                            {addOption && (
                              <div>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  className={classes.mb8}
                                  id="outlined-select-currency-native"
                                  select
                                  label="Select"
                                  value={addOption.question_type}
                                  onChange={(e) => {
                                    let copy = [...addOptionArr];
                                    let objIndex = copy.findIndex((obj) => obj.question == e.target.name);
                                    copy[copy.length - 1].question_type = e.target.value;
                                    copy[copy.length - 1].values = ["option"];

                                    setAddOptionArr(copy);
                                  }}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  variant="outlined"
                                >
                                  <option name="text" value={"text"}>
                                    Text
                                  </option>
                                  <option name="Radio button" value={"radio"}>
                                    Radio button
                                  </option>
                                  <option name="Checkbox" value={"radio"}>
                                    Checkbox
                                  </option>
                                  <option name="File" value={"file"}>
                                    File
                                  </option>
                                </TextField>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  )}
                  <div className={`${classes.btn} ${classes.btnnnn}`}>
                    <button
                      style={{ margin: "1rem 0" }}
                      type="button"
                      onClick={() => {
                        let datat = {
                          question: "",
                          values: [""],
                          isRequired: false,
                          isEnabled: true,
                          isNew: 0,
                          question_type: "text",
                        };
                        setAddOptionArr([...addOptionArr, datat]);
                        setAddOption(true);
                      }}
                    >
                      <AddIcon /> <label htmlFor="addIcon">Add another field</label>
                    </button>
                  </div>
                </div>

                <div className={classes.btn}>
                  <button type="submit">{location?.state ? "Update" : "Create"}</button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      {visible && (
        <ModalResponse
          onClose={() => {
            setVisible(false);
            setMsg("");
            history.push("/careers");
          }}
          open={visible}
          message={message}
        />
      )}
      <Footer />
    </div>
  );
}

export default CareersCreate;
