import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  autoComplete: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "5px",
    },
  },
  paddingInput: {
    "&  .MuiOutlinedInput-input": {
      padding: " 14.5px 10px",
    },
    marginBottom: "2rem",
    width: "100%",
    "@media screen and (max-width:767px)": {
      width: "100%",
      // marginBottom: "0rem",
    },
  },
  icon: {
    fontSize: "1.4rem",
    color: "#3b3a97",
  },
  icons: {
    fontSize: "1rem",
    color: "gray",
  },
  center: {
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
    fontSize: " 1.3rem",
    padding: "1rem",
  },
  padding: {
    fontSize: "1.1rem",
    padding: "1rem",
  },
  multiSelect: {
    width: "100%",
    marginTop: "-13px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
      borderWidth: "1px",
    },
    "& label.MuiFormLabel-root": {
      color: "#333",
      marginTop: "-4px",
    },
    "& label.MuiFormLabel-root.Mui-focused": {
      color: "#333",
    },
    "& .MuiChip-outlined": {
      backgroundColor: "#e0e0e0",
      border: "none",
    },
    "& .creatableSelect": {
      width: "100%",
      border: "1px solid #333",
      borderRadius: "5px",
      minHeight: "46px",
      paddingTop: "3px",
      marginTop: "-10px",
    },
    "& .css-tlfecz-indicatorContainer svg": {
      color: "#767576",
      "& :hover": {
        color: "#767576",
      },
    },
    "& .css-1rhbuit-multiValue": {
      borderRadius: "10px",
    },
    "& .css-xb97g8": {
      color: "#333",
      backgroundColor: "hsl(0, 0%, 90%)",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "hsl(0, 0%, 90%)",
        color: "#333",
        cursor: "pointer",
      },
    },
    "& .css-1wa3eu0-placeholder": {
      color: "#a09c9c",
    },
    "& .css-1okebmr-indicatorSeparator": {
      backgroundColor: "white",
    },
  },
  multiSelectCity: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot input": {
      paddingRight: "30px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
      borderWidth: "1px",
    },
    "& label.MuiFormLabel-root": {
      color: "#333",
    },
    "& label.MuiFormLabel-root.Mui-focused": {
      color: "#333",
    },
    "& label.selectCC": {
      position: "relative",
      top: "-12px",
      color: "#333",
      padding: "0 5px",
      background: "#fff",
      zIndex: 1,
    },
    "& input::placeholder": {
      color: "#333",
    },
  },
  dropDown: {
    textAlign: "left",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333",
      borderWidth: "1px",
    },
  },
  formControl: {
    "@media screen and (max-width:767px)": {
      width: "100%",
    },
    "@media screen and (min-width:768px)": {
      width: "98.5%",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "14px",
    },
    "& label.MuiFormLabel-root": {
      color: "#333",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  phoneNumberBlock: {
    height: "50px",
    "& p.content-wrapper": {
      display: "flex",
      border: "1px solid #333",
      // 1px solid #afaeae
      borderRadius: "5px",
      padding: "5px",
      height: "46px",
      position: "relative",
      top: "-23px",
    },
    "& img.flag": {
      position: "relative",
      zIndex: "1",
      borderRadius: "3px",
      "@media screen and (max-width: 1500px)": {
        height: "15px",
        marginTop: "10px",
        marginRight: "3px",
      },
      "@media screen and (min-width: 1501px) and (max-width: 1750px)": {
        height: "15px",
        marginTop: "10px",
        marginRight: "4px",
      },
      "@media screen and (min-width: 1751px)": {
        height: "20px",
        marginTop: "7px",
        marginRight: "5px",
      },
    },
    "& select.country-code-select": {
      width: "100%",
      position: "relative",
      top: "-65px",
      background: "transparent",
      color: "transparent",
      borderColor: "transparent",
      outline: "0px",
      height: "42px",
    },
    "& select.country-code-select1": {
      width: "100%",
      position: "relative",
      top: "-65px",
      background: "transparent",
      color: "transparent",
      borderColor: "transparent",
      outline: "0px",
      height: "42px",
    },
    "& label": {
      position: "relative",
      top: "-12px",
      background: "#fff",
      zIndex: "1",
      padding: "0 5px",
      color: "#333",
      // #8c8c8c
    },
    "& span.country_dial_code": {
      paddingTop: "5px",
    },
  },
  validationErrorText: {
    color: "red",
    marginLeft: "5px",
    fontSize: "0.8rem",
  },
  onBoardingForm: {
    "& input::placeholder": {
      color: "#333",
    },
    "& .option-block label": {
      fontSize: "1rem",
    },
    "& .charges-Div": {
      "& .flex": {
        marginTop: "5px",
      },
    },
    "& .option-block": {
      textAlign: "left",
      width: "100%",
      float: "left",
      border: "1px solid #333",
      borderRadius: "5px",
      padding: "0 10px",
      height: "46px",
      "& p": {
        paddingBottom: "2px",
        marginTop: "-15px",
        "& span": {
          background: "#fff",
          padding: "0 5px",
          color: "#333",
          fontSize: "0.8rem",
        },
      },
    },
    "& .dollerSignLabel": {
      textAlign: "left",
      float: "left",
      fontSize: "1rem",
      color: "#333",
      marginLeft: "8px",
      position: "relative",
      top: "35px",
    },
    "& .charges-Div": {
      "& .text-box": {
        paddingTop: "2px",
        "& .MuiFormControl-root.MuiTextField-root": {
          "& input": {
            paddingLeft: "25px",
          },
        },
      },
    },
    "& .text-box.notWorking": {
      pointerEvents: "none",
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        background: "#f1f1f1",
      },
      "& input": {
        color: "#f1f1f1",
      },
      "& .dollerSignLabel": {
        color: "#f1f1f1",
      },
    },
    "& .text-box": {
      paddingTop: "25px",
      "& .MuiOutlinedInput-root": {
        height: "46px",
      },
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "100%",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#333",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#333",
        borderWidth: "1px",
      },
      "& label": {
        fontSize: "1rem",
        color: "#333",
      },
    },
    "& .round-btn": {
      background: "#38369a",
      border: "1px solid #38369a",
      borderRadius: "25px",
      padding: "7px 10px",
      color: "#fff",
      fontWeight: "600",
      fontSize: "1.25rem",
      "& svg": {
        color: "#fff",
      },
      "& .MuiCircularProgress-root": {
        height: "25px",
        width: "25px",
        verticalAlign: "middle",
      },
      "@media screen and (max-width:767px)": {
        width: "250px",
      },
      "@media screen and (min-width:768px)": {
        width: "300px",
      },
    },
    "@media screen and (max-width:767px)": {
      "& .w-full": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      "& .phoneNoBlock .ph-right": {
        width: "64%",
        marginLeft: "1%",
      },
      "& .mobile-view-ot": {
        marginTop: "15px",
      },
      "& .mobile-view": {
        marginTop: "-15px",
      },
      "& .btn-mobile-view": {
        paddingTop: "15px",
      },
      // '.w-full.charges-Div':{
      //     width: '49.5%',
      // }
    },
    "@media screen and (max-width:339px)": {
      "& .option-block .option-item": {
        marginLeft: "15px",
      },
    },
    "@media screen and (min-width:960px) and (max-width:1024px)": {
      "& .mobile-view": {
        width: "100%",
      },
      "& .mobile-view-ot": {
        width: "100%",
      },
    },
    "@media screen and (min-width:375px) and (max-width:767px)": {
      "& .charges-Div": {
        width: "49.5%",
        "& .text-box.left-charges-div": {
          paddingRight: "0.5%",
        },
        "& .text-box.right-charges-div": {
          paddingLeft: "0.5%",
        },
      },
      "& .working-div-left": {
        width: "49.5%",
        paddingRight: "0.5%",
      },
      "& .working-div-right": {
        width: "49.5%",
        paddingLeft: "0.5%",
      },
    },
  },
  ccLabel: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    fontSize: "0.7rem",
    marginLeft: "7px",
    color: "#333",
  },
  fcc_label: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    fontSize: "0.8rem",
    marginLeft: "7px",
    color: "#333",
    background: "#fff",
    position: "relative",
    zIndex: "1",
    left: "5px",
    padding: "0 5px",
  },
  creatableSelect1: {
    minHeight: "48px!important",
    marginTop: "-8px!important",
  },
}));

export default useStyles;
