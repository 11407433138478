import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import CallIcon from "@material-ui/icons/Call";
import StopIcon from "@material-ui/icons/Stop";
import classes from "./Application.module.css";
import EmailIcon from "@material-ui/icons/Email";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ApplicationResume from "./ApplicationResume";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableContainer from "@material-ui/core/TableContainer";
import ApplicationMail from "./ApplicationMail";

function ApplicationList(props) {
  const [showMobile, setShowMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowMail, setSelectedRowMail] = useState();
  return (
    <div className={classes.tableDiv}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Job ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="center">Resume</TableCell>
              <TableCell align="center">Contact Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length > 0 ? (
              props.data.map((res, index) => {
                let updatedate = "";
                if (res.updatedAt) {
                  let temp_date = new Date(res.updatedAt);
                  let year = temp_date.getFullYear();
                  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                  let month = months[temp_date.getMonth()];
                  let day = temp_date.getDate();
                  updatedate = `${day} ${month} ${year}`;
                }

                return (
                  <TableRow key={index} className={res.isShotlised == 0 ? classes.tableBoGreen : ""}>
                    <TableCell>{res.JobID}</TableCell>
                    <TableCell>{res.Job_Title}</TableCell>
                    <TableCell>{res.years_of_experience}</TableCell>
                    <TableCell>{updatedate}</TableCell>
                    <TableCell align="center">
                      {res.isShotlised == 2 && <StopIcon className={classes.green} />}
                      {res.isShotlised == 3 && <StopIcon className={classes.red} />}
                      {res.isShotlised == 0 && <StopIcon className={classes.trans} />}
                      <VisibilityIcon
                        onClick={() => {
                          setOpen(true);
                          setSelectedRow(res);
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center" className={`${classes.relative} ${classes.width200}`}>
                      <div className={classes.relative}>
                        {showMobile && res?.Id == selectedRow?.Id && (
                          <span>
                            {res.country_code}
                            {res.phone}
                          </span>
                        )}
                        <CallIcon
                          onClick={() => {
                            setSelectedRow(res);
                            setShowMobile((pre) => !pre);
                          }}
                          className={classes.call}
                          color="primary"
                        />
                        <EmailIcon
                          color="primary"
                          onClick={() => {
                            setSelectedRowMail(res);
                            setOpenMail(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.count > 0 && (
        <ReactPaginate
          previousLabel={"<"}
          previousClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          disabledClassName={"border py-1 px-2 text-gray-300 text-sm rounded border-gray-300"}
          nextClassName={"border py-1 px-2 text-black text-sm rounded border-gray-300"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={props.count}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            props.getAllList(e.selected * 10);
          }}
          containerClassName={
            " justify-between pagginaton_react pt-10 items-center w-full pr-12 mobile-pagination lg:flex my-8"
          }
          subContainerClassName={"pages pagination"}
          activeClassName={"text-white text-sm font-bold px-2 py-1 purple rounded focus:outline-none"}
        />
      )}

      <ApplicationResume
        selectedRow={selectedRow?.signed_url}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        changeStatusResume={props.changeStatusResume}
        data={selectedRow}
      />
      <ApplicationMail
        selectedRow={selectedRowMail}
        open={openMail}
        onClose={() => {
          setOpenMail(false);
        }}
      ></ApplicationMail>
    </div>
  );
}

export default ApplicationList;
