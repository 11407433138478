import React from 'react';
import zazzy from '../../../../assets/img/zazzy.svg';
import moment from 'moment';
import { connect } from 'react-redux';

 function Information({ consultant }) {
   const {consultantProfile} = consultant;
   return (
     <div
       className="mt-10 pt-10"
       style={{
         borderTop: ' 1px solid rgba(10, 10, 10,0.1)',
         display: !consultantProfile.professionalData
           ? 'none'
           : consultantProfile.professionalData.length == 0 ? 'none' : 'block',
       }}
     >
       <p
         style={{ color: '#3B3E41' }}
         className="text-lg mb-2 font-semibold lg:mb-0"
       >
         - Professional information
       </p>

       <div
         style={{ borderBottom: '1px solid rgba(10,10,10,0.1)' }}
         className="mt-7"
       >
         {!consultantProfile.professionalData
           ? []
           : consultantProfile.professionalData.map((item, index) => {
               return (
                 <div
                   key={index + 'danger_red'}
                   style={{
                     borderTop: '1px solid rgba(10,10,10,0.1)',
                   }}
                   className="flex flex-col lg:flex-row items-center mt-10 pt-10 pb-14"
                 >
                   <img
                     src={zazzy}
                     alt=""
                     className="h-20 w-20 self-start rounded"
                   />
                   <div className="lg:ml-10">
                     <p className="font-bold mt-2 lg:mt-0 text-xl lg:text-2xl">
                       {item.position}
                     </p>
                     <div
                       className="flex flex-wrap items-center mt-3"
                       style={{ color: '#707376' }}
                     >
                       <p
                         style={{
                           borderRight: ' 1px solid rgba(10, 10, 10,0.1)',
                         }}
                         className="text-base px-2 mt-2 lg:mt-0"
                       >
                         {item.company}
                       </p>
                       <p
                         style={{
                           borderRight: ' 1px solid rgba(10, 10, 10,0.1)',
                         }}
                         className="text-base px-2 mt-2 lg:mt-0"
                       >
                         {item.location}
                       </p>
                       <p
                         style={{
                           borderRight: ' 1px solid rgba(10, 10, 10,0.1)',
                         }}
                         className="text-base px-2 mt-2 lg:mt-0"
                       >
                         {item.employmentType}
                       </p>
                       <p className="text-base px-2 mt-2 lg:mt-0">
                         {moment(item.startDate).format('MMM YYYY')} -{' '}
                         {moment(item.endDate).format('MMM YYYY')}
                       </p>
                     </div>

                     <p
                       style={{ color: '#707376' }}
                       className="leading-8 text-lg mt-7 font-light"
                     >
                       {item.responsibilities}
                     </p>
                   </div>
                 </div>
               );
             })}
       </div>
     </div>
   );
 }

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {})(Information);
