import React from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import xBlack from "../../assets/img/xBlack.svg";
import roundX from "../../assets/img/roundX.svg";
import deleteBig from "../../assets/img/Icon/deleteBig.svg";
import Backdrop from "@material-ui/core/Backdrop";

import classes from "./Carrers.module.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
function ModalResponse(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={props.open}>
          <div className="toaster-wrapper">
            {
              <img
                src={xBlack}
                alt="roundX"
                loading="lazy"
                className="cursor-pointer"
                style={{ float: "right", padding: "20px" }}
                onClick={() => {
                  props.onClose();
                }}
              />
            }

            {
              <div className="rounded bg-white  p-8" id="model_box">
                <div className={classes.center}>
                  {!props?.delete ? (
                    <CheckCircleOutlineIcon className="mailIcon" />
                  ) : (
                    <img
                      src={deleteBig}
                      alt="roundX"
                      loading="lazy"
                      // className="cursor-pointer"
                      style={{ margin: "auto", paddingBottom: "20px" }}
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                  <div className={classes.padding}>
                    <p style={{ fontSize: "20px" }}>{props.message}</p>
                  </div>
                  {props.delete && (
                    <div>
                      <button onClick={props.deleteItem} className={classes.delete}>
                        Delete
                      </button>
                      <button onClick={props.onClose} className={classes.cancel}>
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalResponse;
