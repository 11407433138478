/* eslint-disable import/no-anonymous-default-export */

import {
  GET_CASE_STUDIES,
  GET_CASE_STUDIES_LOADING,
  GET_CASE_STUDIES_ERROR,
  GET_CASE_STUDY,
  GET_CASE_STUDY_LOADING,
  CLEAR_CASE_STUDY,
  GET_CASE_STUDY_ERROR,
} from '../actions/types'; 

const initialState = {
  caseStudyLoading: false,
  caseStudies: [],
  caseStudyError: null,
  caseStudyDetail: null,
  caseStudyDetailLoading: false,
  caseStudyDetailError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_CASE_STUDY:
      return {
        ...state,
        caseStudies: action.payload,
        caseStudyLoading: true,
      };
    case GET_CASE_STUDIES:
      return {
        ...state,
        caseStudies: action.payload,
        caseStudyLoading: false,
      };
    case GET_CASE_STUDIES_LOADING:
      return {
        ...state,
        caseStudies: [],
        caseStudyLoading: true,
      };
    case GET_CASE_STUDIES_ERROR:
      return {
        ...state,
        caseStudies: [],
        caseStudyLoading: false,
        caseStudyError: action.payload,
      };
    case GET_CASE_STUDY:
      return {
        ...state,
        caseStudyDetail: action.payload,
        caseStudyDetailLoading: false,
      };
    case GET_CASE_STUDY_LOADING:
      return {
        ...state,
        caseStudyDetail: null,
        caseStudyDetailLoading: true,
      };
    case GET_CASE_STUDY_ERROR:
      return {
        ...state,
        caseStudyDetail: null,
        caseStudyDetailLoading: false,
        caseStudyDetailError: action.payload,
      };
    default:
      return state;
  }
}
