import React, { useState } from 'react';
import { useField } from 'formik';

export default function Inputs({ ...props }) {
  const [field, meta] = useField(props);
  const { myAct } = props;
  const [clicked, setClicked] = useState(false);
  return (
    <div
      style={{ padding: '6px 13px' }}
      className={`flex items-center justify-between ${
        props.mt ? props.mt : 'mt-0'
      }   relative ${
        !meta.error && clicked
          ? 'active'
          : clicked && meta.error
          ? 'error'
          : 'inactive'
      }-textInput ml-4 mr-3`}
    >
      <p>$</p>
      <input
        style={{ color: '#38369A', marginLeft: 5 }}
        onClick={() => {
          setClicked(true);
          myAct(true);
        }}
        className={`focus:outline-none ${!props.width ? '' : props.width}`}
        {...field}
        {...props}
      />
    </div>
  );
}
