import useStyles from "./AlertMsg.css";
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

const ErrorAlert = props => {
    const classes = useStyles();
    return (
        <div className={classes.ErrorMsg}>
            <p><ErrorOutlineIcon />{props.message}</p>
            <span onClick={props.close}><CloseIcon /></span>
        </div>
    );

}

export default ErrorAlert;