import React,{useEffect} from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import logo from '../../../../../assets/img/regLogo.svg';
import passActive from '../../../../../assets/img/passActive.svg';
import passInActive from '../../../../../assets/img/passInActive.svg';
import passError from '../../../../../assets/img/passError.svg';
import CustomTextInput from '../../../components/CustomTextInput';
import ContinueFooter from '../../../components/ContinueFooter';
import { useHistory } from 'react-router-dom';
import { setPassword } from '../../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';

let derivedData;

 function Secure({ setPassword, consultant }) {
   useEffect(() => {
     const derived = JSON.parse(localStorage.getItem('registerFormValue'));
     derivedData = derived;
   }, []);

   const history = useHistory();
    const alert = useAlert();
   const initialValues = {
     password: '',
     confirmPassword: '',
   };

   const validationSchema = yup.object().shape({
     password: yup.string().required('Password is Required'),
     confirmPassword: yup.string().required('Confirm password is required'),
   });

   const handleSubmit = (formValues) => {
    //  console.log({ formValues });
    const passData = {
      token: consultant.verifyOtpToken,
      password: formValues.password,
    };
     
    if (formValues.password !== formValues.confirmPassword) {
       alert.error('Passwords do not match');
     } else {
       setPassword(passData,(res)=>{

        if (Object.keys(res).length > 0) {
          alert.error(res.error);
        } else {
          history.push('/freelancer/sign-up/step-four');
        }
       });
     }

     
   };

   const percentage = 66.6;
   return (
     <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
       <div className=" flex flex-col justify-center md:px-16 xl:px-24 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9 waitBorderBottom">
           Secure your account
         </h1>

         <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="confirm-password" onSubmit={props.handleSubmit}>
                 <div className="mt-10">
                   <CustomTextInput
                     name="password"
                     id="password"
                     type="password"
                     placeholder="Password *"
                     activeIcon={passActive}
                     inactiveIcon={passInActive}
                     errorIcon={passError}
                   />
                   <CustomTextInput
                     name="confirmPassword"
                     id="confirmPassword"
                     type="password"
                     placeholder="Confirm Password *"
                     activeIcon={passActive}
                     inactiveIcon={passInActive}
                     errorIcon={passError}
                   />
                 </div>
               </Form>
             );
           }}
         </Formik>
       </div>
       <ContinueFooter
         goBack={() => {
           history.push('/freelancer/sign-up/step-two', { data: derivedData });
         }}
         percentage={percentage}
         id="confirm-password"
       />
     </div>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, { setPassword })(Secure);