/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/img/regLogo.svg';
import OtpInput from 'react-otp-input';
import CompanyContinue from '../CompanyContinue';
import Countdown from 'react-countdown';
import { useAlert } from 'react-alert';
import {
  postCompanyOne,
  CompanyVerifyPassCode,
} from '../../../../state/actions/companyAction.js';

import { connect } from 'react-redux';


const percentage = 66.6;

 function CompanyFormTwo(props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [resendActive, setResendActive] = useState(false);
  const alert = useAlert();


  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const { refreshData, company, CompanyVerifyPassCode, postCompanyOne } = props;
  //   const history = useHistory();
  const [otp, setOtp] = useState('');
  return (
    <div
      style={{
        height:
          dimensions.width < 780 ? 680 : dimensions.width < 1300 ? 590 : 680,
      }}
      className=" relative lg:w-3/6 w-full overflow-y-scroll hideScroll"
    >
      <div className=" flex flex-col justify-center md:px-16  px-8 pb-28 lg:pb-32">
        <div className="flex items-center mx-auto mt-12">
          <img src={logo} alt="logo" className="" />
          <p className="text-base ml-2 text-black">StrategyConnect</p>
        </div>

        <h1 className="text-3xl text-center mt-9 waitBorderBottom">
          Enter Passcode
        </h1>

        <form
          id="Compasscode" 
          onSubmit={(e) => {
            e.preventDefault();

            const verifyData = {
              code: otp,
              token: company.registerToken,
              type: 'EMAIL_VERIFICATION',
            };
            //  console.log(otp);
            CompanyVerifyPassCode(verifyData, (res) => {
              if (Object.keys(res).length > 0) {
                alert.error(res.error);
              } else {
                console.log('worked....');
                props.setPage(3);
              }
            });
          }}
        >
          <div className="mt-10">
            <OtpInput
              inputStyle={{
                height: 40,
                width: 40,
                border: '1.5px solid #D1D4D8',
                borderRadius: 5,
              }}
              focusStyle={{
                outline: 'none',
                height: 40,
                width: 40,
                border: '1.5px solid #38369A',
                color: '#38369A',
                borderRadius: 5,
              }}
              containerStyle="justify-between"
              value={otp}
              onChange={(val) => setOtp(val)}
              numInputs={6}
              shouldAutoFocus={false}
              isInputNum
            />
          </div>
        </form>

        <div className="flex justify-between mt-5 otpBottom">
          <p className="text-base font-thin" style={{ color: '#0A0A0A' }}>
            {/* 0:25 sec */}
            <Countdown
              onComplete={() => {
                setResendActive(true);
              }}
              date={Date.now() + 60000}
              zeroPadTime={0}
            />
          </p>

          <button
            disabled={!resendActive}
            onClick={() => {
              if (resendActive) {
                postCompanyOne(refreshData, (res) => {
                  if (Object.keys(res).length > 0) {
                    alert.error(res.error);
                  } else {
                  }
                });
              }
            }}
            className="text-base "
            style={{ color: !resendActive ? 'gray' : '#38369A' }}
          >
            Resend Code
          </button>
        </div>

        <p style={{ color: '#707376' }} className="text-center mt-7 font-thin">
          we sent a email with a verification code to{' '}
          <span
            style={{ color: '#38369A', fontWeight: '500', cursor: 'pointer' }}
          >
            {refreshData.email}
          </span>{' '}
          . if you don’t receive the email, check your spam folder,
        </p>
      </div>

      <CompanyContinue
        goBack={() => {
          props.setPage(1);
        }}
        percentage={percentage}
        id="Compasscode"
      />
    </div>
  );
}


const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps, {
  postCompanyOne,
  CompanyVerifyPassCode,
})(CompanyFormTwo);