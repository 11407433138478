import React, { useState, useEffect } from "react";
import Check from "./Check";
import { useHistory, Link } from "react-router-dom";

export default function Subplans({ list, openModal, request_for_fun }) {
  let history = useHistory();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className="my-12">
      {/* <h1 className="text-4xl font-bold text-center">Easy subscriptions plans</h1>
      <p className="text-xl font-light text-center mt-2" style={{ color: "#707376" }}>
        No contracts. No surprise fees.
      </p> */}

      <div className={`flex flex-wrap ${list.length > 1 ? "justify-between" : "justify-center"}  items-center`}>
        {list.map((subs, i) => {
          return (
            <div
              key={i + "dkvjdvknj"}
              style={{
                maxWidth: "478px",
                minWidth: dimensions.width <= 1200 && dimensions.width >= 1024 ? 400 : 0,
                height:
                  dimensions.width <= 1024 ? "auto" : dimensions.width <= 1200 && dimensions.width >= 1024 ? 682 : 512,
                borderRadius: 10,
                boxShadow: "2px 2px 6px #00000029",
                border: "1px solid #b1b5b99e",
              }}
              className="border grid border-gray-200 p-7 flex flex-col  justify-between"
            >
              <p
                style={{
                  borderBottom: "0.8px solid rgba(10, 10, 10, 0.1)",
                  position: "relative",
                }}
                className="pb-7 text-2xl font-bold"
              >
                {subs.heading}
                <img src={subs.image} className="sub-plan-image" />
              </p>
              <div className="self-start" style={{ minHeight: "315px" }}>
                {subs.points.map((point, i) => {
                  return (
                    <span key={i + "jcbnkhs"} className="flex mt-7">
                      <Check />
                      <p
                        style={{
                          color: "#707376",
                          maxWidth: dimensions.width <= 1200 ? 210 : 380,
                        }}
                        className="text-lg ml-3 font-light"
                      >
                        {point}
                      </p>
                    </span>
                  );
                })}
              </div>
              {/* <div>
                <button>
                  <button>Make Enquiry</button>
                </button>
              </div> */}

              <div
                style={{
                  borderTop: "0.8px solid rgba(10, 10, 10, 0.1)",
                }}
                className="pt-4 mt-6"
              >
                <span>
                  <button
                    onClick={() => {
                      history.push(`/strategy-services/${subs.link.split(" ").join("")}`);
                    }}
                    className="rounded bg-indigo-800 text-white text-center py-1.5 w-full-eng text-base font-light"
                  >
                    <span>Know More</span>
                  </button>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
