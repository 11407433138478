import React, { useState } from 'react';
import CustomDropdown from '../../../components/CustomDropdown';
import downArrow from '../../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../../assets/img/textUpArrow.svg';
import edit from '../../../../../assets/img/edit.svg';
import trash from '../../../../../assets/img/trash.svg';
import Searchable from 'react-searchable-dropdown';

export default function LanguagesComponent({
  placeholder,
  languagesValues,
  fluencyValues,
  langArrayList,
  itemOnePlaceholder,
  itemTwoPlaceholder,
  showOneDrop,
  setArrList,
  deleteFromArrayList,
}) {
  const [picked, setPicked] = useState(false);
  const [myJoinAs, setMyJoinAs] = useState('');
  const [fluent, setFluent] = useState(''); 

  const languageDropDown = () => {
    let joinArray = [];
    languagesValues.map((item) => {
      joinArray.push({ value: item.item, label: item.item });
    });
    return joinArray;
  };
  const fluencyValuesDropDown = () => {
    let joinArray = [];
    fluencyValues.map((item) => {
      joinArray.push({ value: item.item, label: item.item });
    });
    return joinArray;
  };

  const onSubmit = () => {
    setArrList(myJoinAs, fluent); 
  };

  return (
    <div
      className={`relative ${picked ? 'active' : 'inactive'}-textInput mt-6 `}
    >
      <p style={{ color: '#707376' }}>{placeholder}</p>

      <div
        style={{
          marginTop: 16,
          borderTop: '1px solid rgba(10, 10, 10, 0.11)',
        }}
        className="flex flex-col lg:flex-row items-center pb-4 justify-between"
      >
        <div style={{ marginTop: 25, zIndex: 0 }}>
          <Searchable
            value={myJoinAs}
            placeholder={itemOnePlaceholder} // by default "Search"
            notFoundText="No result found" // by default "No result found"
            options={languageDropDown()}
            onSelect={(value) => {
              setMyJoinAs(value);
              setPicked(true);
            }}
            listMaxHeight={200} //by default 140
          />
        </div>

        {showOneDrop ? null : (
          <div
            style={{
              marginTop: 25,
              marginLeft: 10,
              marginRight: 10,
              zIndex: 0,
            }}
          >
            <Searchable
              value={fluent}
              placeholder={itemTwoPlaceholder} // by default "Search"
              notFoundText="No result found" // by default "No result found"
              options={fluencyValuesDropDown()}
              onSelect={(value) => {
                setFluent(value);
                setPicked(true);
              }}
              listMaxHeight={200} //by default 140
            />
          </div>
        )}

        <p
          style={{ height: 49.5 }}
          onClick={onSubmit}
          className="text-white text-center w-full flex items-center lg:w-auto cursor-pointer hover:bg-indigo-900 relative top-3 text-sm font-light rounded py-3 px-4 purple"
        >
          Save
        </p>
      </div>
      {/* list of languages */}
      <div
        style={{
          borderTop: '1px solid rgba(10, 10, 10, 0.08)',
        }}
        className="lg:mt-2 mt-4 pb-2"
      >
        {langArrayList.length < 1
          ? null
          : langArrayList.map((item, index) => {
              return (
                <div
                  key={index + 'dkcjnlmlk'}
                  style={{ backgroundColor: 'rgba(177, 181, 185, 0.09)' }}
                  className="flex items-center rounded justify-between mt-4 py-2 px-3"
                >
                  <p
                    className="text-sm w-full"
                    style={{
                      color: '#0A0A0A',
                      borderRight: '0.6px solid #B1B5B9',
                    }}
                  >
                    {item.lang} - {item.fluency}
                  </p>
                  <div className="flex items-center">
                    <img
                      onClick={() => {
                        deleteFromArrayList(index);
                      }}
                      src={trash}
                      alt="delete"
                      className="ml-2 cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}
