import React from 'react';

export default function Rocket() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M63.9248 4.77798C63.7862 2.23573 61.7643 0.214104 59.222 0.0753538C54.0923 -0.204896 44.0543 0.067729 34.4558 5.03235C29.5627 7.56335 24.3877 11.8545 20.2578 16.8055C20.2069 16.8665 20.1574 16.9279 20.1069 16.989L10.7344 17.713C9.19166 17.8322 7.81754 18.6409 6.96429 19.9314L0.570037 29.6029C-0.0770879 30.5816 -0.179963 31.8065 0.294662 32.8794C0.769412 33.9524 1.74491 34.7001 2.90429 34.8797L10.8007 36.1027C10.7728 36.2562 10.7448 36.4096 10.7192 36.5631C10.4563 38.1356 10.9773 39.7524 12.113 40.888L23.112 51.8871C24.0537 52.8287 25.326 53.3479 26.6298 53.3479C26.8983 53.3479 27.1683 53.3257 27.437 53.2809C27.5905 53.2552 27.7439 53.2272 27.8974 53.1994L29.1204 61.0959C29.3 62.2552 30.0478 63.2309 31.1205 63.7055C31.5669 63.903 32.0393 64.0005 32.5095 64.0005C33.1699 64.0004 33.8257 63.8081 34.3972 63.4302L44.0687 57.0359C45.3593 56.1825 46.1678 54.8084 46.2868 53.2657L47.0108 43.8934C47.0719 43.8429 47.1334 43.7934 47.1944 43.7425C52.1455 39.6126 56.4368 34.4376 58.9677 29.5444C63.9325 19.9455 64.2047 9.90735 63.9248 4.77798ZM42.0005 53.9076L32.7492 60.0242L31.5572 52.3286C35.4672 51.179 39.335 49.3377 43.0199 46.8711L42.5483 52.9767C42.5189 53.3576 42.3192 53.697 42.0005 53.9076ZM25.7637 49.2355L14.7645 38.2364C14.4839 37.9556 14.3542 37.5612 14.4178 37.1814C14.7448 35.226 15.2529 33.3646 15.8819 31.6111L32.3854 48.1146C30.2794 48.869 28.393 49.319 26.8187 49.5822C26.4382 49.6455 26.0443 49.5161 25.7637 49.2355ZM11.0233 21.4519L17.1288 20.9802C14.662 24.6651 12.8208 28.533 11.6712 32.443L3.97566 31.2511L10.0924 21.9996C10.3032 21.6809 10.6425 21.4812 11.0233 21.4519ZM44.7927 40.8626C41.7635 43.3892 38.8232 45.2167 36.1129 46.5391L17.4603 27.8865C19.1925 24.3485 21.3067 21.4024 23.1374 19.2076C26.9593 14.6257 31.7127 10.6731 36.1788 8.3631C44.988 3.80648 54.2694 3.5611 59.0179 3.8196C59.6464 3.85385 60.1463 4.35373 60.1805 4.98235C60.4394 9.73085 60.1935 19.0121 55.6369 27.8215C53.327 32.2875 49.3744 37.0407 44.7927 40.8626Z"
          fill="black"
        />
        <path
          d="M49.4083 25.7285L49.4083 25.7286C47.8745 27.2623 45.8602 28.0304 43.8399 28.0306C41.8193 28.0308 39.8062 27.2629 38.2719 25.7286C36.7825 24.2392 35.9653 22.2665 35.9653 20.1604C35.9653 18.0542 36.7824 16.0815 38.2718 14.5922L38.2719 14.5921C41.3411 11.5228 46.3389 11.5226 49.4083 14.5921L49.4083 14.5921C50.8976 16.0814 51.7147 18.0542 51.7147 20.1604C51.7147 22.2666 50.8977 24.2393 49.4083 25.7285Z"
          fill="#38369A"
          stroke="black"
          stroke-width="3"
        />
        <path
          d="M1.9126 52.7423C2.39247 52.7423 2.87235 52.5593 3.23835 52.1931L9.35997 46.0714C10.0922 45.3392 10.0922 44.152 9.35997 43.4198C8.62785 42.6875 7.4406 42.6875 6.70835 43.4198L0.586846 49.5414C-0.145404 50.2737 -0.145404 51.4608 0.586846 52.1931C0.952846 52.5592 1.43272 52.7423 1.9126 52.7423Z"
          fill="#38369A"
        />
        <path
          d="M14.9701 49.0299C14.238 48.2977 13.0508 48.2977 12.3185 49.0299L0.549249 60.7993C-0.183002 61.5315 -0.183002 62.7187 0.549249 63.4509C0.915374 63.817 1.39512 64 1.875 64C2.35487 64 2.83475 63.817 3.20075 63.4508L14.97 51.6815C15.7024 50.9493 15.7024 49.7622 14.9701 49.0299Z"
          fill="#38369A"
        />
        <path
          d="M17.9286 54.64L11.807 60.7616C11.0748 61.4939 11.0748 62.681 11.807 63.4133C12.1731 63.7794 12.653 63.9625 13.1327 63.9625C13.6125 63.9625 14.0925 63.7795 14.4585 63.4133L20.5801 57.2916C21.3124 56.5594 21.3124 55.3723 20.5801 54.64C19.848 53.9078 18.6607 53.9078 17.9286 54.64Z"
          fill="#38369A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
