/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Work from "../Home/SmallHome/components/Work";
import Footer from "../Home/SmallHome/components/Footer";
import OurStory from "./OurStory";
import { Helmet } from "react-helmet";
// import OurValues from './OurValues';

export default function index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="StrategyConnect operates in the business advisory & transformation space to offer startups, family offices, and high-growth organizations, a unique combination of on-demand talent as well as AI-powered technology to help them achieve scale, profitability, and cost-effectiveness."
        />
        <title>About Us | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div>
        <p className="sm:text-3xl text-xl font-semibold text-center xl:w-5/12 sm:w-8/12 w-11/12 mx-auto sm:mt-32 mt-20">
          We empower businesses and prepare them for scalable success
        </p>
        <p className="text-sm grey-text text-center mx-auto  mt-4">With streamlined advisory services</p>
      </div>
      <div className="xl:px-32 px-0">
        <OurStory />
      </div>

      {/* <OurValues /> */}
      <Work />
      <Footer />
    </div>
  );
}
