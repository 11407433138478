import React, { useState, useEffect } from "react";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import BusinessHero from "./BusinessHero";
import Customize from "../MarketPlace/Customize";
import Chat from "../Home/SmallHome/components/Hero/Chat";
import World from "../Home/SmallHome/components/Hero/World";
import Rocket from "../Home/SmallHome/components/Hero/Rocket";
import Subplans from "../MarketPlace/Subplans";
import TechnicalDueImage from "../../assets/img/Tech/15k.png";
import CommercialDueImage from "../../assets/img/Tech/10k.png";
import EnquireModal from "../MarketPlace/EnquireModal";
import { Helmet } from "react-helmet";

export default function BusinessPlan() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [modalState, setModalState] = useState(false);
  const [request_for, setRequest_for] = useState("");

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    window.scrollTo(0, 0);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageList = [
    {
      Img: Chat,
      number: "5000+",
      text: "Consultants & Experts",
    },
    {
      Img: World,
      number: "100+",
      text: "Clients Covered",
    },
    {
      Img: Rocket,
      number: "200+",
      text: "Projects Delivered",
    },
  ];

  const subscriptionList = [
    {
      heading: "Technology Due Diligence ",
      points: [
        "Team Assessment, Product Assessment, Technology and Risk Assessment of a tech product company",
        "Ideal for an investor acquiring a product focused tech company",
        "Ideal for a tech company to identify and fix its product development process",
      ],
      image: TechnicalDueImage,
      link: "technical-due-diligence",
    },
    {
      heading: "Commercial Due Diligence",
      points: [
        "Understanding the market potential, competitive landscape, business plan and future growth strategy",
        "Ideal for an investor (Angel, VC or PE) to assess the commercial viability of a target business",
        "Ideal for a growth focused company to identify vulnerabilities and improve upon its business before entering new markets/verticals or raise financing",
      ],
      image: CommercialDueImage,
      link: "commercial-due-diligence",
    },
    // {
    //   heading: "Operational Viability",
    //   points: [
    //     "Evaluating sales channels and marketing strategy.",
    //     "Assessing organization growth.",
    //     "Assessing technology readiness",

    //     "Addressing cost optimization through outsourcing and creative procurement strategies.",
    //   ],
    // },
    // {
    //   heading: "Financial Viability & Planning",
    //   points: [
    //     "Evaluating key financial ratios.",
    //     "Assessing the cost of customer acquisition.",
    //     "Creating cash flow growth scenarios.",

    //     "Developing comprehensive 3 to 5-year growth roadmap with clearly defined milestones.",
    //   ],
    // },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Our best-selling services are carefully designed into standardized offerings with a well-defined process, clear deliverables, and transparent pricing to help businesses scale fast and succeed. "
        />
        <title>Services Packages | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      {modalState ? (
        <EnquireModal
          visible={modalState}
          setVisible={(val) => {
            setModalState(val);
          }}
          request_for={request_for}
        />
      ) : null}
      <Navbar />
      <BusinessHero />
      {/* <div className="lg:mx-56 sm:mx-32 mx-6 pt-10 topborder flex justify-between">
        {imageList.map((item) => {
          return (
            <div className="flex flex-col items-center content-center">
              <item.Img />
              <p className="text-lg md:text-2xl font-bold md:mt-7 md:mb-3 leading-10">{item.number}</p>
              <p className="text-base  font-normal  text-center md:mb-10 lg:leading-7 grey-text">{item.text}</p>
            </div>
          );
        })}
      </div> */}

      <div
        style={{
          padding: `${
            dimensions.width <= 1200 && dimensions.width >= 1024
              ? "0 15%"
              : dimensions.width < 1024
              ? "0 10%"
              : "0 224px"
          } `,
        }}
      >
        <Subplans
          openModal={(val) => {
            setModalState(val);
          }}
          list={subscriptionList}
          request_for_fun={(val) => {
            setRequest_for(val);
          }}
        />
      </div>

      <Customize
        openModal={(val) => {
          setModalState(val);
        }}
      />

      <Footer />
    </>
  );
}
