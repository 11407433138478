import React, { useEffect } from "react";
import Navbar from "../../../components/Home/SmallHome/components/Navbar";
import Footer from "../../../components/Home/SmallHome/components/Footer";
import LatestUpdates from "../../../components/Home/SmallHome/components/Footer/LatestUpdates";
import OnboardingForm from "./OnboardingForm";
import OnboardingHeader from "./OnboardingHeader";
import { Helmet } from "react-helmet";

const Onboarding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Sign up / Sign In for Consultants " />
        <title>Consultants Login | StrategyConnect</title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <OnboardingHeader />
      <OnboardingForm />
      <Footer />
    </>
  );
};

export default Onboarding;
