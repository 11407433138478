import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import halfBar from '../../../assets/img/halfBar.svg';
import fullBar from '../../../assets/img/fullBar.svg';
import oneBar from '../../../assets/img/oneBar.svg';
import info from '../../../assets/img/info.svg';
import CompanyExperienceModal from './CompanyExperienceModal';


export default function CompanyExperience({functionalArray }) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [addEx, setAddEx] = useState(false);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <div
      style={{ borderTop: '1px solid rgba(10, 10, 10,0.1)' }}
      className="flex flex-wrap justify-between mt-10 pt-14"
    >
      <div className="flex items-center w-full lg:w-auto mb-7 lg:mb-0 justify-between">
        <p
          style={{ color: '#38369A' }}
          className="lg:text-lg self-start relative lg:top-1 font-semibold text-base uppercase"
        >
          Expertise
        </p>
        {/* small edit and add btn on small screen */}
        <div className="flex lg:hidden item-center">
          <button
            onClick={() => {
              setAddEx(true);
            }}
            className="border-indigo-800 border rounded text-indigo-800 px-3 "
          >
            Edit
          </button>
          <button
            onClick={() => {
              setAddEx(true);
            }}
            className="purple ml-4 rounded text-white font-bold text-3xl px-2 "
          >
            +
          </button>
        </div>
        {/* small edit and add btn on small screen */}
      </div>
      <div className="w-full lg:w-4/5">
        <div className="">
          <div className="flex flex-wrap  items-center justify-between">
           
            <p
              style={{ color: '#3B3E41' }}
              className={`text-lg font-semibold mb-2 ${
                'flex' 
              } lg:mb-0`}
            >
              {' '}
            </p>
            <div className="lg:flex hidden items-center">
              <button
                onClick={() => {
                  setAddEx(true);
                }}
                style={{ border: '1px solid #38369A', color: '#38369A' }}
                className="text-base rounded px-4 py-2"
              >
                Edit Experience
              </button>
              <button
                onClick={() => {
                  setAddEx(true);
                }}
                className="purple text-white px-4 py-2 ml-4 rounded text-base"
              >
                Add Experience
              </button>
            </div>
          </div>
          
        </div>

        <div className="lg:mt-10 mt-5">
          <p
            style={{ color: '#3B3E41' }}
            className="text-lg mb-2 font-semibold lg:mb-0"
          >
            - Functional capabilities
          </p>

          <div className="flex items-center flex-wrap mb-16 justify-between">
            {!functionalArray
              ? []
              : functionalArray.map((item, index) => {
                  return (
                    <div
                      key={index + 'dfjhkjnb'}
                      className="border flex flex-col lg:flex-row lg:items-center lg:w-96 w-full justify-between rounded mt-3 lg:mt-7 border-gray-200 px-4  py-2 lg:h-16"
                    >
                      <p
                        style={{ color: '#707376' }}
                        className=" text-base text-left text-ellipse lg:w-40"
                      >
                        {item.lang}
                      </p>
                      <div
                        style={{
                          borderTop:
                            dimensions.width < 780 ? '1px solid #E0E3E7' : '',
                        }}
                        className="flex  w-full pt-3 lg:pt-0 mt-0 mt-2 items-center"
                      >
                        <img
                          src={
                            item.ex === 1
                              ? oneBar
                              : item.ex === 2
                              ? halfBar
                              : fullBar
                          }
                          alt=""
                          className="lg:pr-5 h-2 relative -left-12 lg:left-0 w-full"
                        />
                        <ReactTooltip className="customTool" />

                        <img
                          src={info}
                          data-tip={item.fluency}
                          alt=""
                          style={{ borderLeft: '1px solid #D1D4D8' }}
                          className="pl-4 cursor-pointer"
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <CompanyExperienceModal
          heading="Add Experience"
          visible={addEx}
          setVisible={(val) => {
            setAddEx(val);
          }}
        />
      </div>
    </div>
  );
}
