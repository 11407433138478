import React, { useState, useEffect } from 'react';
import close from '../../../../assets/img/cancel.svg';
import Modal from 'react-awesome-modal';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import LanguagesComponent from '../../../auth/Freelancer/SignUpStepTwo/LanguagesComponent';
import CustomTextInput from '../../../auth/components/CustomTextInput';
import CustomDropdown from '../../../auth/components/CustomDropdown';
import downArrow from '../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../assets/img/textUpArrow.svg';
import CustomDatePicker from '../../../auth/Freelancer/SignUpStepThree/SignUpThreeForm/CustomDatePicker';
import CustomTextArea from '../../../auth/components/CustomTextArea';
import {
  postCompanyData,
} from '../../../../state/actions/companyAction';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { useAlert } from 'react-alert';
import CustomIndustryList from '../../../auth/CompanySignup/CompanyFormThree/CustomIndustryList';



function CompanyExperienceModal(props) {
  const { consultant, company, postCompanyData, loading } = props;
  const alert = useAlert();

  const [professionActive, setProfessionActive] = useState(false);
  const [employmentType, setEmploymentType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const bigHeight = (80 / 100) * dimensions.height;
  const midHeight = (90 / 100) * dimensions.height;
  const smallHeight = dimensions.height;

  const [industryList, setIndustryList] = useState(!company.companyIndustry ? [] : company.companyIndustry);
  const [functionalList, setFunctionalList] = useState(!company.functionalExpertise ? [] : company.functionalExpertise);
  const copyArray = [...industryList];
  const copyfuncArray = [...functionalList];

   const durationValues = [
     { item: '5 - 15', value: '5 - 15' },
     { item: '20 - 35', value: '20 - 35' },
     { item: '50 +', value: '50 +' },
   ];

  const employmentTypeItems = [
    { item: 'Remote', value: 'Remote' },
    { item: 'Full Time', value: 'Full Time' },
    { item: 'Part Time', value: 'Part time' },
  ];

  const initialValues = {
    position: '',
    company: '',
    location: '',
    responsibilities: '',
  };

  const validationSchema = yup.object().shape({
    company: yup.string(),
    location: yup.string(),
    position: yup.string(),
    responsibilities: yup.string(),
  });

  const handleSubmit = (formValues) => {
    const dataObject = {
      companyIndustry:  industryList,
      functionalExpertise: functionalList,
    };
    const authId = localStorage.getItem('companyAuth');
    console.log({ dataObject });
    postCompanyData(dataObject, authId, (res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      } else {
        props.setVisible(false);
      }
    });
  };

  return ( 
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width == 1200
          ? '45%'
          : dimensions.width == 1024
          ? '50%'
          : '40%'
      }
      onClickAway={() => props.setVisible(false)}
    >
      <LoadingOverlay active={consultant.loading} spinner text=" ">
        <div
          style={{
            height:
              dimensions.width < 780
                ? smallHeight
                : dimensions.width == 1200
                ? midHeight
                : dimensions.width == 1024
                ? midHeight
                : bigHeight,
          }}
          className="rounded-lg bg-white "
        >
          <div
            style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
            className="flex items-center justify-between p-8"
          >
            <p className="text-xl uppercase">
              {props.heading}
              {/* Add experience */}
            </p>
            <img
              src={close}
              alt=""
              onClick={() => {
                props.setVisible(false);
              }}
              className="cursor-pointer"
            />
          </div>

          {/* main content */}
          <div className="xl:px-24 md:px-14 px-10 my-3  h-4/6 overflow-y-scroll hideScroll">
            {/* innerContent */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                return (
                  <Form
                    onSubmit={formProps.handleSubmit}
                    id="Add_experience_modal"
                  >
                    <CustomIndustryList
                      setArrList={(valOne) => {
                        setIndustryList([...industryList, { lang: valOne }]);
                      }}
                      languagesValues={consultant.industry_dropdown}
                      deleteFromArrayList={(valId) => {
                        if (valId > -1) {
                          copyArray.splice(valId, 1);
                          setIndustryList([...copyArray]);
                        }
                      }}
                      langArrayList={industryList}
                      id="industries"
                    />
                    <LanguagesComponent
                      itemOnePlaceholder="Select Expertise"
                      itemTwoPlaceholder="Team size"
                      languagesValues={consultant.functional_dropdown}
                      fluencyValues={durationValues}
                      langArrayList={functionalList}
                      deleteFromArrayList={(valId) => {
                        if (valId > -1) {
                          copyfuncArray.splice(valId, 1);
                          setFunctionalList([...copyfuncArray]);
                        }
                      }}
                      setArrList={(valOne, valTwo) => {
                        setFunctionalList([
                          ...functionalList,
                          {
                            lang: valOne,
                            fluency: valTwo,
                            ex:
                              valTwo == '5 - 15'
                                ? 1
                                : valTwo == '20 - 35'
                                ? 2
                                : valTwo == '50 +'
                                ? 3
                                : null,
                          },
                        ]);
                      }}
                      placeholder="Functional Expertise"
                    />

                    

                    
                  </Form>
                );
              }}
            </Formik>

            {/* innerContent */}
          </div>
          {/* main content */}

          <div
            className="px-10 py-5 flex justify-end"
            style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
          >
            <button
              onClick={() => {
                props.setVisible(false);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Cancel
            </button>
            <button
              form="Add_experience_modal"
              type="submit"
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Save Changes
            </button>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}


const mapStateToProps = (state) => ({
  consultant: state.consultant,
  company:state.company.companyData,
  loading:state.company.loading
});

export default connect(mapStateToProps, {
  postCompanyData,
})(CompanyExperienceModal);
