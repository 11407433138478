import axios from '../../services/api';

import {
  STOP_LOADING,
  POST_LOADING,
  COUNTRIES,
  GET_ROLE_DROPDOWN,
  GET_INDUSTRY_DROPDOWN,
  GET_LANGUAGE_DROPDOWN,
  GET_FUNCTIONAL_DROPDOWN,
  POST_CONSULTANT_REGISTER,
  POST_CONSULTANT_OTP,
  TELL_US,
  EXPERIENCE,
  CONSULTANT_DATA,
  SET_RESET_PASSWORD_TOKEN,

} from './types';

const baseURL = 'https://testapi.strategyconnect.co/';


export const getrolelist = (navigateFunction) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${baseURL}api/data/generic/consultant/masters?type=role`, {
      headers: {
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data === null) {
        dispatch({type:STOP_LOADING});
        navigateFunction({ error: res.data.meta.message });
      } else {
        dispatch({
        type: GET_ROLE_DROPDOWN,
        payload: res.data.data,
      });
      }
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
       navigateFunction({ error: 'Something went wrong, please try again later'});
    });
};

export const getIndustryList = (navigateFunction) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(
      `${baseURL}api/data/generic/consultant/masters?type=industry_categorization`,
      {
        headers: {
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
       if (res.data.data === null) {
        dispatch({type:STOP_LOADING});
         navigateFunction({ error: res.data.meta.message });
      } else {
        dispatch({
        type: GET_INDUSTRY_DROPDOWN,
        payload: res.data.data,
      });
      } 
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
      navigateFunction({
        error: 'Something went wrong, please try again later',
      });
    });
};

export const getLanguageList = (navigateFunction) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${baseURL}api/data/generic/consultant/masters?type=language`, {
      headers: {
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
       if (res.data.data === null) {
        dispatch({type:STOP_LOADING});
       navigateFunction({ error: res.data.meta.message });
      } else {
         dispatch({
        type: GET_LANGUAGE_DROPDOWN,
        payload: res.data.data,
      });
      } 
     
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
      navigateFunction({
        error: 'Something went wrong, please try again later',
      });
    });
};

export const getFunctionalList = (navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .get(
      `${baseURL}api/data/generic/consultant/masters?type=functional_capability`,
      {
        headers: {
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      if (res.data.data === null) {
        dispatch({type:STOP_LOADING});
        navigateFunction({ error: res.data.meta.message });
      } else {
        dispatch({
        type: GET_FUNCTIONAL_DROPDOWN,
        payload: res.data.data,
      });
      navigateFunction({});
      } 
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
      navigateFunction({
        error: 'Something went wrong, please try again later',
      });
    });
};

export const getCountries = (navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .get(
      `${baseURL}api/data/generic/country/list?limit=1000&fields=["name"]`,
      {
        headers: {
          Authorization: 'test',
          namespace: 'WEB',
        },
      }
    )
    .then((res) => {
      let contries = [];
      if (res.data.data === null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
 
          res.data.data.list.forEach((element) => {
            const item = element.name;
            const value = element.name;

            let contry = {
              item,
              value,
            };
            contries.push(contry);
          });
        
        dispatch({
          type: COUNTRIES,
          payload: contries,
        });
        navigateFunction({});
      }
    })
    .catch((e) => {
      dispatch({ type: STOP_LOADING });
      navigateFunction({
        error: 'Something went wrong, please try again later',
      });
    });
};



export const postRegisterData = (postData, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`${baseURL}api/data/generic/init-onboard/consultant/`, postData, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data === null) {
        dispatch({type:STOP_LOADING});
        navigateFunction({error:res.data.meta.message});
      } else {
        navigateFunction({}); 
        dispatch({
          type: POST_CONSULTANT_REGISTER,
          payload: res.data.data.verification_token,
        });
      }
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
      navigateFunction({error:'something went wrong please try again later...'})
    });
};

export const verifyPassCode = (verifyData, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`${baseURL}api/auth/verify/`, verifyData, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data == null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
      localStorage.setItem('authId', res.data.data);
       navigateFunction({});
       dispatch({
         type: POST_CONSULTANT_OTP,
         payload: res.data.data,
       });
      }
      
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
       navigateFunction({error:`${e}`});
    });
}; 

export const login = (email, password, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`${baseURL}api/auth/login/`, { data: { email, password } }, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data == null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
      localStorage.setItem('authId', res.data.data);
       navigateFunction({});
      //  dispatch({
      //    type: POST_CONSULTANT_OTP,
      //    payload: res.data.data,
      //  });
      }
      
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
       navigateFunction({error:`${e}`});
    });
};

export const forgotPassword = (email, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`${baseURL}api/auth/forgotpassword/`, { data: { email } }, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data == null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
       navigateFunction({});
       dispatch({
         type: SET_RESET_PASSWORD_TOKEN,
         payload: res.data.data.reset_token,
       });
      }
      
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
       navigateFunction({error:`${e}`});
    });
};

export const resetPassword = (token, code, password, password_again, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`${baseURL}api/auth/resetpassword/`, { data: {token, code, password, password_again} }, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      if (res.data.data == null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
      localStorage.setItem('authId', res.data.data);
      dispatch({ type: STOP_LOADING }); 
      navigateFunction({});
       
       
      }
      
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING});
       navigateFunction({error:`${e}`});
    });
};

export const setPassword = (passwordData, navigateFunction) => (dispatch) => {
  //   const derived = JSON.parse(localStorage.getItem('registerFormValue'));
  dispatch(setLoading());
  const value = `${passwordData.password}`;

  axios
    .post(`${baseURL}api/data/generic/set-password/consultant`, passwordData, {
      headers: {
        'auth-token': `${passwordData.token}`,
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then( (res) => {

      if (res.data.data == null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
          dispatch({
           type: STOP_LOADING,
         });
         navigateFunction({});
      }

    })
    .catch((e) => {
       dispatch({type: STOP_LOADING});
         navigateFunction({error:'something went wrong please tray again later..'});
    });
};
 
export const setTellUsData = (data, navigateFunction) => (dispatch) => {
  dispatch(setLoading());
  setTimeout(() => {
    dispatch({
      type: TELL_US,
      payload: data,
    });
    navigateFunction({});
  }, 2000);
};
export const setExperienceData = (data, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  setTimeout(() => {
    dispatch({
      type: EXPERIENCE,
      payload: data,
    });
    navigateFunction({});
  }, 2000);
};

export const saveProfileData = (data, token, navigateFunction) => (
  dispatch
) => {
  dispatch(setLoading());

  axios
    .post(
      `${baseURL}api/data/generic/save-profile/consultant/`,
      data,
      {
        headers: {
          'auth-token': `${token}`,
          'content-type': 'application/json',
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      if (res.data.data === null) {
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
        dispatch(getConsultantData(token, navigateFunction));
      }
    })
    .catch((e) => {
      dispatch({ type: STOP_LOADING });
      navigateFunction({
        error: 'something went wrong, please try again later',
      });
    });
};

export const getConsultantData = (authId, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .get(
      `${baseURL}api/data/generic/consultant/profile`,
      {
        headers: {
          'auth-token': `${authId}`,
          'content-type': 'application/json',
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      dispatch({
        type: CONSULTANT_DATA,
        payload: res.data.data,
      });
      navigateFunction({});
    })
    .catch((e) => {
      navigateFunction({ error: `${e}` });
    });
};

export const setLoading = () => {
  return {
    type: POST_LOADING,
  };
};
