/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import Inputs from './Inputs';

export default function index(props) {
  const [active, setActive] = useState(false);
  return (
    <div
      style={{ color: '#707376' }}
      className={` ${props.hidden ? '' : 'border'} ${
        active ? 'border-indigo-800' : 'border-gray-300'
      } rounded p-5 ${props.hidden ? 'mt-0' : 'mt-7'}  text-sm`}
    >
      <p className={`${props.hidden ? 'hidden' : 'waitBorderBottom'} `}>
        Estimated Rate in USD
      </p>

      <div className="flex justify-between mt-6 items-cener">
        <div className="flex justify-between items-center">
          <p className="mr-5">Daily</p>
          <Inputs
            myAct={(val) => {
              setActive(val);
            }}
            id={props.daily}
            width="w-full"
            type="text"
            name={props.daily}
          />
        </div>
      </div>

      <div className={`flex justify-between mt-6 items-cener`}>
        <div className="flex justify-between items-center">
          <p className="mr-2.5">Hourly</p>
          <Inputs
            myAct={(val) => {
              setActive(val);
            }}
            id={props.hourly}
            width="w-full"
            type="text"
            name={props.hourly}
          />
        </div>
      </div>

      <div
        className={`flex justify-between mt-6 ${
          props.hidden ? '' : ''
        } items-cener`}
      >
        <div className="flex justify-between items-center">
          <p>Monthly</p>
          <Inputs
            myAct={(val) => {
              setActive(val);
            }}
            id={props.monthly}
            width="w-full"
            type="text"
            name={props.monthly}
          />
        </div>
      </div>
      {!props.hidden && (
        <p
          style={{ marginTop: 20 }}
          className="radio__label text-sm relative top-0.5 font-thin"
        >
          Daily rates are considered for 9 working hours per day. Monthly rates
          are considered for 22 working days per month
        </p>
      )}

      <label
        style={{ color: '#707376' }}
        className="checkbox mt-2 cursor-pointer"
      >
        <span className="checkbox__input">
          <input type="checkbox" name="checkbox" />
          <span className="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </span>
        </span>
        <span className="radio__label text-sm relative top-0.5 font-thin">
          I’m comfortable showing my rate information on my profile
        </span>
      </label>
    </div>
  );
}
