import axios from '../../services/api';

import {
  POST_LOADING,
  COMPANY_SAVE_AUTH_TOKEN,
  POST_COMPANY_OTP,
  STOP_LOADING,
  COMPANY_DATA,
} from './types';

export const postCompanyOne = (postData, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(
      `https://testapi.strategyconnect.co/api/data/generic/init-onboard/company/`,
      postData,
      {
        headers: {
          'content-type': 'application/json',
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      if(res.data.data == null){
        navigateFunction({error:res.data.meta.message});
        dispatch({type:STOP_LOADING})
      }else{
        dispatch({
          type: COMPANY_SAVE_AUTH_TOKEN,
          payload: res.data.data.verification_token,
        });
        navigateFunction({});
      }
    })
    .catch((e) => {
      dispatch({type:STOP_LOADING})
      navigateFunction({error:`${e}`});
    });
};


export const CompanyVerifyPassCode = (verifyData, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(`https://testapi.strategyconnect.co/api/auth/verify/`, verifyData, {
      headers: {
        'content-type': 'application/json',
        'x-fwd-authorization': 'test',
        namespace: 'STRATEGY',
      },
    })
    .then((res) => {
      console.log({ res });
      if (res.data.data == null) {
        
        dispatch({ type: STOP_LOADING });
        navigateFunction({ error: res.data.meta.message });
      } else {
        localStorage.setItem('companyAuth', res.data.data);
        navigateFunction({});
        dispatch({
          type: POST_COMPANY_OTP,
          payload: res.data.data,
        });
      }
    })
    .catch((e) => {
      navigateFunction({ error: `${e}` });
    });
};


export const postCompanyData = (verifyData, authId, navigateFunction) => (dispatch) => {
  dispatch(setLoading());

  axios
    .post(
      `https://testapi.strategyconnect.co/api/data/generic/save-profile-data/company/`,
      verifyData,
      {
        headers: {
          'auth-token': `${authId}`,
          'content-type': 'application/json',
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      navigateFunction({});
      dispatch(getCompanyData(authId, navigateFunction));
    })
    .catch((e) => {
      navigateFunction({ error: `${e}` });
    });
};

export const getCompanyData = (authId, navigateFunction) => (dispatch) => {
  axios
    .get(
      `https://testapi.strategyconnect.co/api/data/generic/company/profile-data`,
      {
        headers: {
          'auth-token': `${authId}`,
          'x-fwd-authorization': 'test',
          namespace: 'STRATEGY',
        },
      }
    )
    .then((res) => {
      dispatch({type:STOP_LOADING})
      dispatch({
        type: COMPANY_DATA,
        payload: res.data.data,
      });
      navigateFunction({});
    })
    .catch((e) => {
      navigateFunction({ error: `${e}` });
    });
};



export const setLoading = () => {
  return {
    type: POST_LOADING,
  };
};