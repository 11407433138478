/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import woman from '../../../../../assets/img/pgOneImg.webp';
import img10 from '../../../../../assets/img/Crocs.svg';
import img12 from '../../../../../assets/img/amatus.svg';
import img13 from '../../../../../assets/img/leoa.svg';
import img14 from '../../../../../assets/img/oimenu.svg';
import ResetPasswordForm from './ResetPasswordForm';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

 function ResetPassword({ location, consultant }) {
  //  const { data } = location.state;
  //  console.log({ location });
  const data = {};
   const imgArray = [img10, img12, img13, img14];
   return (
     <LoadingOverlay active={consultant.loading} spinner text="">
       <div className=" w-full flex  items-center">
         <div className="h-screen hidden lg:block relative w-3/5">
           <img src={woman} style={{objectFit:'cover'}} alt="placeImage" className="z-10 h-screen w-full" />
           <div
             style={{
               background:
                 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(56, 54, 154, 0.8) 77.08%, rgba(56, 54, 154, 0.96) 100%)',
             }}
             className="w-full  h-full z-20 absolute top-0  left-0"
           >
             <div className="absolute bottom-0 pb-10 px-16">
               <h1 className="text-4xl font-bold text-white w-4/5 pb-7 ">
                 Work with the best brands in the industry.
               </h1>
               <div style={{ borderTop: '1.5px solid #FFFFFF' }}>
                 <p className="text-white text-sm text-center mt-7 mb-5">
                   Trusted by
                 </p>

                 <div className="flex items-center justify-between ">
                   {imgArray.map((item, index) => {
                     return <img src={item} key={index} alt="logo" />;
                   })}
                 </div>
               </div>
             </div>
           </div>
         </div>
         <ResetPasswordForm data={data} />
       </div>
     </LoadingOverlay>
   );
 }


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {})(ResetPassword); 