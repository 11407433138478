import React from 'react'

export default function ForwardArrow() {
      return (
        <svg
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2.00003L8.96317 8.9632L2 15.9264"
            stroke="#38369A"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
}
