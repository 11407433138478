import React, { useState, useEffect } from "react";
import target from "../../../assets/img/target.svg";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import defaultCaseStudy from "../../../assets/img/defaultCaseStudy.png";

import { getCaseStudyDetail } from "../../../state/actions/caseStudyAction";

function Interested({ getCaseStudyDetail, props }) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [limitCase, setLimitCase] = useState();
  const [limitCase_image, setLimitCase_image] = useState();
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let aValue = localStorage.getItem("industry");
    if (JSON.parse(aValue).length >= 3) {
      setLimitCase(JSON.parse(aValue).slice(0, 3));
      // setLimitCase_image(JSON.parse(aValue).signed_url.slice(0, 3));
    } else {
      setLimitCase(JSON.parse(aValue).list);
      // setLimitCase_image(JSON.parse(aValue).signed_url);
    }
  }, []);
  return (
    <div className="">
      <p className="textCap">You may benefit from these other suggested case studies </p>
      <div className="case_study_box w-full">
        {limitCase &&
          limitCase.map((item, i) => {
            if (item) {
              return (
                <Link
                  key={item.cs_published_id}
                  to={{
                    pathname: `/case-studies/${item.cs_published_id}`,
                    caseId: `${item.cs_published_id}`,
                  }}
                  onClick={() => {
                    getCaseStudyDetail(item.cs_published_id);
                  }}
                  // replace
                  className="m-w-100"
                >
                  <div className="case-box">
                    <img
                      key={item.cs_published_id}
                      className="caseStudyImage"
                      src={item.signed_url ? item.signed_url : defaultCaseStudy}
                      loading="lazy"
                      alt="tyler"
                    />
                    {/* {limitCase_image.length > 0 ? (
                      limitCase_image.map((val) => {
                        if (item.case_study_id == val.case_study_id) {
                          return (
                            <img
                              key={val.case_study_id}
                              className="caseStudyImage"
                              src={val.signed_url ? val.signed_url : defaultCaseStudy}
                              loading="lazy"
                              alt="tyler"
                            />
                          );
                        }
                      })
                    ) : (
                      <img className="caseStudyImage" src={defaultCaseStudy} loading="lazy" alt="tyler" />
                    )} */}
                    {/* {limitCase_image.map((val) => {
                      // console.log("vavavavva", val);
                      if (item.case_study_id == val.case_study_id) {
                        console.log("iiiiiiiiiiii");
                        return (
                          <img
                            key={val.case_study_id}
                            className="caseStudyImage"
                            src={val.signed_url}
                            loading="lazy"
                            alt="tyler"
                          />
                        );
                      } else {
                        console.log("jjjjjjjjjjjjjjjjj");
                        return (
                          <img
                            key={val.case_study_id}
                            className="caseStudyImage"
                            src={defaultCaseStudy}
                            loading="lazy"
                            alt="tyler"
                          />
                        );
                      }
                    })} */}

                    <div className="boxCaseStudyTop">
                      <div className="caseStudyText">{item.title}</div>
                      {item.industries && JSON.parse(item.industries).length > 0 && (
                        <div style={{ fontSize: "0.8rem" }}>
                          <span style={{ fontWeight: "bold" }}> Industry : &nbsp;</span>
                          {item.industries && JSON.parse(item.industries).map((indu, i) => {
                            if (i == 0) {
                              return <span>{indu.name}&nbsp;</span>;
                            } else if (i == 1) {
                              return <span>...</span>;
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              );
            }
          })}
      </div>
    </div>
  );
}

getCaseStudyDetail.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  caseStudy: state.caseStudy,
});

export default connect(mapStateToProps, { getCaseStudyDetail })(Interested);
