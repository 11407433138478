import React, { useState, useEffect } from 'react';
import close from '../../../../assets/img/cancel.svg';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import checked from '../../../../assets/img/checkB.svg';
import unchecked from '../../../../assets/img/unCheck.svg';
import Modal from 'react-awesome-modal';
import CustomRates from '../../../auth/components/CustomRates';
import {saveProfileData} from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import LoadingOverlay from 'react-loading-overlay';
import CustomTextInput from '../../../auth/components/CustomTextInput';

function EditPreferences(props) {
  const { consultant, saveProfileData } = props;
   const alert = useAlert();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [engagement, setEngagement] = useState(
    !consultant.consultantProfile.preference
      ? 0
      : consultant.consultantProfile.preference
  );
 

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const bigHeight = (80 / 100) * dimensions.height;
  const midHeight = (90 / 100) * dimensions.height;
  const smallHeight = dimensions.height;

  const initialValues = {
    daily: '',
    hourly: '',
    monthly: '',
    engagement:''
  };

  const validationSchema = yup.object().shape({
    engagement: yup.string(),
    daily: yup.number(),
    hourly: yup.number(),
    monthly: yup.number(),
  });

  const handleSubmit = (formValues) => {
    const dataObject = {
      preference: engagement,
      daily: formValues.daily.length == 0 ? consultant.consultantProfile.daily : formValues.daily,
      hourly: formValues.hourly.length == 0 ? consultant.consultantProfile.hourly : formValues.hourly,
      monthly: formValues.monthly.length == 0 ? consultant.consultantProfile.monthly : formValues.monthly,
      engagement: formValues.engagement.length == 0 ? consultant.consultantProfile.engagement : formValues.engagement,
    };
     const authId = localStorage.getItem('authId');
     saveProfileData(dataObject, authId, (res) => {
       if (Object.keys(res).length > 0) {
         alert.error(res.error);
       } else {
         props.setVisible(false);
       }
     });
  };

  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width == 1200
          ? '60%'
          : dimensions.width == 1024
          ? '60%'
          : '40%'
      }
      onClickAway={() => props.setVisible(false)}
    >
      <LoadingOverlay active={consultant.loading} spinner text=" ">
        <div
          style={{
            height:
              dimensions.width < 780
                ? smallHeight
                : dimensions.width == 1200
                ? midHeight
                : dimensions.width == 1024
                ? midHeight
                : bigHeight,
            width: dimensions.width < 780 ? dimensions.width : 'auto',
          }}
          className="rounded-lg bg-white overflow-y-scroll hideScroll"
        >
          <div
            style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
            className="flex items-center justify-between p-8"
          >
            <p className="text-xl">EDIT PREFRENECES</p>
            <img
              src={close}
              alt=""
              onClick={() => {
                props.setVisible(false);
              }}
              className="cursor-pointer"
            />
          </div>

          {/* main edit content */}
          <div className="  lg:px-10 px-5 h-3/5 mt-8 overflow-y-scroll hideScroll ">
            {/* onsite or remote option */}
            <p className="lg:text-xl text-lg">
              - Please add your engagement preferences
            </p>
            <div
              style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
              className="flex items-center mt-4 pb-10"
            >
              <div
                onClick={() => {
                  setEngagement(1);
                }}
                className="flex items-center cursor-pointer"
              >
                <img
                  src={engagement == 1 ? checked : unchecked}
                  alt=""
                  loading="lazy"
                />
                <p
                  className="lg:text-xl text-lg ml-3"
                  style={{ color: engagement == 1 ? '#38369A' : '#707376' }}
                >
                  Full time
                </p>
              </div>

              <div
                onClick={() => {
                  setEngagement(2);
                }}
                className="flex items-center ml-10 cursor-pointer"
              >
                <img
                  src={engagement == 2 ? checked : unchecked}
                  alt=""
                  loading="lazy"
                />
                <p
                  className="lg:text-xl text-lg ml-3"
                  style={{ color: engagement == 2 ? '#38369A' : '#707376' }}
                >
                  Part time
                </p>
              </div>
           
              <div
                onClick={() => {
                  setEngagement(3);
                }}
                className="flex items-center ml-10 cursor-pointer"
              >
                <img
                  src={engagement == 3 ? checked : unchecked}
                  alt=""
                  loading="lazy"
                />
                <p
                  className="lg:text-xl text-lg ml-3"
                  style={{ color: engagement == 3 ? '#38369A' : '#707376' }}
                >
                  Remote
                </p>
              </div>
           
           
           
            </div>
            {/* onsite or remote option */}

            
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                return (
                  <Form id="hourly-rate" onSubmit={formProps.handleSubmit}>
                    {/* part time of full time option */}
                    <p className="lg:text-xl text-lg mt-5">
                      - Please add your location preferences
                    </p>
                    <div
                      style={{
                        borderBottom: '1px solid rgba(56, 54, 154,0.1)',
                      }}
                      className="flex items-center mt-4 pb-10"
                    >
                      <CustomTextInput
                        name="engagement"
                        id="engagement"
                        type="text"
                        placeholder="Location Preference"
                      />
                    </div>
                    {/* part time of full time option */}

                    {/* hourly rates */}
                    <p className="lg:text-xl text-lg mt-5">
                      - Please add your Estimated rates in USD
                    </p>

                    <div className="flex items-center flex-wrap">
                      <CustomRates
                        hidden={true}
                        daily="daily"
                        hourly="hourly"
                        monthly="monthly"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>

            {/* hourly rates */}
          </div>
          {/* main edit content */}
          <div
            className="px-10 py-5 flex  mt-10 justify-end"
            style={{ borderTop: '1px solid rgba(56, 54, 154,0.1)' }}
          >
            <button
              onClick={() => {
                props.setVisible(false);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Cancel
            </button>
            <button
              form="hourly-rate"
              type="submit"
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Save Changes
            </button>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}


const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  saveProfileData,
})(EditPreferences);
