import React from 'react';

export default function World() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M32 25.875C37.9965 25.875 42.875 20.9965 42.875 15C42.875 9.0035 37.9965 4.125 32 4.125C26.0035 4.125 21.125 9.0035 21.125 15C21.125 20.9965 26.0035 25.875 32 25.875ZM32 7.875C35.9288 7.875 39.125 11.0712 39.125 15C39.125 18.9288 35.9288 22.125 32 22.125C28.0712 22.125 24.875 18.9288 24.875 15C24.875 11.0712 28.0712 7.875 32 7.875Z"
          fill="black"
        />
        <path
          d="M54 25.875C57.7909 25.875 60.875 22.7909 60.875 19C60.875 15.2091 57.7909 12.125 54 12.125C50.2091 12.125 47.125 15.2091 47.125 19C47.125 22.7909 50.2091 25.875 54 25.875ZM54 15.875C55.7231 15.875 57.125 17.2769 57.125 19C57.125 20.7231 55.7231 22.125 54 22.125C52.2769 22.125 50.875 20.7231 50.875 19C50.875 17.2769 52.2769 15.875 54 15.875Z"
          fill="black"
        />
        <path
          d="M55.5125 30.125H52.6125C49.1951 30.125 46.2451 32.144 44.9009 35.044C42.1332 32.0227 38.1579 30.125 33.7474 30.125H30.2528C25.8423 30.125 21.8669 32.0227 19.0993 35.044C17.7549 32.144 14.8049 30.125 11.3875 30.125H8.4875C3.8075 30.125 0 33.9095 0 38.5612V52.2125C0 54.232 1.64925 55.875 3.6765 55.875H15.1424C15.3346 58.1125 17.2161 59.875 19.5028 59.875H44.4974C46.7839 59.875 48.6654 58.1125 48.8577 55.875H60.1926C62.2921 55.875 64.0001 54.1738 64.0001 52.0825V38.5612C64 33.9095 60.1925 30.125 55.5125 30.125V30.125ZM3.75 38.5612C3.75 35.9772 5.87525 33.875 8.4875 33.875H11.3875C13.9997 33.875 16.125 35.9772 16.125 38.5612V39.8451C14.8641 43.1281 15.125 45.0956 15.125 52.125H3.75V38.5612ZM45.125 55.4974C45.125 55.8435 44.8435 56.125 44.4974 56.125H19.5026C19.1565 56.125 18.875 55.8434 18.875 55.4974V45.2528C18.875 38.979 23.979 33.875 30.2526 33.875H33.7472C40.021 33.875 45.125 38.979 45.125 45.2526V55.4974ZM60.25 52.0825C60.25 52.1471 60.949 52.125 48.875 52.125C48.875 45.0436 49.1339 43.1227 47.875 39.8451V38.5612C47.875 35.9772 50.0002 33.875 52.6125 33.875H55.5125C58.1248 33.875 60.25 35.9772 60.25 38.5612V52.0825Z"
          fill="black"
        />
        <path
          d="M10 25.875C13.7909 25.875 16.875 22.7909 16.875 19C16.875 15.2091 13.7909 12.125 10 12.125C6.20913 12.125 3.125 15.2091 3.125 19C3.125 22.7909 6.20913 25.875 10 25.875ZM10 15.875C11.7231 15.875 13.125 17.2769 13.125 19C13.125 20.7231 11.7231 22.125 10 22.125C8.27688 22.125 6.875 20.7231 6.875 19C6.875 17.2769 8.27688 15.875 10 15.875Z"
          fill="black"
        />
        <path
          d="M10 44C11.1028 44 12 43.1028 12 42C12 40.8972 11.1028 40 10 40C8.8972 40 8 40.8972 8 42C8 43.1028 8.8972 44 10 44ZM9.5 43C10.0013 43 9.09091 41.4987 9.09091 42C9.09091 42.5013 10.0013 42 9.5 42C8.99873 42 9.09091 42.5013 9.09091 42C9.09091 41.4987 8.99873 43 9.5 43Z"
          fill="#38369A"
        />
        <path
          d="M54 44C55.1028 44 56 43.1028 56 42C56 40.8972 55.1028 40 54 40C52.8972 40 52 40.8972 52 42C52 43.1028 52.8972 44 54 44ZM53.5 43C54.0013 43 53.0909 41.4987 53.0909 42C53.0909 42.5013 54.0013 42 53.5 42C52.9987 42 53.0909 42.5013 53.0909 42C53.0909 41.4987 52.9987 43 53.5 43Z"
          fill="#38369A"
        />
        <path
          d="M32 49C34.2056 49 36 47.2056 36 45C36 42.7944 34.2056 41 32 41C29.7944 41 28 42.7944 28 45C28 47.2056 29.7944 49 32 49ZM31 47C32.0025 47 30.1818 43.9975 30.1818 45C30.1818 46.0025 32.0025 45 31 45C29.9975 45 30.1818 46.0025 30.1818 45C30.1818 43.9975 29.9975 47 31 47Z"
          fill="#38369A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
