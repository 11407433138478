import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import ReactTooltip from 'react-tooltip';
import EllipsisText from 'react-ellipsis-text';
import projTwo from '../../../../assets/img/projTwo.webp';
import close from '../../../../assets/img/cancel.svg';
import halfBar from '../../../../assets/img/halfBar.svg';
import fullBar from '../../../../assets/img/fullBar.svg';
import oneBar from '../../../../assets/img/oneBar.svg';
import info from '../../../../assets/img/info.svg';


export default function ViewProjects(props) {
  const { projectobject } = props;
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const bigHeight = (80 / 100) * dimensions.height;
  const smallHeight = dimensions.height;

  return (
    <Modal
      visible={props.visible}
      effect="fadeInUp"
      width={
        dimensions.width < 780
          ? '100%'
          : dimensions.width === 1200
          ? '45%'
          : dimensions.width === 1024
          ? '60%'
          : '60%'
      }
      onClickAway={() => props.setVisible(false)}
    >
      <div
        style={{
          height: dimensions.width < 780 ? smallHeight : bigHeight,
        }}
        className="rounded-lg bg-white flex flex-col"
      >
        <div
          style={{ borderBottom: '1px solid rgba(56, 54, 154,0.1)' }}
          className="flex items-center justify-between p-8"
        >
          <p className="text-xl uppercase">Project details</p>
          <img
            src={close}
            alt=""
            onClick={() => {
              props.setVisible(false);
            }}
            className="cursor-pointer"
          />
        </div>

        <div
          style={{ width: '90%', marginBottom: 42 }}
          className="border border-grey-200 rounded overflow-y-scroll hideScroll self-center overflow p-4 lg:p-8 mt-10"
        >
          <div
            style={{ borderBottom: '1px solid #E0E3E7', paddingBottom: 24 }}
            className="flex flex-wrap"
          >
            <img
              src={projTwo}
              alt=""
              loading="lazy"
              style={{ height: 183.52, width: 300 }}
            />
            <div style={{ marginLeft: 24, maxWidth: 385 }}>
              <h1 className="text-2xl font-bold mt-4 md:mt-0">
                {projectobject.title}
              </h1>
              <p
                style={{ color: '#707376' }}
                className="text-lg font-light mt-2"
              >
                {projectobject.description}
              </p>
            </div>
          </div>

          <div style={{ borderBottom: '1px solid #E0E3E7', paddingBottom: 12 }}>
            <p
              style={{ color: '#3B3E41' }}
              className={`text-lg font-semibold mt-6`}
            >
              - Industry focus :
            </p>
            <div className="flex items-center flex-wrap justify-between">
              {!projectobject.industryList
                ? []
                : projectobject.industryList.map((ind, index) => {
                    return (
                      <div
                        key={index + 'dkjnvlmlkd'}
                        className="border flex flex-col lg:flex-row lg:items-center lg:w-80 w-full justify-between rounded mt-3 lg:mt-7 border-gray-200 px-4  py-2 lg:h-16"
                      >
                        <p
                          style={{ color: '#707376' }}
                          className=" text-base text-left lg:w-32 text-ellipse"
                        >
                          {ind.lang}
                        </p>
                        <div
                          style={{
                            borderTop:
                              dimensions.width < 780 ? '1px solid #E0E3E7' : '',
                          }}
                          className="flex  w-full pt-3 lg:pt-0 mt-0 mt-2 items-center"
                        >
                          <img
                            src={
                              ind.ex === 1
                                ? oneBar
                                : ind.ex === 2
                                ? halfBar
                                : fullBar
                            }
                            alt=""
                            className="lg:pr-5 h-2 relative -left-12 lg:left-0 w-full"
                          />
                          <ReactTooltip className="customTool" />

                          <img
                            src={info}
                            data-tip={ind.fluency}
                            alt=""
                            style={{ borderLeft: '1px solid #D1D4D8' }}
                            className="pl-4 cursor-pointer"
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>

          <div style={{ borderBottom: '1px solid #E0E3E7', paddingBottom: 12 }}>
            <p
              style={{ color: '#3B3E41' }}
              className={`text-lg font-semibold mt-6`}
            >
              - Functional Capabilities :
            </p>
            <div className="flex items-center flex-wrap justify-between">
              {!projectobject.functionalList
                ? []
                : projectobject.functionalList.map((ind, index) => {
                    return (
                      <div
                        key={index + 'dkjnvlmlkd'}
                        className="border flex flex-col lg:flex-row lg:items-center lg:w-80 w-full justify-between rounded mt-3 lg:mt-7 border-gray-200 px-4  py-2 lg:h-16"
                      >
                        <p
                          style={{ color: '#707376' }}
                          className=" text-base text-left lg:w-32 text-ellipse"
                        >
                          {ind.lang}
                        </p>
                        <div
                          style={{
                            borderTop:
                              dimensions.width < 780 ? '1px solid #E0E3E7' : '',
                          }}
                          className="flex  w-full pt-3 lg:pt-0 mt-0 mt-2 items-center"
                        >
                          <img
                            src={
                              ind.ex === 1
                                ? oneBar
                                : ind.ex === 2
                                ? halfBar
                                : fullBar
                            }
                            alt=""
                            className="lg:pr-5 h-2 relative -left-12 lg:left-0 w-full"
                          />
                          <ReactTooltip className="customTool" />

                          <img
                            src={info}
                            data-tip={ind.fluency}
                            alt=""
                            style={{ borderLeft: '1px solid #D1D4D8' }}
                            className="pl-4 cursor-pointer"
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>

          <div className="flex flex-wrap justify-between pb-10">
            <h1
              style={{ color: '#3B3E41' }}
              className={`text-xl font-semibold mt-6`}
            >
              Reference:
            </h1>

            <div>
              <h1 style={{ color: '#3B3E41' }} className={`text-lg mt-6 mb-4`}>
                Email address:
              </h1>
              <span className="border border-gray-300 rounded py-3 px-4">
                {projectobject.email}
              </span>
            </div>

            <div>
              <h1 style={{ color: '#3B3E41' }} className={`text-lg mt-6 mb-4`}>
                Linkedin profile link:
              </h1>
              <span className="border border-gray-300 rounded py-3 px-4">
                {projectobject.linkedIn}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
