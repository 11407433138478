import React, { useEffect } from 'react';
import Navbar from '../../components/Home/SmallHome/components/Navbar';
import Footer from '../../components/Home/SmallHome/components/Footer';
import AboutProfile from './AboutProfile';
import Experience from './Experience';
import Prodisplay from './Prodisplay';
import ProfileHeading from './ProfileHeading';
import ProfilePicture from './ProfilePicture';
import Projects from './Projects';
import Languages from './Languages';
import Preferences from './Preferences';
import Coordinates from './Coordinates';
import {
  getConsultantData,
  getFunctionalList,
  getIndustryList,
  getLanguageList,
  getCountries,
} from '../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import LoadingOverlay from 'react-loading-overlay';

function Profile({
  getConsultantData,
  getIndustryList,
  getFunctionalList,
  consultant,
  getLanguageList,
  getCountries,
}) {
  const alert = useAlert();

  useEffect(() => {
    var aValue = localStorage.getItem('authId');
    getConsultantData(aValue, (res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });

    getFunctionalList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
    getIndustryList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
    getLanguageList((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
    getCountries((res) => {
      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      }
    });
  }, []);
  return (
    <LoadingOverlay active={consultant.loading} spinner text=" ">
      <Navbar />
      <div className="xl:px-36 px-8 mt-20 ">
        <Prodisplay />
        <div className="lg:border  rounded mt-5   lg:mt-10 lg:mb-20 mb-10  border-gray-100">
          <ProfilePicture />
          <div className="xl:px-14 md:px-10 px-0">
            <ProfileHeading
              user={1}
              years={
                !consultant.consultantProfile.years
                  ? 0
                  : consultant.consultantProfile.years
              }
              name={
                consultant.consultantProfile.first_name +
                ' ' +
                consultant.consultantProfile.last_name
              }
              industryList={consultant.consultantProfile.industryList}
            />
            <AboutProfile
              aboutText={consultant.consultantProfile.description}
            />
            <Experience
              user="consultant"  
              industryArray={consultant.consultantProfile.industryList}
              functionalArray={consultant.consultantProfile.functionalList}
            />
            <Projects />
            <Languages
              languageArray={consultant.consultantProfile.languageList}
            />
            <Preferences
              engagement={
                !consultant.consultantProfile.preference
                  ? 0
                  : consultant.consultantProfile.preference
              }
              location={
                !consultant.consultantProfile.engagement
                  ? 0
                  : consultant.consultantProfile.engagement
              }
              daily={
                !consultant.consultantProfile.daily
                  ? ''
                  : consultant.consultantProfile.daily
              }
              hourly={
                !consultant.consultantProfile.hourly
                  ? ''
                  : consultant.consultantProfile.hourly
              }
              monthly={
                !consultant.consultantProfile.monthly
                  ? ''
                  : consultant.consultantProfile.monthly
              }
            />
          </div>
        </div>
        <div className="text-center p-8">
              <a href="javascript:void()" onClick={() => {
                window.localStorage.removeItem('authId');
                window.location.href = '/';
              }}>logout</a>
            </div>
      </div>
      <Footer />
    </LoadingOverlay>
  );
}

const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, {
  getIndustryList,
  getFunctionalList,
  getLanguageList,
  getConsultantData,
  getCountries,
})(Profile);
