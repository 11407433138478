import React, { useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
// import projOne from '../../../assets/img/projOne.webp';
// import projTwo from '../../../assets/img/projTwo.webp';
import projThree from '../../../assets/img/projThree.webp';
// import projFour from '../../../assets/img/projFour.webp';
import EmptyProjects from './EmptyProjects';
import EditProject from './EditProject';
import AddProject from './AddProject';
import ViewProjects from './ViewProjects';
import { connect } from 'react-redux';

 function Projects({ consultant }) {
   const {consultantProfile} = consultant;
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [viewProject, setviewproject] = useState(false);
  const [projectobject, setProjectObject] = useState({});

  return (
    <div className="flex flex-wrap items-center justify-between mt-5 lg:mt-10 pt-7 lg:pt-0">
      <div
        className={`flex items-center w-full ${
          !consultantProfile.projectsArray
            ? []
            : consultantProfile.projectsArray.length === 0
            ? ''
            : 'self-start'
        }  lg:w-auto mb-7 lg:mb-0 justify-between`}
      >
        <p
          style={{ color: '#38369A' }}
          className="lg:text-lg self-start self-start relative top-2 lg:-top-1 font-semibold text-base uppercase"
        >
          PROJECTS
        </p>
        {/* small edit and add btn on small screen */}
        <div className="flex lg:hidden item-center">
          <button
            onClick={() => {
              setEditOpen(true);
            }}
            className="border-indigo-800 border rounded text-indigo-800 px-3 "
          >
            Edit
          </button>
          <button
            onClick={() => {
              setAddOpen(true);
            }}
            className="purple ml-4 rounded text-white font-bold text-3xl px-2 "
          >
            +
          </button>
        </div>
        {/* small edit and add btn on small screen */}
      </div>
      <div className="w-full lg:w-4/5">
        <div className="flex flex-wrap  items-center justify-between">
          <p
            style={{ color: '#3B3E41' }}
            className="text-lg font-semibold mb-2 lg:mb-0"
          ></p>
          <div className="lg:flex hidden items-center">
            <button
              onClick={() => {
                setEditOpen(true);
              }}
              style={{ border: '1px solid #38369A', color: '#38369A' }}
              className="text-base rounded px-4 py-2"
            >
              Edit Projects
            </button>
            <button
              onClick={() => {
                setAddOpen(true);
              }}
              className="purple text-white px-4 py-2 ml-4 rounded text-base"
            >
              Add Projects
            </button>
          </div>
        </div>
       

        {!consultantProfile.projectsArray ? (
          <EmptyProjects />
        ) : (
          <div className="">
            <div className="flex items-center mt-0 lg:mt-10 flex-wrap justify-between">
              {/* project Card */}
              {!consultantProfile.projectsArray
                ? []
                : consultantProfile.projectsArray.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setviewproject(true);
                          setProjectObject(item);
                        }}
                        className="w-full md:w-64 xl:w-96 mt-7 lg:mt-14 cursor-pointer"
                        key={index + 'jdvhknlkmv'}
                      >
                        <img
                          src={projThree}
                          alt=""
                          loading="lazy"
                          className="rounded w-full mb-4 h-28 lg:h-auto"
                        />
                        <p className="text-sm">
                          <EllipsisText text={item.description} length={'70'} />
                        </p>
                      </div>
                    );
                  })}

              {/* project Card */}
            </div>
          </div>
        )}
      </div>

      {editOpen && (
        <EditProject
          project={consultantProfile}
          visible={editOpen}
          setVisible={(val) => {
            setEditOpen(val);
          }}
        />
      )}

      {addOpen && (
        <AddProject
          visible={addOpen}
          setVisible={(val) => {
            setAddOpen(val);
          }}
        />
      )}

      {viewProject && (
        <ViewProjects
          projectobject={projectobject}
          visible={viewProject}
          setVisible={(val) => {
            setviewproject(val);
          }}
        />
      )}
    </div>
  );
}


 const mapStateToProps = (state) => ({
   consultant: state.consultant,
 });

 export default connect(mapStateToProps, {

 })(Projects);