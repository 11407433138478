import React, { useState, useEffect } from 'react';
import uploadWhite from '../../../assets/img/uploadWhite.svg';
import purpEdit from '../../../assets/img/purpEdit.svg';
import webwhite from '../../../assets/img/webwhite.svg';
import goweb from '../../../assets/img/goweb.svg';
import whiteArrDown from '../../../assets/img/whiteArrDown.svg';
import whiteArrUp from '../../../assets/img/whiteArrUp.svg';
import CompanyEditProfile from './CompanyEditProfile';
import CompanyShareModal from './CompanyShareModal';




export default function CompanyProfileHeading(props) {
  const { company } = props;
  const [resume, setResume] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <div className="flex lg:flex-row flex-col justify-center lg:justify-between items-center">
      <img
        src={props.logo}
        style={{
          border: '12px solid white',
          borderRadius: 5,
          objectFit: 'contain',
        }}
        alt=""
        className="lg:h-40 h-32 w-32 lg:w-40 rounded -top-10 relative "
        loading="lazy"
      />

      <div className="lg:ml-10 w-full">
        <div className="flex lg:flex-row flex-col items-center w-full justify-between ">
          <h1 className="lg:text-3xl text-lg font-bold">{props.name}</h1>
          {/* mobile view */}

          <div style={{ color: '#707376' }} className="block lg:hidden">
            <div className="flex items-center flex-wrap  justify-between ">
              <p
                style={{ color: ' #3B3E41' }}
                className="text-sm mt-3 font-medium "
              >
                Specialised in:
              </p>
              {!props.industryList
                ? []
                : props.industryList.map((item, index) => {
                    return (
                      <div
                        key={index + 'dkhjnkm'}
                        className="flex mt-3 items-center "
                      >
                        <div
                          style={{ borderRadius: '50%' }}
                          className={`h-1 bg-gray-300 ${
                            index === 0 ? 'hidden' : ''
                          } w-1 mx-1`}
                        />
                        {/* <img src={item.img} alt="" className="mr-2 h-5" /> */}
                        <p className="text-sm">{item.lang}</p>
                      </div>
                    );
                  })}
            </div>
          </div>
          {/* mobile view */}

          <div
            style={{
              borderTop:
                dimensions.width < 780
                  ? '1px solid rgba(177, 181, 185,0.4)'
                  : '',
            }}
            className="flex w-full lg:w-auto px-3 lg:px-0  items-center relative mt-5 lg:mt-0 pt-5 lg:pt-0 justify-between"
          >
            <button
              onClick={() => {
                setEditProfile(true);
              }}
              style={{ color: '#38369A', border: '1px solid #38369A' }}
              className="flex items-center lg:text-base text-sm rounded py-1.5  px-4"
            >
              <img
                src={purpEdit}
                alt=""
                className="mr-2 hidden lg:block"
                loading="lazy"
              />
              Edit
            </button>

            <button
              onClick={() => {
                setShareModal(true);
              }}
              className="flex text-white purple mx-3 hover:bg-indigo-900 items-center lg:text-base text-sm  rounded py-1.5 px-4"
            >
              <img
                src={uploadWhite}
                alt=""
                className="mr-2 hidden lg:block"
                loading="lazy"
              />
              Share
            </button>

            <a
              href={company.companyData.website}
              target="_blank"
              className="flex text-white purple hover:bg-indigo-900 items-center lg:text-base text-sm  rounded py-1.5 px-4"
            >
              <img
                src={webwhite}
                alt=""
                className="mr-2 hidden lg:block"
                loading="lazy"
              />
              <span
                className="pr-2"
              >
                Website
              </span>
              <img src={goweb} alt="" className="ml-2" />
            </a>
          </div>
        </div>

        <div className="lg:flex hidden  mt-5">
          <div
            style={{ color: '#707376' }}
            className="flex ml-2 justify-between"
          >
            <p className=" text-lg w-40">Specialised in:</p>
            <div className="flex items-center flex-wrap justify-items-end ">
              {!props.industryList
                ? []
                : props.industryList.map((item, index) => {
                    return (
                      <div
                        key={index + 'dkhjnkm'}
                        className="flex items-center "
                      >
                        <div
                          style={{ borderRadius: '50%' }}
                          className={`h-1 bg-gray-300 ${
                            index === 0 ? 'hidden' : ''
                          } w-1 mx-1`}
                        />
                        {/* <img src={item.img} alt="" className="mr-2" /> */}
                        <p className="text-lg ml-2">{item.lang}</p>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>

      <CompanyEditProfile
        visible={editProfile}
        setVisible={(val) => {
          setEditProfile(val);
        }}
      />

      <CompanyShareModal
        visible={shareModal}
        setVisible={(val) => {
          setShareModal(val);
        }}
      />
    </div>
  );
}
