import React, { useEffect, useRef, useState } from "react";
import axios1 from "axios";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import classes from "./Blog.module.css";
import { MenuItem } from "@material-ui/core";
import API_PATH from "../../api_config.json";
import { useHistory } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import TextField from "@material-ui/core/TextField";
import ModalResponse from "../Careers/ModalResponse";
import Navbar from "../Home/SmallHome/components/Navbar";
import Footer from "../Home/SmallHome/components/Footer";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from '@material-ui/icons/Cancel';

function BlogCreate() {
  let history = useHistory();
  const editorRef = useRef(null);
  const [context, setContext] = useState(null);
  const [imgPreview, setImagePreView] = useState(null);
  const [fileNameError, setFileNameError] = useState("");
  const [fileSuccess, setFileSuccess] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [filesId, setFilesId] = useState(null);
  const [message, setMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [contextError, setContextError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [filesIdError, setFilesIdError] = useState("");

  const initialValues = {
    title: "",
    category: "",
  };
  const validationSchemaY = yup.object().shape({
    title: yup.string().required("Title is required"),
    category: yup.string().required("Category is required"),
  });

  const handleSubmit = async (formValues, { resetForm }) => {
    createLog(formValues);
  };

  const createLog = async (formValues) => {
    if (!context) {
      setContextError("Description  is required");
      return false;
    }

    if (!filesId) {
      setFilesIdError("Cover image  is required");
      return false;
    }
    await axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/blogs/post-blog`,
      data: {
        title: formValues.title,
        description: context,
        category: formValues.category,
        cover_image_id: filesId,
        user_platform_id: 1,
        createdby_userid: Number(localStorage.getItem("loginUserId")),
        updatedby_userid: 3,
      },
    })
      .then((res) => {
        if (res.data.status == 200) {
          setMsg(res.data.message);
          setVisible(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fileUpload = (e) => {
    const file = e.target.files[0];
    let ext = file.name.split(".").pop();

    setFileSuccess(true);
    setFileError(false);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_platform_id", 1);
    formData.append("createdby_userid", 1);

    const response = axios1({
      method: "post",
      url: `${API_PATH.baseUrl}/api/v1.0/list/upload1`,
      data: formData,
    })
      .then((res) => {
        setFilesId(res.data[0].asset_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const remove = () => {
    setImagePreView(null)
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Blogs | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div className={`${classes.create} xl:px-32 px-0 privacyPolicy`} style={{ backgroundColor: "#EFEFFA" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaY}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {};
            if (values.budget && isNaN(values.budget)) {
              errors.budget = "Only number is allowed";
            }
            return errors;
          }}
        >
          {(props) => {
            const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
            return (
              <Form id="" onSubmiblogt={handleSubmit} encType="multipart/form-data">
                <div>
                  <h3>Blog Title</h3>
                </div>

                <TextField
                  fullWidth
                  margin="dense"
                  type="search"
                  autoComplete="off"
                  id="title"
                  name="title"
                  label="Title"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.title && touched.title && errors.title}
                  error={errors.title}
                />

                <div>
                  <h3>Category</h3>
                </div>
                <TextField
                  fullWidth
                  margin="dense"
                  type="search"
                  autoComplete="off"
                  id="category"
                  name="category"
                  label="Category"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  select
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.category && touched.category && errors.category}
                  error={errors.category}
                >
                  <MenuItem value={"Business"}>Business</MenuItem>
                  <MenuItem value={"Finance"}>Finance</MenuItem>
                  <MenuItem value={"Strategy"}>Strategy</MenuItem>
                  <MenuItem value={"Marketing"}>Marketing</MenuItem>
                </TextField>

                <div className={classes.error}>{categoryError}</div>
                <div>
                  <h3>Blog Description</h3>
                </div>
                <Editor
                  name="context"
                  apiKey="4cb7llw9xpc87xh5p1g8jftaieayrxasq7fm6xsddokjoove"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat" +
                      "link image",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                    file_picker_callback: function (cb, value, meta) {
                      var input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "image/*");
                      input.onchange = function () {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function () {
                          var id = "blobid" + new Date().getTime();
                          var blobCache = editorRef.current.editorUpload.blobCache;
                          var base64 = reader.result.split(",")[1];
                          var blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);

                          /* call the callback and populate the Title field with the file name */
                          cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                      };
                      input.click();
                    },
                  }}
                  onEditorChange={(newText) => {
                    setContextError("");
                    setContext(newText);
                    values.context = newText;
                  }}
                />
                {<span className={classes.error}>{errors.context}</span>}
                <div className={classes.error} style={{ top: "1px" }}>
                  {contextError}
                </div>
                <div>
                  <div className={classes.cover}>
                    <div htmlFor="coverImage">
                      <label className={classes.coverImage} htmlFor="coverImage">
                        <AddCircleOutlineIcon />
                        Add Cover Image
                        {<span className={classes.error}>{fileNameError}</span>}
                      </label>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="coverImage"
                        onChange={(e) => {
                          setFilesIdError("");
                          setFileNameError("");
                          let file = e.target.files[0];
                          // replace(/\s+/g, "").trim()
                          let ext = file.name.split(".").pop();

                          if (["jpg", "jpeg", "png", "PNG", "JPEG", "JPG"].includes(ext)) {
                            const objectUrl = URL.createObjectURL(e.target.files[0]);
                            setImagePreView(objectUrl);
                            fileUpload(e);
                            // values[res.question.replace(/\s+/g, "").trim()] = e.target.files[0].name;
                          } else {
                            setFileSuccess(false);
                            setFileError(true);
                            setFileNameError(" Supports only  jpg,jpeg,png");
                          }
                        }}
                      ></input>
                    </div>
                    <div className={classes.coverImage}>
                      {imgPreview && <CancelIcon className={classes.cross} onClick = {remove} /> }
                      {imgPreview && <img src={imgPreview}></img>}
                      </div>
                    {
                      <span style={{ top: "-26px" }} className={classes.error}>
                        {filesIdError}
                      </span>
                    }
                  </div>
                </div>
                <div className={classes.btn}>
                  <button type="submit">Publish</button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {visible && (
        <ModalResponse
          onClose={() => {
            setVisible(false);
            setMsg("");
            history.push("/blogs");
          }}
          open={visible}
          message={message}
        />
      )}
      <Footer />
    </div>
  );
}

export default BlogCreate;
