import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import close from '../../../../assets/img/cancel.svg';
// import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
// import ava from '../../../../assets/img/sai.png';
import logo from '../../../../assets/img/regLogo.svg';
import inIcon from '../../../../assets/img/inUser.svg';
import actIcon from '../../../../assets/img/actUser.svg';
import errIcon from '../../../../assets/img/errUser.svg';
//arrow icons
import downArrow from '../../../../assets/img/textArrDown.svg';
import upArrow from '../../../../assets/img/textUpArrow.svg';
// // messgae icons
import messActive from '../../../../assets/img/messActive.svg';
import messInActive from '../../../../assets/img/messInActive.svg';
import messError from '../../../../assets/img/messError.svg';
// // phone call icons
import phoneActive from '../../../../assets/img/phone-callInact.svg';
import phoneInActive from '../../../../assets/img/phone-callAct.svg';
import phoneError from '../../../../assets/img/phone-callErr.svg';
import { useAlert } from 'react-alert';
import CustomTextInput from '../../components/CustomTextInput';
import CustomDropdown from '../../components/CustomDropdown';
import { postCompanyOne } from '../../../../state/actions/companyAction.js';
import { connect } from 'react-redux';
import CompanyContinue from '../CompanyContinue';



 function CompanyFormOne(props) {
   const [dimensions, setDimensions] = useState({
     width: window.innerWidth,
     height: window.innerHeight,
   });
   const { setPage, postCompanyOne } = props;
   const alert = useAlert();
   const percentage = 66.6;

   useEffect(() => {
     function handleResize() {
       setDimensions({
         width: window.innerWidth,
         height: window.innerHeight,
       });
     }

     window.addEventListener('resize', handleResize);

     return (_) => {
       window.removeEventListener('resize', handleResize);
     };
   });

   const [myJoinAs, setMyJoinAs] = useState('');

   const dropDownItems = [
     { item: 'Web Developer', value: 'web Developer' },
     { item: 'UI/UX desinger', value: 'UI/UX desinger' },
     { item: 'Mobile Developer', value: 'Mobile Developer' },
     { item: 'Content Writer', value: 'Content Writer' },
     { item: 'Graphics Designer', value: 'Graphics Designer' },
     { item: 'Game Developer', value: 'Game Developer' },
   ];

   const initialValues = {
     service: '',
     companyName: '',
     contactName: '',
     email: '',
     phoneNumber: '',
   };

   const validationSchema = yup.object().shape({
     contactName: yup.string().required('Contact is required '),
     companyName: yup.string().required('Name is required'),
     email: yup.string().email('Invalid email').required('Email is Required'),
     phoneNumber: yup
       .number('Number only')
       .required('Phone Number is Required'),
   });

   const handleSubmit = (formValues) => {
     
     const submitObj = {
       company_name: formValues.companyName,
       contact_name: formValues.contactName,
       email: formValues.email,
       phone: formValues.phoneNumber,
       type: myJoinAs
     };
      console.log({ submitObj });
     postCompanyOne(submitObj ,(res)=>{
       if(Object.keys(res).length > 0){
          alert.error(res.error);
        }else{
        setPage(2, submitObj); 
        }
     });
   };

   return (
     <div
       style={{
         height:
           dimensions.width < 780 ? 680 : dimensions.width < 1300 ? 590 : 680,
       }}
       className=" relative lg:w-3/6 w-full overflow-y-scroll hideScroll"
     >
       <img
         src={close}
         alt=""
         loading="lazy"
         className="absolute top-0 right-0 pr-8 cursor-pointer pt-8"
         onClick={() => {
           props.setVisible(false);
         }}
       />
       <div className=" flex flex-col justify-center lg:px-12 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9">Company On-boarding</h1>
         <p className="grey-text text-center text-base font-light mt-3">
           Please fill the form and get started
         </p>

         <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="company-sign" onSubmit={props.handleSubmit}>
                 <CustomDropdown
                   items={dropDownItems}
                   placeholder="Services Provided *"
                   id="service"
                   width="w-full"
                   onValueChange={(val) => {
                     setMyJoinAs(val);
                   }}
                   downArrow={downArrow}
                   upArrow={upArrow}
                 />
                 <CustomTextInput
                   width="w-full pr-4"
                   name="companyName"
                   id="companyName"
                   type="text"
                   placeholder="Company Name *"
                 />
                 <CustomTextInput
                   name="contactName"
                   width="w-full pr-4"
                   id="contactName"
                   type="text"
                   placeholder="Contact person's full name *"
                   activeIcon={actIcon}
                   inactiveIcon={inIcon}
                   errorIcon={errIcon}
                 />
                 <CustomTextInput
                   width="w-full pr-4"
                   name="email"
                   id="email"
                   type="text"
                   placeholder="Email address *"
                   activeIcon={messActive}
                   inactiveIcon={messInActive}
                   errorIcon={messError}
                 />
                 <CustomTextInput
                   width="w-full pr-4"
                   name="phoneNumber"
                   id="phoneNumber"
                   type="text"
                   placeholder="Phone Number *"
                   activeIcon={phoneActive}
                   inactiveIcon={phoneInActive}
                   errorIcon={phoneError}
                 />

                 <p
                   style={{ color: 'rgba(112, 115, 118, 0.65)' }}
                   className="text-sm mt-7 font-thin"
                 >
                   <span style={{ color: '#707376' }} className="font-bold">
                     Disclaimer:
                   </span>{' '}
                   I acknowledge that any information, application process,
                   screening details, expert network, client information is
                   strictly private & confidential.
                 </p>

                 <label
                   style={{ color: '#38369A' }}
                   className="checkbox mt-6 cursor-pointer"
                 >
                   <span className="checkbox__input">
                     <input type="checkbox" name="checkbox" />
                     <span className="checkbox__control">
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 24 24"
                         aria-hidden="true"
                         focusable="false"
                       >
                         <path
                           fill="none"
                           stroke="currentColor"
                           stroke-width="3"
                           d="M1.73 12.91l6.37 6.37L22.79 4.59"
                         />
                       </svg>
                     </span>
                   </span>
                   <span className="radio__label ">
                     I acceept the terms and conditions
                   </span>
                 </label>
               </Form>
             );
           }}
         </Formik>
       </div>
      
       <CompanyContinue
         percentage={percentage}
         id="company-sign"
       />
     </div>
   );
 }


const mapStateToProps = (state) => ({
  // consultant: state.consultant,
});

export default connect(mapStateToProps, { postCompanyOne })(CompanyFormOne);
