import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import JobApply from "./JobApply";
import classes from "./Carrers.module.css";
import API_PATH from "../../api_config.json";
import CareersImage from "../../assets/img/Careers.jpg";
import Footer from "../Home/SmallHome/components/Footer";
import Navbar from "../Home/SmallHome/components/Navbar";
import useStyles from "../../pages/auth/Onboarding/OnboardingForm/Onboarding.css";

const axios = require("axios");

function CareerDetails() {
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [detailsData, setDetailsData] = useState();

  const showConatact = async () => {
    window.scrollTo(0, 0);
  };
  const getSingleList = async (search_input) => {
    await axios({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/jobs/fetch-job-details?id=${search_input}`,
    })
      .then((res) => {
        setDetailsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSingleList(location.state.detail.id);
    showConatact();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These careers outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Careers | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div>
        <img src={CareersImage} className={classes.widthFull} />
        {!showForm && <h1 className={classes.cHeader}>{location?.state?.detail?.job_title}</h1>}
      </div>
      {!showForm && (
        <div className={classes.careerDetails}>
          <div>
            <h4>Description</h4>
            <div className="ull" dangerouslySetInnerHTML={{ __html: location?.state?.detail?.job_description }}></div>
          </div>
          <div>
            <h4>Responsibilities</h4>
            <div className="ull" dangerouslySetInnerHTML={{ __html: location?.state?.detail?.responsibilities }}></div>
            <div className={classes.center}>
              <button
                className={classes.carrierButton}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}

      {showForm && detailsData && <JobApply detailsData={detailsData && detailsData} />}

      <Footer />
    </div>
  );
}

export default CareerDetails;
