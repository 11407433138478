import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import ava from '../../../../assets/img/sai.png';
import logo from '../../../../assets/img/regLogo.svg';
import CustomImageUpload from '../../components/CustomImageUpload';
import ContinueFooter from '../../components/ContinueFooter';
// notes icons
import noteActive from '../../../../assets/img/noteActive.png';
import noteInActive from '../../../../assets/img/noteInactive.svg';
import noteError from '../../../../assets/img/noteError.png';
import houseActive from '../../../../assets/img/house-active.svg';
import houseInactive from '../../../../assets/img/house-inactive.svg';
import houseError from '../../../../assets/img/house-error.svg';
import inIcon from '../../../../assets/img/inUser.svg';
import actIcon from '../../../../assets/img/actUser.svg';
import errIcon from '../../../../assets/img/errUser.svg';
import clipActive from '../../../../assets/img/clip-active.svg';
import clipInactive from '../../../../assets/img/clip-Inactive.svg';
import clipError from '../../../../assets/img/clip-error.svg';
import CustomTextArea from '../../components/CustomTextArea';
import CustomTextInput from '../../components/CustomTextInput';
import CustomFileUpload from '../../components/CustomFIleUpload';
import { setTellUsData } from '../../../../state/actions/consultantAction';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';



 function AboutYourselfForm({ setTellUsData }) {
   const [avater, setAvater] = useState(ava);
   const [checked, setChecked] = useState(false);
   const hiddenFileInput = useRef(null);
   const hiddenFileInputRes = useRef(null);
   const [file, setFile] = useState('Upload your Resume');
   const history = useHistory();
   const alert = useAlert();


   const initialValues = {
     description: '',
     currentRole: '',
     currentEmployer: '',
   };

   const validationSchema = yup.object().shape({
     description: yup
       .string()
       .min(4, 'Has to have at least 4 characters')
       .required('This is required'),
     currentRole: yup.string(),
     currentEmployer: yup.string(),
   });

   const handleClick = (event) => {
     hiddenFileInput.current.click();
   };

   const handleChange = (event) => {
     const fileUploaded = event.target.files[0];
     let reader = new FileReader();
     reader.readAsDataURL(fileUploaded);
     reader.onload = () => {
       console.log(reader.result);
       setAvater(reader.result);
     };
   };

   //  resume
   const handleClickRes = (event) => {
     hiddenFileInputRes.current.click();
   };

   const handleChangeRes = (event) => {
     const fileUploaded = event.target.files[0].name;
     //   let reader = new FileReader();
     //   reader.readAsDataURL(fileUploaded);
     //   reader.onload = () => {
     //     // console.log(reader.result);
     //     setAvater(reader.result);
     //   };
     setFile(fileUploaded);
   };

   const handleSubmit = (formValues) => {
     formValues.avater = avater;
     formValues.file = file;
     console.log({ formValues });
     setTellUsData(formValues,(res)=>{

      if (Object.keys(res).length > 0) {
        alert.error(res.error);
      } else {
        history.push('/freelancer/sign-up/step-five');
      }
     })
   };

   return (
     <div className="h-screen lg:w-3/5 relative w-full overflow-y-scroll hideScroll">
       <div className=" flex flex-col justify-center md:px-16 xl:px-24 px-8 pb-28 lg:pb-32">
         <div className="flex items-center mx-auto mt-12">
           <img src={logo} alt="logo" className="" />
           <p className="text-base ml-2 text-black">StrategyConnect</p>
         </div>

         <h1 className="text-3xl text-center mt-9 waitBorderBottom">
           Tell us more about yourself
         </h1>

         {/* image upload container */}
         <CustomImageUpload
           avater={avater}
           handleClick={handleClick}
           hiddenFileInput={hiddenFileInput}
           handleChange={handleChange}
           checked={checked}
           setChecked={setChecked}
         />
         {/* image upload container */}
         <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={handleSubmit}
         >
           {(props) => {
             return (
               <Form id="tell-about" onSubmit={props.handleSubmit}>
                 <CustomTextArea
                   cols="26"
                   rows="3"
                   name="description"
                   id="description"
                   type="text"
                   placeholder="Short description *"
                   activeIcon={noteActive}
                   inactiveIcon={noteInActive}
                   errorIcon={noteError}
                 />
                 <CustomFileUpload
                   placeholder="Upload your Resume"
                   name="resumeUpload"
                   handleClick={handleClickRes}
                   hiddenFileInput={hiddenFileInputRes}
                   handleChange={handleChangeRes}
                   activeIcon={clipActive}
                   fileName={file}
                   inactiveIcon={clipInactive}
                   errorIcon={clipError}
                 />
                 <CustomTextInput
                   name="currentRole"
                   id="currentRole"
                   type="text"
                   placeholder="Current Role"
                   activeIcon={houseActive}
                   inactiveIcon={houseInactive}
                   errorIcon={houseError}
                 />
                 <CustomTextInput
                   name="currentEmployer"
                   id="currentEmployer"
                   type="text"
                   placeholder="Current Employer"
                   activeIcon={actIcon}
                   inactiveIcon={inIcon}
                   errorIcon={errIcon}
                 />
               </Form>
             );
           }}
         </Formik>
       </div>
       <ContinueFooter
         percentage={55.5}
         goBack={() => {
           history.push('/freelancer/sign-up/step-three');
         }}
         id="tell-about"
       />
     </div>
   );
 }




const mapStateToProps = (state) => ({
  consultant: state.consultant,
});

export default connect(mapStateToProps, { setTellUsData })(AboutYourselfForm);