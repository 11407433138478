import React, { useEffect, useState } from "react";
import axios1 from "axios";
import BlogList from "./BlogList";
import { Helmet } from "react-helmet";
import BlogSearch from "./BlogSearch";
import classes from "./Blog.module.css";
import API_PATH from "../../api_config.json";
import CareersImage from "../../assets/img/blog.png";
import Footer from "../Home/SmallHome/components/Footer";
import Navbar from "../Home/SmallHome/components/Navbar";
function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBlogs("", 0, 1);
  }, []);
  const getAllBlogs = async (search_input, from, search_by) => {
    await axios1({
      method: "get",
      url: `${API_PATH.baseUrl}/api/v1.0/blogs/fetch-all-blogs?offset=10&search_term=${search_input}&from=${from}&search_by=${search_by}`,
    })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.rows.length > 0) {
          setBlogs(res.data.data.rows);
          let pagination = res.data.data.count / 10;
          if (pagination > 1) {
            setCount(pagination);
          } else {
            setCount(0);
          }
        } else {
          setBlogs([]);
          setCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllBlogsByCategory = async (category) => {
  //   await axios1({
  //     method: "get",
  //     url: `${API_PATH.baseUrl}/api/v1.0/blogs/fetch-suggested-blogs?category=${category}`,
  //   })
  //     .then((res) => {
  //       console.log("this is res", res);
  //       if (res && res.data && res.data.data && res.data.data.rows.length > 0) {
  //         setBlogs(res.data.data.rows);
  //         let pagination = res.data.data.count / 10;
  //         if (pagination > 1) {
  //           setCount(pagination);
  //         } else {
  //           setCount(0);
  //         }
  //       } else {
  //         setBlogs([]);
  //         setCount(0);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="These Blogs outline the rules and regulations for the use of StrategyConnect's Website "
        />
        <title>Blogs | StrategyConnect </title>
        <link rel="canonical" href="strategyconnect.co" />
      </Helmet>
      <Navbar />
      <div>
        <img src={CareersImage} className={classes.widthFull} />
        <h1 className={classes.cHeader}>StrategyConnect Blogs</h1>
        <div className="">
          <BlogSearch getCaseStudies={getAllBlogs} />
        </div>
      </div>

      <BlogList
        blogs={blogs}
        count={count}
        getCaseStudies={getAllBlogs}
        // getAllBlogsByCategory={getAllBlogsByCategory}
      />
      <Footer />
    </div>
  );
}

export default Blogs;
