import React, { useState, useEffect } from 'react';
import addidasLogo from '../../../../assets/img/Adidas_logo.svg';
import holidaymeLogo from '../../../../assets/img/Holidayme_logo.svg';
import digicelLogo from '../../../../assets/img/Digicel_logo.svg';
import fareyeLogo from '../../../../assets/img/Fareye_logo.svg';
import sideImage from '../../../../assets/img/onBoardingSideImage.jpg';
import logo from '../../../../assets/img/StrategyConnect_logo.svg';


const Sidebar = () => {
    const imgArray = [addidasLogo, holidaymeLogo, digicelLogo, fareyeLogo];
    return (
        <>
            <img src={sideImage} alt="On Boarding Image" style={{ objectFit: 'cover' }} className="z-10 h-screen w-full" />
            <div className="onBoadingSideTop text-center">
                <div className="onBoardLeftTop">
                    <img src={logo} alt="logo" className="" />
                </div>
            </div>
            <div className="absolute bottom-0 box-padd">
                <div className="heading-Data">
                    <h1 className="text-3xl">Trusted Provider of Top Talent For Top Brands</h1>
                </div>
                <div className="flex items-center justify-between pb-5 logos-block">
                    {imgArray.map((item, index) => {
                        return <img src={item} key={index} alt="logo" />;
                    })}
                </div>
            </div>
        </>
    );
}
export default Sidebar;