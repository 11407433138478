import React from 'react';
import useStyles from "./OnboardingHeader.css";
import logo from '../../../assets/img/StrategyConnect_logo.svg';

const OnboardingHeader = () => {
    const classes = useStyles();
    return (
        <>
            <header>
                <div className={classes.headerDiv}>
                    <img src={logo} alt="logo" />
                </div>
            </header>
        </>
    );
}

export default OnboardingHeader;