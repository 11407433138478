import React from 'react';
import BackBtn from './BackBtn';

export default function CompanyContinue({ percentage, id, width, goBack }) {
  return (
    <div
      style={width ? { width: '50%' } : null}
      className={`fixed bg-white ${
        width ? null : 'w-full lg:w-3/6'
      }  footerBorder bottom-0 lg:py-5 py-3.5 px-5  ${
        width ? null : ' lg:px-5 '
      }   flex items-center justify-between`}
    >
      <div
        style={{ height: 83, width: `${percentage}%` }}
        className="absolute greenFooterBorder left-0"
      />
      <p className="text-base">
        Note: You can still update your profile later.
      </p>

      {goBack ? (
        <span
          className={`relative ${width ? 'left-1' : '-left-3'}`}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            goBack();
          }}
        >
          <BackBtn />
        </span>
      ) : null}

      <button
        type="submit"
        form={id}
        className="p-2 rounded purple_re z-10 text-white text-sm"
      >
        Continue
      </button>
    </div>
  );
}
