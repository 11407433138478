import React, { useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import projThree from '../../../assets/img/projThree.webp';
import EmptyProjects from './EmptyProjects';
import CompanyEditProject from './CompanyEditProject';
import CompanyAddProject from './CompanyAddProject';
import CompanyViewProjects from './CompanyViewProjects';
import { connect } from 'react-redux';

 function CompanyProjects({ company }) {
   const [editOpen, setEditOpen] = useState(false);
   const [addOpen, setAddOpen] = useState(false);
   const [viewProject, setviewproject] = useState(false);
   const [projectobject, setProjectObject] = useState({});

   return (
     <div
       style={{ borderTop: '1px solid #e5e7eb' }}
       className="flex flex-wrap items-center justify-between mt-5 lg:mt-10 pt-7 lg:pt-10"
     >
       <div
         className={`flex items-center w-full ${
           !company.projectsArray
             ? []
             : company.projectsArray.length === 0
             ? ''
             : 'self-start'
         }  lg:w-auto mb-7 lg:mb-0 justify-between`}
       >
         <p
           style={{ color: '#38369A' }}
           className="lg:text-lg self-start relative top-2 lg:-top-1 font-semibold text-base uppercase"
         >
           PROJECTS
         </p>
         {/* small edit and add btn on small screen */}
         <div className="flex lg:hidden item-center">
           <button
             onClick={() => {
               setEditOpen(true);
             }}
             className="border-indigo-800 border rounded text-indigo-800 px-3 "
           >
             Edit
           </button>
           <button
             onClick={() => {
               setAddOpen(true);
             }}
             className="purple ml-4 rounded text-white font-bold text-3xl px-2 "
           >
             +
           </button>
         </div>
         {/* small edit and add btn on small screen */}
       </div>
       <div className="w-full lg:w-4/5">
         <div className="">
           <div className="flex flex-wrap  items-center justify-between">
             <p
               style={{ color: '#3B3E41' }}
               className="text-lg font-semibold mb-2 lg:mb-0"
             ></p>
             <div className="lg:flex hidden items-center">
               <button
                 onClick={() => {
                   setEditOpen(true);
                 }}
                 style={{ border: '1px solid #38369A', color: '#38369A' }}
                 className="text-base rounded px-4 py-2"
               >
                 Edit Projects
               </button>
               <button
                 onClick={() => {
                   setAddOpen(true);
                 }}
                 className="purple text-white px-4 py-2 ml-4 rounded text-base"
               >
                 Add Projects
               </button>
             </div>
           </div>
         </div>

         {!company.projectsArray ? (
           []
         ) : company.projectsArray.length < 1 ? (
           <EmptyProjects />
         ) : (
           <div className="">
             <div className="flex items-center mt-0 lg:mt-10 flex-wrap justify-between">
               {/* project Card */}
               {!company.projectsArray
                 ? []
                 : company.projectsArray.map((item, index) => {
                     return (
                       <div
                         onClick={() => {
                           setviewproject(true);
                           setProjectObject(item);
                         }}
                         className="w-full md:w-64 xl:w-96 mt-7 lg:mt-14 cursor-pointer"
                         key={index + 'jdvhknlkmv'}
                       >
                         <img
                           src={projThree}
                           alt=""
                           loading="lazy"
                           className="rounded w-full mb-4 h-28 lg:h-auto"
                         />
                         <p className="text-sm">
                           <EllipsisText
                             text={item.description}
                             length={'70'}
                           />
                         </p>
                       </div>
                     );
                   })}

               {/* project Card */}
             </div>
           </div>
         )}
       </div>

       <CompanyEditProject
         project={company}
         visible={editOpen}
         setVisible={(val) => {
           setEditOpen(val);
         }}
       />
       <CompanyAddProject
         visible={addOpen}
         setVisible={(val) => {
           setAddOpen(val);
         }}
       />
       <CompanyViewProjects
         projectobject={projectobject}
         visible={viewProject}
         setVisible={(val) => {
           setviewproject(val);
         }}
       />
     </div>
   );
 }


 const mapStateToProps = (state) => ({
   company: state.company.companyData,
 });

 export default connect(mapStateToProps, {})(CompanyProjects);